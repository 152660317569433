export class BlockUiDTO {

    message: string;

    isSmall: boolean;

    showLoader = true;

    public static of(message: string) {
        const blockUiDTO: BlockUiDTO = new BlockUiDTO();
        blockUiDTO.message = message;
        return blockUiDTO;
    }

    public toMessage() {
        const object = {};
        Object
            .keys(this)
            .filter(e => typeof this[e] !== 'function')
            .forEach(e => object[e] = this[e]);
        return JSON.stringify(object);
    }
}
