import { Component, Injector } from '@angular/core';
import { NotificationWidgetListingItemActionComponent } from '@shared/notification-widget/notification-widget-listing/notification-widget-listing-item/notification-widget-listing-item-action/notification-widget-listing-item-action.component';

@Component({
  selector: 'shared-notification-widget-listing-small-item-action',
  templateUrl: './notification-widget-listing-small-item-action.component.html',
  styleUrls: ['./notification-widget-listing-small-item-action.component.scss']
})
export class NotificationWidgetListingSmallItemActionComponent extends NotificationWidgetListingItemActionComponent {

  constructor(
    protected injector: Injector,
  ) {
    super(injector);
  }

}
