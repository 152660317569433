<div class="container blogs" *ngIf="postData.length>0" style="padding: 10vh 0;">
    <h2 class="font36">{{sectionName}}</h2>
    <carousel [isAnimated]="isAnimated" [showIndicators]="showIndicators" [singleSlideOffset]="singleSlideOffset"
        [itemsPerSlide]="itemsPerSlide" [(activeSlide)]="activeSlideIndex" [interval]="interval"
        (slideRangeChange)='gotRangeChange($event)'>
        <slide *ngFor="let post of postData; let index=index;" class="postSlide">
                <div class="listItem" *ngIf="post.title">
                    <div class="img">
                        <img *ngIf="post.jetpack_featured_media_url" src="{{post.jetpack_featured_media_url}}" alt="">
                    </div>
                    <h3 class="heading" [innerHTML]="getTitle(post.title['rendered'])"></h3>
                    <div class="text-right"><a href="{{post.link}}" target="_blank" class="more">More</a></div>
                </div>
        </slide>
    </carousel>
</div>