import { Injectable } from '@angular/core';
import { ConstantService } from './shared.constant';

@Injectable({ providedIn: 'root' })
export class LocaleUtils {

    static isChina(): boolean {
        return (ConstantService.get('chinaLocalKey') || 'CN') === sessionStorage.getItem('locale');
    }

    static getLocale(): string {
        return sessionStorage.getItem('locale');
    }

    static shouldMapWork(): boolean {
        return !(LocaleUtils.isChina());
    }
    static shouldCaptchaWork(): boolean {
        return !(LocaleUtils.isChina());
    }

    static shouldAddressAutoCompleteWork(): boolean {
        return !(LocaleUtils.isChina());
    }

    static shouldYoutubeWork(): boolean {
        return !(LocaleUtils.isChina());
    }

    static shouldVimeoWork(): boolean {
        return !(LocaleUtils.isChina());
    }

    static shouldGoogleAnalyticsWork(): boolean {
        return !(LocaleUtils.isChina());
    }
}
