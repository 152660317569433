import { Component } from '@angular/core';
import { AbstractBaseComponent } from '@shared/abstracts/abstract-base.component';
import { ConstantService } from '@shared/constant/shared.constant';

@Component({
  selector: 'marketplace-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent extends AbstractBaseComponent {
  url: string;
  constructor() {
    super();
    this.url = ConstantService.get('HELP_URL');
  }
}
