<ng-container [ngSwitch]="isLoggedIn">
  <div *ngSwitchCase="true" class="d-flex flex-column h-100">
    <div
      class="d-flex align-items-center justify-content-center mb-2 flex-grow-1"
    >
      <span class="font32 text-white mb-0 display-4">
        <img
          class="ml-2"
          src="{{ assetPath }}/images/new-svg/buybacksearch.svg"
        />
        Discover the best <span class="bold">BuyBack offers</span>
      </span>
    </div>
    <div class="bg-blue-lightest-2 border-radius">
      <div class="p-3">
        <!-- <h1 class="font36 bold">
          <img
            src="{{ assetPath }}/images/new-svg/buybackdashicon.svg"
          />Discover the best BuyBack offers
        </h1> -->
        <form
          *ngIf="containerDetailsBuyerData"
          [formGroup]="containerDetailsBuyerData.filterForm"
        >
          <div class="row m-0">
            <div class="col-lg-10 col-12 p-0 row p-0 m-0">
              <div class="col-lg-6 col-12 p-0 p-lg-3 row m-0">
                <div class="col-lg-8 col-md-4 col-6">
                  <div
                    class="form-group"
                    *ngIf="shouldAddressAutoCompleteWork()"
                  >
                    <input
                      #mapLocation
                      type="text"
                      class="form-control location"
                      placeholder="Location*"
                      formControlName="location"
                    />
                    <control-messages
                      [control]="
                        containerDetailsBuyerData.filterForm.controls.location
                      "
                    ></control-messages>
                  </div>
                  <div class="row" *ngIf="!shouldAddressAutoCompleteWork()">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <ng-select
                          [labelForId]="'box_country'"
                          [searchFn]="countrySearchFN"
                          [clearable]="false"
                          class="form-control ngSelectClass"
                          formControlName="country"
                          name="box_export_country"
                          autocomplete="off"
                          bindLabel="value"
                          bindValue="key"
                          [items]="
                            containerDetailsBuyerData?.countryForSearchWrapper
                              ?.countryNames
                          "
                          placeholder="Country*"
                          (change)="fetchAndPopulateCityData()"
                        >
                        </ng-select>
                        <control-messages
                          [control]="
                            containerDetailsBuyerData.filterForm.controls
                              .country
                          "
                        >
                        </control-messages>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <ng-select
                          [labelForId]="'box_city'"
                          [clearable]="false"
                          class="form-control ngSelectClass"
                          formControlName="city"
                          name="box_city"
                          autocomplete="off"
                          bindLabel="city"
                          bindValue="city"
                          [items]="geoTaggedLocations"
                          placeholder="City*"
                        >
                        </ng-select>
                        <control-messages
                          [control]="
                            containerDetailsBuyerData.filterForm.controls.city
                          "
                        ></control-messages>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-6 pr-0 pl-2 pl-lg-3">
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        type="text"
                        readonly
                        class="form-control radius text-right"
                        placeholder="100"
                        id="radius"
                        formControlName="radius"
                        [outsideClick]="true"
                        placement="bottom"
                        container="body"
                        [popover]="homeRadiusPopContent"
                        [popoverContext]="popHomeRadiusPopContent"
                        #popHomeRadiusPopContent="bs-popover"
                      />
                      <div class="input-group-append metallic-blue">
                        Radius(km)
                      </div>
                    </div>
                    <control-messages
                      [control]="
                        containerDetailsBuyerData.filterForm.controls.radius
                      "
                    ></control-messages>
                  </div>
                </div>
                <div class="col-lg-8 col-12">
                  <div class="form-group">
                    <div
                      class="multiselect"
                      (mouseleave)="containerTypeExpanded = false"
                    >
                      <div class="selectBox" (click)="containerTypeClick()">
                        <select
                          class="form-control"
                          id="box_containerType"
                          [ngClass]="{
                            'ng-dirty ng-valid':
                              containerTypeSelectedList?.length > 0,
                            'ng-touched ng-dirty': addContainerTypeTouched,
                            'ng-invalid':
                              containerDetailsBuyerData.filterForm.controls
                                .checklistType.status == 'INVALID'
                          }"
                        >
                          <option>Type*</option>
                        </select>
                      </div>

                      <div
                        [ngStyle]="{
                          display: containerTypeExpanded ? 'block' : 'none'
                        }"
                        class="p-2"
                        id="checkboxes"
                        style="height: 185px; overflow: overlay"
                      >
                        <div class="h-100" style="overflow: overlay">
                          <div
                            class="checkBoxDiv"
                            *ngFor="
                              let checklistType of containerDetailsBuyerData.containerTypeData;
                              let containerTypeIndex = index
                            "
                          >
                            <div *ngIf="!isFilteredType(checklistType)">
                              <input
                                [checked]="checkForValueType(checklistType)"
                                (change)="
                                  containerTypeServedListChange(
                                    $event,
                                    checklistType
                                  )
                                "
                                formControlName="checklistType"
                                name="checklistType"
                                type="checkbox"
                                id="containerTypeIndex-{{
                                  checklistType.containerTypeCode
                                }}"
                              />
                              <label
                                for="containerTypeIndex-{{
                                  checklistType.containerTypeCode
                                }}"
                              >
                                {{ checklistType.containerTypeCodeDesc }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <control-messages
                      [control]="
                        containerDetailsBuyerData.filterForm.controls
                          .checklistType
                      "
                    >
                    </control-messages>

                    <ul class="chipbox">
                      <li
                        *ngFor="
                          let containerType of getTypesForView();
                          let index = index
                        "
                      >
                        {{ containerType.containerTypeCodeDesc }}
                        <a
                          href="javascript:void(0)"
                          class="icn-close sm"
                          (click)="containerTypeRemove(containerType)"
                          >Close</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12">
                  <input
                    type="checkbox"
                    class="btn-check-cyan"
                    id="20DV"
                    value="20' Dry Van"
                    (change)="typeSelected('20DV', $event)"
                    autocomplete="off"
                  />
                  <label for="20DV">20' Dry Van</label>
                  <input
                    type="checkbox"
                    class="btn-check-cyan"
                    id="40DV"
                    value="40' Dry Van"
                    (change)="typeSelected('40DV', $event)"
                    autocomplete="off"
                  />
                  <label for="40DV">40' Dry Van</label>
                  <input
                    type="checkbox"
                    class="btn-check-cyan"
                    id="40HC"
                    avalue="40' High Cube"
                    (change)="typeSelected('40HC', $event)"
                    autocomplete="off"
                  />
                  <label for="40HC">40' High Cube</label>
                </div>
              </div>
              <div class="col-lg-6 col-12 p-0 p-lg-3 row m-0">
                <div class="col-12 col-lg-9">
                  <div class="form-group">
                    <input
                      #mapLocation2
                      type="text"
                      class="form-control location"
                      placeholder="Redelivery Locations*"
                      formControlName="reDeliveryLocations"
                    />
                    <control-messages
                      [control]="
                        containerDetailsBuyerData.filterForm.controls
                          .reDeliveryLocations
                      "
                    >
                    </control-messages>
                    <ul class="chipbox">
                      <li *ngFor="let loc of locs; let index = index">
                        {{ loc.location }}
                        <a
                          href="javascript:void(0)"
                          class="icn-close sm"
                          (click)="removeLocation(loc)"
                          >Close</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="form-group col-12 col-lg-9">
                  <div
                    class="multiselect"
                    (mouseleave)="containerGradeExpanded = false"
                  >
                    <div class="selectBox" (click)="containerGradeClick()">
                      <select
                        class="form-control"
                        id="box_containerGrade"
                        [ngClass]="{
                          'ng-dirty ng-valid':
                            containerGradeSelectedList?.length > 0,
                          'ng-touched ng-dirty': addContainerGradeTouched,
                          'ng-invalid':
                            containerDetailsBuyerData.filterForm.controls
                              .checklistGrade.status == 'INVALID'
                        }"
                      >
                        <option>Grade*</option>
                      </select>
                    </div>
                    <div class="p-2"></div>
                    <div
                      [ngStyle]="{
                        display: containerGradeExpanded ? 'block' : 'none'
                      }"
                      class="p-2"
                      id="checkboxes"
                    >
                      <div class="h-100" style="overflow: overlay">
                        <div
                          class="checkBoxDiv"
                          *ngFor="
                            let checklistGrade of containerDetailsBuyerData.containerGradeData;
                            let containerGradeIndex = index
                          "
                        >
                          <div *ngIf="!isFilteredGrade(checklistGrade)">
                            <input
                              [checked]="checkForValue(checklistGrade)"
                              (change)="
                                containerGradeServedListChange(
                                  $event,
                                  checklistGrade
                                )
                              "
                              formControlName="checklistGrade"
                              name="checklistGrade"
                              type="checkbox"
                              id="containerGradeIndex-{{ checklistGrade.code }}"
                            />
                            <label
                              for="containerGradeIndex-{{
                                checklistGrade.code
                              }}"
                            >
                              {{ checklistGrade.description }}
                            </label>
                          </div>
                        </div>
                      </div>
                      <control-messages
                        [control]="
                          containerDetailsBuyerData.filterForm.controls
                            .checklistGrade
                        "
                      >
                      </control-messages>
                    </div>
                  </div>
                  <ul class="chipbox">
                    <li
                      *ngFor="
                        let containerGrade of getGradesForView();
                        let index = index
                      "
                    >
                      {{ containerGrade.description }}
                      <a
                        href="javascript:void(0)"
                        class="icn-close sm"
                        (click)="containerGradeRemove(containerGrade)"
                        >Close</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="col-12">
                  <input
                    type="checkbox"
                    class="btn-check-cyan"
                    id="IICL"
                    autocomplete="off"
                    (change)="gradeSelected('IICL', $event)"
                  />
                  <label for="IICL">IICL</label>
                  <input
                    type="checkbox"
                    class="btn-check-cyan"
                    id="CW"
                    autocomplete="off"
                    (change)="gradeSelected('Cargo Worthy', $event)"
                  />
                  <label for="CW">Cargo Worthy</label>
                  <input
                    type="checkbox"
                    class="btn-check-cyan"
                    id="New"
                    autocomplete="off"
                    (change)="gradeSelected('New', $event)"
                  />
                  <label for="New">New</label>
                </div>
              </div>
            </div>
            <button
              (click)="gotoSearch()"
              class="btn btn-pink btn-block gtm-home-container-search col-lg-2 col-12"
              type="button"
              aria-label="apply"
            >
              <img
                src="{{
                  assetPath
                }}/images/new-svg/right-arrow-round-white-negative.svg"
                alt=""
              />
            </button>
          </div>
        </form>
        <ng-template #homeRadiusPopContent>
          <input
            type="range"
            min="100"
            max="1000"
            value="{{ getRadiusVal() }}"
            (change)="setRadiusVal($event.target.value)"
          />
          <div>
            <span class="pull-left">100</span>
            <span class="pull-right">1000</span>
          </div>
        </ng-template>
      </div>

      <!-- <div
      class="d-flex justify-content-around align-items-center text-white h-100"
    >
      <div>
        <h1 class="m-0">Buy. Sell. <span class="bold">In control</span></h1>

        <p>
          Save time and money by purchasing top-quality shipping conatiners at
          BOXXPORT. Try them out for 90 days and sell them back to us at
          pre-agreed price.
        </p>
        <p class="bold">Get the best deals and flexible options with us.</p>
      </div>
      <div class="d-flex flex-column align-item-center">
        <img src="{{ assetPath }}/images/new-svg/buybackicon.svg" />
        <button class="btn btn-pink">
          Register and begin
          <img
            class="pl-1"
            src="{{ assetPath }}/images/new-svg/rightpinkarrow.svg"
          />
        </button>
        <button class="btn btn-pink-outline">Learn More</button>
      </div>
    </div> -->
    </div>
  </div>
  <marketplace-buyback *ngSwitchCase="false"></marketplace-buyback>
</ng-container>
