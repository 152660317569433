import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { PostsDTO } from '@marketplace/dto/PostsDTO';
import { AbstractBaseComponent } from '@shared/abstracts/abstract-base.component';
import { ErrorUtils } from '@shared/constant/error.utils';
import { ConstantService } from '@shared/constant/shared.constant';

@Component({
  selector: 'landing-page-latest-posts',
  templateUrl: './landing-page-latest-posts.component.html',
  styleUrls: ['./landing-page-latest-posts.component.scss']
})
export class LandingPageLatestPostsComponent extends AbstractBaseComponent implements OnInit {

  @Input() isNewsSections: boolean = false;
  @Input() newsCategoryId: boolean;
  isAnimated = true;
  singleSlideOffset = true;
  activeSlideIndex = 0;
  showIndicators = false;
  itemsPerSlide = 3;
  interval = 0;
  postData = [];
  sectionName: string;
  page = 1;
  perPageFetchCount = 18;
  totalPostAvailable;
  postType: string;
  postUrl: string;
  constructor(private http: HttpClient) { super(); }

  ngOnInit() {
    if (this.isNewsSections) {
      this.sectionName = 'Latest News';
      this.postType = 'categories';
    } else {
      this.sectionName = 'Blogs';
      this.postType = 'categories_exclude';
    }
    if (ConstantService.get('WORDPRESS_DATA_URL')) {
      this.postUrl = `${ConstantService.get('WORDPRESS_DATA_URL')}posts?context=embed&_fields=type,link,title,jetpack_featured_media_url&page=${this.page}&per_page=${this.perPageFetchCount}&${this.postType}=${this.newsCategoryId}`;
      this.fetchData();
    }
  }
  fetchData() {
    if (ConstantService.get('WORDPRESS_DATA_URL')) {
      this.http.get(this.postUrl, { responseType: 'text', observe: 'response' })
        .subscribe(resp => {
          this.totalPostAvailable = resp.headers.get('x-wp-total');
          JSON.parse(resp.body).forEach(post => {
            this.postData.push(Object.assign(new PostsDTO(), post));
          });
        }, ErrorUtils.logError);
    }
  }
  gotRangeChange(event: any) {
    if (this.totalPostAvailable > this.postData.length) {
      const first = event[0];
      const d = Math.floor(this.postData.length / this.itemsPerSlide) - 2;
      if (d > 0 && first == d * this.itemsPerSlide) {
        this.page = this.page + 1;
        this.fetchData();
      }
    }
  }
  getTitle(title: string) {
    if (title.length > 100) {
      return title.substring(0, 100) + '...';
    }
    return title;
  }
}
