import { AbstractBaseService } from '@shared/abstracts/abstract-base-service';
import { ScriptService } from './script.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CustomCalendlyService extends AbstractBaseService {

    constructor(private scriptService: ScriptService) {
        super();
    }

    async load() {
        // const urls = 'https://assets.calendly.com/assets/external/widget.js';
        return this.scriptService.load('calendly_script').then(e => true);
    }
}
