import { Injectable } from "@angular/core";
import { PushNotificationService } from "@marketplace/service/push-notification.service";
import { AbstractBaseComponent } from "@shared/abstracts/abstract-base.component";
import { ConstantService } from "@shared/constant/shared.constant";

@Injectable({
  providedIn: "root",
})
export class PushNotificationUtils extends AbstractBaseComponent {
  constructor(private pushNotificationService: PushNotificationService) {
    super();
  }
  notificationWorkerPath = `${this.assetPath}/scripts/notification-worker.js`;
  askPermission() {
    return new Promise((resolve, reject) => {
      const permissionResult = Notification.requestPermission((result) => {
        console.log(result);
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
        console.log(permissionResult);
      }
    });
    // .then((permissionResult) => {
    //   if (permissionResult !== "granted") {
    //     throw new Error("We weren't granted permission.");
    //   }
    // });
  }
  async subscribeUserToPush() {
    return navigator.serviceWorker
      .register(this.notificationWorkerPath, { type: "module" })
      .then((registration) => {
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: ConstantService.get(
            "PUSH_NOTIFICATION_PUBLIC_KEY"
          ),
        };
        return new Promise((resolve) => {
          if (registration) {
            if (registration.active) {
              console.log(registration);
              resolve(registration.pushManager.subscribe(subscribeOptions));
            }
            registration.installing.addEventListener(
              "statechange",
              (e: any) => {
                console.log(e.target.state);
                if (e.target.state == "activated") {
                  resolve(registration.pushManager.subscribe(subscribeOptions));
                }
              }
            );
            // registration.addEventListener("updatefound", () => {
            //   registration.update();
            // });
          }
        });
      })
      .then((pushSubscription) => {
        console.log(
          "Received PushSubscription: ",
          JSON.stringify(pushSubscription)
        );
        this.pushNotificationService
          .setNotificationRegistration(pushSubscription)
          .subscribe(() => {});
        return pushSubscription;
      });
  }

  urlBase64ToUint8Array(base64String) {
    var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    var base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  }

  async checkForUpdates() {
    let registration = await this.lookForExistingWorker();
    console.log(registration);
    if (!registration) {
      return "install";
    } else if (
      registration.active.scriptURL.endsWith(this.notificationWorkerPath)
    ) {
      registration.update();
      return "update";
    } else {
      return "install";
    }
  }
  lookForExistingWorker() {
    return navigator.serviceWorker
      .getRegistration(this.notificationWorkerPath)
      .then((registration) => {
        console.log(registration);
        return registration;
      });
  }
  async removeServiceWorker() {
    let registration = await this.lookForExistingWorker();
    if (!registration) {
      return false;
    }
    return registration.unregister().then((status) => {
      return status;
    });
  }
  async getPushSubscription() {
    let registration = await this.lookForExistingWorker();
    if (!registration) {
      return false;
    }
    return registration.pushManager.getSubscription().then((subscription) => {
      return subscription;
    });
  }
}
