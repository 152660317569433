import { Component, Injector } from '@angular/core';
import { AbstractBaseStaticComponent } from '@marketplace/static/abstract/abstract-base-static.component';

@Component({
  templateUrl: './subscription-success.component.html',
  styleUrls: ['./subscription-success.component.scss']
})
export class SubscriptionSuccessComponent extends AbstractBaseStaticComponent {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

   OnInit(): void {
  }

}
