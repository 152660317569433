import { NgModule } from "@angular/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JwtModule, JWT_OPTIONS } from "@auth0/angular-jwt";
import { MarketplaceModuleRoutingModule } from "@marketplace/marketplace-routing.module";
import { MarketplaceAppComponent } from "@marketplace/marketplace.component";
import { PageNotFoundComponent } from "@marketplace/page-not-found/page-not-found.component";
import { FooterComponent } from "@marketplace/ui/footer/footer.component";
import { HeaderComponent } from "@marketplace/ui/header/header.component";
import { ZendeskWidgetConfig } from "@shared/chat-widget/zendesk/zendesk-widget.config";
import { CookieConsentModule } from "@shared/cookie-consent/cookie-consent.module";
import { LanguageTranslationModule } from "@shared/language-translation/language-translation.module";
import { NotificationWidgetModule } from "@shared/notification-widget/notification-widget.module";
import { SharedServiceModule } from "@shared/_services/shared-service.module";
import { ClickOutsideModule } from "ng-click-outside";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { CountdownFormatFn, CountdownGlobalConfig } from "ngx-countdown";
import { NgxZendeskWebwidgetModule } from "ngx-zendesk-webwidget";
import defaultLanguage from "projects/marketplace/src/assets/i18n/en.json";
import { AccessDeniedComponent } from "./access-denied/access-denied.component";
import { BeacomeAsSellerHomeComponent } from "./beacome-as-seller/beacome-as-seller-home/beacome-as-seller-home.component";
import { BuyerComponent } from "./buyer/buyer.component";
import { LandingPageFaqsComponent } from "./landing-page/landing-page-faqs/landing-page-faqs.component";
import { LandingPageInfoSectionComponent } from "./landing-page/landing-page-info-section/landing-page-info-section.component";
import { LandingPageLatestPostsComponent } from "./landing-page/landing-page-latest-posts/landing-page-latest-posts.component";
import { LandingPageTestemonialsComponent } from "./landing-page/landing-page-testemonials/landing-page-testemonials.component";
import { LandingPageWhyBoxxportComponent } from "./landing-page/landing-page-why-boxxport/landing-page-why-boxxport.component";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { MarketplaceCoreModule } from "./marketplace-core/marketplace-core.module";
import { BuyerDashboardService } from "./marketplace-dashboard-core/service/buyer-dashboard.service";
import { SellerDashboardService } from "./marketplace-dashboard-core/service/seller-dashboard.service";
import { MarketplaceProductCoreModule } from "./marketplace-product-core/marketplace-product-core.module";
import { MarketplaceProductCoreServiceModule } from "./marketplace-product-core/service/marketplace-product-core-service.module";
import { ProfileComponent } from "./profile/profile.component";
import { RootComponent } from "./root/root.component";
import { SellerComponent } from "./seller/seller.component";
import { CartPaymentNavigationGuard } from "./service/cart.payment.navigation.guard";
import { MarketplaceEventService } from "./service/marketplace-event.service";
import { PriceUpdateNavigationGuard } from "./service/price.update.navigation.guard";
import { ScrollService } from "./service/scroll.service";
import { StaticComponent } from "./static/static.component";
import { SubscriptionSuccessComponent } from "./subscription-success/subscription-success.component";
import { SidebarComponent } from "./ui/sidebar/sidebar.component";
import { UnderMaintenanceComponent } from "./under-maintenance/under-maintenance.component";
import { BlogsComponent } from "./blogs/blogs.component";

export function jwtOptionsFactory() {
  return {
    tokenGetter: () => {
      return null;
    },
  };
}

function countdownConfigFactory(): CountdownGlobalConfig {
  const countdownTimeUnits: Array<[string, number]> = [
    ["Y", 1000 * 60 * 60 * 24 * 365], // years
    ["M", 1000 * 60 * 60 * 24 * 30], // months
    ["D", 1000 * 60 * 60 * 24], // days
    ["H", 1000 * 60 * 60], // hours
    ["m", 1000 * 60], // minutes
    ["s", 1000], // seconds
    ["S", 1], // million seconds
  ];

  const formatDate: CountdownFormatFn = ({ date, formatStr, timezone }) => {
    let duration = Number(date || 0);

    return countdownTimeUnits.reduce((current, [name, unit]) => {
      if (current.indexOf(name) !== -1) {
        const v = Math.floor(duration / unit);
        duration -= v * unit;
        return current.replace(new RegExp(`${name}+`, "g"), (match: string) => {
          return v.toString().padStart(match.length, "0");
        });
      }
      return current;
    }, formatStr);
  };
  return { format: "DD:HH:mm:ss", formatDate } as CountdownGlobalConfig;
}
@NgModule({
  declarations: [
    MarketplaceAppComponent,
    PageNotFoundComponent,
    HeaderComponent,
    FooterComponent,
    SellerComponent,
    BuyerComponent,
    ProfileComponent,
    RootComponent,
    StaticComponent,
    SidebarComponent,
    BeacomeAsSellerHomeComponent,
    AccessDeniedComponent,
    UnderMaintenanceComponent,
    SubscriptionSuccessComponent,
    LandingPageComponent,
    LandingPageInfoSectionComponent,
    LandingPageWhyBoxxportComponent,
    LandingPageLatestPostsComponent,
    LandingPageFaqsComponent,
    LandingPageTestemonialsComponent,
    BlogsComponent,
  ],
  imports: [
    JwtModule.forRoot({
      config: {
        throwNoTokenError: false,
        skipWhenExpired: true,
      },
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
      },
    }),
    MatSnackBarModule,
    BrowserAnimationsModule,
    LanguageTranslationModule.forRoot(defaultLanguage),
    CookieConsentModule,
    MarketplaceCoreModule,
    SharedServiceModule,
    ClickOutsideModule,
    MarketplaceProductCoreModule,
    NotificationWidgetModule,
    MarketplaceProductCoreServiceModule,
    MarketplaceModuleRoutingModule,
    // ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    NgxZendeskWebwidgetModule.forRoot(ZendeskWidgetConfig),
    CarouselModule.forRoot(),
  ],
  providers: [
    MarketplaceEventService,
    ScrollService,
    SellerDashboardService,
    BuyerDashboardService,
    PriceUpdateNavigationGuard,
    CartPaymentNavigationGuard,
    { provide: CountdownGlobalConfig, useFactory: countdownConfigFactory },
  ],
  bootstrap: [MarketplaceAppComponent],
})
export class MarketplaceModule {}
