import { Component, OnInit } from '@angular/core';
import { AbstractBaseComponent } from '@shared/abstracts/abstract-base.component';

@Component({
  selector: 'marketplace-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent extends AbstractBaseComponent implements OnInit {

  constructor() {
    super();
  }
}
