import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractBaseService } from '@shared/abstracts/abstract-base-service';
import { BankAccountDTO } from '@shared/dto/BankAccountDTO';
import { BaseResponseDTO } from '@shared/dto/BaseResponseDTO';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BankAccountService extends AbstractBaseService {

    constructor(private http: HttpClient) { super(); }

    private baseUrl() {
        const apiUrl = `${this.apiBaseUrl}organization/account/details/`;
        return apiUrl;
    }

    getAllForDefaultOrg(): Observable<BaseResponseDTO<BankAccountDTO[]>> {
        return this.http.get<BaseResponseDTO<BankAccountDTO[]>>(this.baseUrl()).pipe(first());
    }

    getAll(orgIdentifier: any): Observable<BaseResponseDTO<BankAccountDTO[]>> {
        return this.http.get<BaseResponseDTO<BankAccountDTO[]>>(this.baseUrl() + 'org/' + orgIdentifier).pipe(first());
    }

    save(object: BankAccountDTO) {
        return this.http.post<BaseResponseDTO<any>>(this.baseUrl(), object).pipe(first());
    }

    update(identifier: string, object: BankAccountDTO) {
        return this.http.put<BaseResponseDTO<any>>(`${this.baseUrl()}${identifier}`, object).pipe(first());
    }

    delete(identifier: string) {
        return this.http.delete<BaseResponseDTO<any>>(`${this.baseUrl()}${identifier}`).pipe(first());
    }

    markAsDefault(identifier: string) {
        return this.http.put<BaseResponseDTO<any>>(`${this.baseUrl()}${identifier}/markAsDefault`, {}).pipe(first());
    }
    getByIdentifier(identifier: string): Observable<BaseResponseDTO<BankAccountDTO>> {
        return this.http.get<BaseResponseDTO<BankAccountDTO>>(this.baseUrl() + identifier).pipe(first());
    }
    // ##Replace
}
