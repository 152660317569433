import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractBaseService } from '@shared/abstracts/abstract-base-service';
import { AbstractUserAsSellerDTO } from '@shared/dto/AbstractUserAsSellerDTO';
import { BaseResponseDTO } from '@shared/dto/BaseResponseDTO';
import { UserAsSellerStep1DTO } from '@shared/dto/UserAsSellerStep1DTO';
import { UserAsSellerStep2DTO } from '@shared/dto/UserAsSellerStep2DTO';
import { UserAsSellerStep3DTO } from '@shared/dto/UserAsSellerStep3DTO';
import { UserAsSellerStep4DTO } from '@shared/dto/UserAsSellerStep4DTO';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserBecomeAsSellerService extends AbstractBaseService {

    constructor(private http: HttpClient) {
        super();
    }

    public asSellerGet(): Observable<BaseResponseDTO<AbstractUserAsSellerDTO>> {
        return this.http.get<BaseResponseDTO<AbstractUserAsSellerDTO>>('/api/users/asSeller/', {}).pipe(first());
    }

    public step1Get(): Observable<BaseResponseDTO<UserAsSellerStep1DTO>> {
        return this.http.get<BaseResponseDTO<UserAsSellerStep1DTO>>('/api/users/asSeller/step1', {}).pipe(first());
    }

    public step2Get(): Observable<BaseResponseDTO<UserAsSellerStep2DTO>> {
        return this.http.get<BaseResponseDTO<UserAsSellerStep2DTO>>('/api/users/asSeller/step2', {}).pipe(first());
    }

    public step2Post(userAsSellerStep2DTO: UserAsSellerStep2DTO): Observable<BaseResponseDTO<void>> {
        return this.http.post<BaseResponseDTO<void>>('/api/users/asSeller/step2', userAsSellerStep2DTO).pipe(first());
    }

    public step3Get(): Observable<BaseResponseDTO<UserAsSellerStep3DTO>> {
        return this.http.get<BaseResponseDTO<UserAsSellerStep3DTO>>('/api/users/asSeller/step3', {}).pipe(first());
    }

    public step4Get(): Observable<BaseResponseDTO<UserAsSellerStep4DTO>> {
        return this.http.get<BaseResponseDTO<UserAsSellerStep4DTO>>('/api/users/asSeller/step4', {}).pipe(first());
    }

    public step3Post(userAsSellerStep3DTO: UserAsSellerStep3DTO): Observable<BaseResponseDTO<void>> {
        return this.http.post<BaseResponseDTO<void>>('/api/users/asSeller/step3', userAsSellerStep3DTO).pipe(first());
    }

    public step4Post(): Observable<BaseResponseDTO<void>> {
        return this.http.post<BaseResponseDTO<void>>('/api/users/asSeller/step4', {}).pipe(first());
    }
    public verificationScheduled(): Observable<BaseResponseDTO<void>> {
        return this.http.post<BaseResponseDTO<void>>('/api/users/asSeller/verificationScheduled', {}).pipe(first());
    }

}
