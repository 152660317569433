import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AbstractBaseService } from '@shared/abstracts/abstract-base-service';
import { first } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class ClientIPService extends AbstractBaseService {

    private http: HttpClient;

    constructor(injector: Injector) {
        super();
        this.http = injector.get(HttpClient);
    }

    public getIPAddress() {
        return this.http.get(`/api/p/clientip`).pipe(first());
    }

}
