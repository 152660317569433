import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractBaseService } from '@shared/abstracts/abstract-base-service';
import { BaseResponseDTO } from '@shared/dto';

@Injectable({ providedIn: 'root' })
export class CompanyService extends AbstractBaseService {

    constructor(private http: HttpClient) { super(); }

    private apiUrl() {
        return `${this.apiBaseUrl}company/`;
    }

    defaultAddress(orgId?: number) {
        if (orgId) {
            return this.http.get<BaseResponseDTO<any>>(this.apiUrl() + `${orgId}/defaultAddress`);
        }
        return this.http.get<BaseResponseDTO<any>>(this.apiUrl() + 'defaultAddress');
    }

    defaultBillingAddress(orgId?: number) {
        if (orgId) {
            return this.http.get<BaseResponseDTO<any>>(this.apiUrl() + `${orgId}/defaultBillingAddress`);
        }
        return this.http.get<BaseResponseDTO<any>>(this.apiUrl() + 'defaultBillingAddress');
    }

    defaultPaymentAddress(orgId?: number) {
        if (orgId) {
            return this.http.get<BaseResponseDTO<any>>(this.apiUrl() + `${orgId}/defaultPaymentAddress`);
        }
        return this.http.get<BaseResponseDTO<any>>(this.apiUrl() + 'defaultPaymentAddress');
    }
}
