import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponseDTO } from '@shared/dto/BaseResponseDTO';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PingService {


    constructor(private http: HttpClient) { }

    pingGet() {
        return this.http.get<BaseResponseDTO<string>>('/api/p/ping/', {}).pipe(first());
    }

    pingAdminGet() {
        return this.http.get<BaseResponseDTO<string>>('/api/admin/secure/ping/', {}).pipe(first());
    }

    localeGet() {
        return this.http.get<BaseResponseDTO<string>>('/api/p/locale/', {}).pipe(first());
    }

    // ##Replace
}
