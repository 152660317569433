import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractBaseService } from '@shared/abstracts/abstract-base-service';
import { Utils } from '@shared/constant/shared.utils';
import { BaseResponseDTO } from '@shared/dto';

@Injectable({ providedIn: 'root' })
export class PermissionsService extends AbstractBaseService {

    constructor(private http: HttpClient) { super(); }

    private apiUrl() {
        return `${this.apiBaseUrl}permission/`;
    }

    permission() {
        return this.http.get<BaseResponseDTO<any>>(this.apiUrl());
    }

    isApproved(org: any) {
        if (org && org.orgType) {
            switch (org.orgType) {
                case 'BUY':
                    return org.buyerStatus === 'APPROVED';
                case 'BUY_SELL':
                    return org.sellerStatus === 'APPROVED';
            }
        }
        return false;
    }


    canHaveMultipleOrganization(orgs: any[]) {
        return this.isSellerAdmin(orgs);
    }

    canEditOrganization(org: any) {
        return this.isOrgAdmin(org);
    }

    canEditPaymentInformation(orgs: any[]) {
        return this.isSellerAdmin(orgs);
    }

    isSellerAdmin(orgs: any[]) {
        return Utils.isNotEmpty(orgs) && orgs.find(org => org.permission && org.permission.isAdmin && org.permission.roles.indexOf('SELL')) !== -1;
    }

    isBuyerAdmin(orgs: any[]) {
        return Utils.isNotEmpty(orgs) && orgs.find(org => org.permission && org.permission.isAdmin && org.permission.roles.indexOf('BUY')) !== -1;
    }

    isBuyer(orgs: any[]) {
        return Utils.isNotEmpty(orgs) && orgs.find(org => org.permission && org.permission.roles.indexOf('BUY')) !== -1;
    }

    isBuyerOrg(org: any) {
        return org && org.permission && org.permission.roles.indexOf('BUY') !== -1;
    }

    isSell(orgs: any[]) {
        return Utils.isNotEmpty(orgs) && orgs.find(org => org.permission && org.permission.roles.indexOf('SELL')) !== -1;
    }

    isSellOrg(org: any) {
        return org && org.permission && org.permission.roles.indexOf('SELL') !== -1;
    }

    isBuyerOrgAdmin(org: any) {
        return org && org.permission && org.permission.isAdmin && org.permission.roles.indexOf('BUY') !== -1;
    }

    isSellOrgAdmin(org: any) {
        return org && org.permission && org.permission.isAdmin && org.permission.roles.indexOf('SELL') !== -1;
    }

    isAdmin(orgs: any[]) {
        return Utils.isNotEmpty(orgs) && orgs.find(org => this.isOrgAdmin(org));
    }

    isOrgAdmin(org: any) {
        return org.permission && org.permission.isAdmin;
    }
}
