<div class="marginTB10">
  <span class="bold">Below details</span> will ONLY display data of
  <a
    [routerLink]="['/profile/org/listing']"
    [queryParams]="{ from: router.url }"
    class="bold mr-3"
    >Default Company ({{ organizationDTO.companyName }}).</a
  >
  <a
    class="btn btn-bluebg btn-xs font12"
    *ngIf="showRedirectButton"
    [routerLink]="['/profile/org/listing']"
    [queryParams]="{ from: router.url }"
    >Go to company details</a
  >
</div>
