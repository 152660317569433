import { Component, OnInit } from "@angular/core";
import { AbstractBaseComponent } from "@shared/abstracts/abstract-base.component";

@Component({
  selector: "marketplace-buyback",
  templateUrl: "./marketplace-buyback.component.html",
  styleUrls: ["./marketplace-buyback.component.scss"],
})
export class MarketplaceBuybackComponent
  extends AbstractBaseComponent
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
