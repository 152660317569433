import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { PreventBackService } from './prevent-back.guard';

@Injectable({
    providedIn: 'root'
})
export class PreventBackGuard implements CanDeactivate<any> {

    constructor(private preventBackService: PreventBackService) { }

    canDeactivate(component: any) {
        // will prevent user from going back
        if (this.preventBackService.getBackClicked()) {
            this.preventBackService.setBackClicked(false);
            // push current state again to prevent further attempts.
            history.pushState(null, null, location.href);
            return false;
        }
        return true;
    }
}
