import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractBaseService } from '@shared/abstracts/abstract-base-service';
import { Utils } from '@shared/constant/shared.utils';
import { AddContainerDescDTO } from '@shared/dto/AddContainerDescDTO';
import { AddContainerStep1DTO } from '@shared/dto/AddContainerStep1DTO';
import { AddContainerStep2DTO } from '@shared/dto/AddContainerStep2DTO';
import { AddContainerStep3DTO } from '@shared/dto/AddContainerStep3DTO';
import { AvailabilityUpdateDTO } from '@shared/dto/availabilityUpdateDTO';
import { BaseResponseDTO } from '@shared/dto/BaseResponseDTO';
import { ContainerDatesDto } from '@shared/dto/ContainerDatesDto';
import { ContainerDto } from '@shared/dto/ContainerDto';
import { ContainerPriceHistoryDTO } from '@shared/dto/ContainerPriceHistoryDTO';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SellerProductService extends AbstractBaseService {

    constructor(private http: HttpClient) { super(); }

    private productUrl(productIdentifier: string) {
        const baseUrl = `${this.apiBaseUrl}seller/product/`;
        if (productIdentifier) {
            return `${baseUrl}${productIdentifier}`;
        }
        return `${baseUrl}`;
    }

    updateProductPost(productIdentifier: string, object: any) {
        return this.http.post<BaseResponseDTO<ContainerDto>>(`${this.productUrl(productIdentifier)}/${productIdentifier}`, object).pipe(first());
    }

    deleteProductDelete(productIdentifier: string) {
        const headers = new HttpHeaders({ 'api-message-code': 'container.mark.deleted' });
        return this.http.post<BaseResponseDTO<boolean>>(`${this.productUrl(productIdentifier)}/markDeleted`, {}, { headers: headers }).pipe(first());
    }
    markProductInactive(productIdentifier: string) {
        const headers = new HttpHeaders({ 'api-message-code': 'container.mark.inactive' });
        return this.http.post<BaseResponseDTO<boolean>>(`${this.productUrl(productIdentifier)}/markInactive`, {}, { headers: headers }).pipe(first());
    }
    markProductAsActive(productIdentifier: string) {
        const headers = new HttpHeaders({ 'api-message-code': 'container.mark.active' });
        return this.http.post<BaseResponseDTO<boolean>>(`${this.productUrl(productIdentifier)}/markAsActive`, {}, { headers: headers }).pipe(first());
    }
    markProductActive(productIdentifier: string, containerDatesDTO: ContainerDatesDto) {
        const headers = new HttpHeaders({ 'api-message-code': 'container.mark.active' });
        return this.http.post<BaseResponseDTO<boolean>>(`${this.productUrl(productIdentifier)}/markActive`, containerDatesDTO, { headers: headers }).pipe(first());
    }

    updateQuantity(productIdentifier: string, quantity: number) {
        const headers = new HttpHeaders({ 'api-message-code': 'stock.quantity.updated' });
        return this.http.post<BaseResponseDTO<boolean>>(`${this.productUrl(productIdentifier)}/updateQuantity/${quantity}`, {}, { headers: headers }).pipe(first());
    }
    updateListing(productIdentifier: string, containerPriceHistoryDTO: ContainerPriceHistoryDTO) {
        return this.http.post<BaseResponseDTO<boolean>>(`${this.productUrl(productIdentifier)}/updateListing`, containerPriceHistoryDTO).pipe(first());
    }
    getProductByIdGet(productIdentifier: string) {
        return this.http.get<BaseResponseDTO<ContainerDto>>(`${this.productUrl(productIdentifier)}`, {}).pipe(first());
    }
    getProductTabByIdGet(productIdentifier: string) {
        return this.http.get<BaseResponseDTO<string>>(`${this.productUrl(productIdentifier)}/tab/status`, {}).pipe(first());
    }

    getProductByIdStep1Get(productIdentifier: string) {
        return this.http.get<BaseResponseDTO<AddContainerStep1DTO>>(`${this.productUrl(productIdentifier)}`, {}).pipe(first());
    }

    saveProductStep1Post(productIdentifier: string, object: any) {
        let apiUrl;
        if (productIdentifier) {
            apiUrl = `${this.productUrl(productIdentifier)}/`;
        } else {
            apiUrl = `${this.productUrl(productIdentifier)}`;
        }
        return this.http.post<BaseResponseDTO<ContainerDto>>(`${apiUrl}basicDetails`, object).pipe(first());
    }

    getProductByIdStep2Get(productIdentifier: string) {
        return this.http.get<BaseResponseDTO<AddContainerStep2DTO>>(`${this.productUrl(productIdentifier)}`, {}).pipe(first());
    }

    saveProductStep2Post(productIdentifier: string, object: any) {
        return this.http.post<BaseResponseDTO<ContainerDto>>(`${this.productUrl(productIdentifier)}/pricingDetails`, object).pipe(first());
    }

    getProductByIdStep3Get(productIdentifier: string) {
        return this.http.get<BaseResponseDTO<AddContainerStep3DTO>>(`${this.productUrl(productIdentifier)}`, {}).pipe(first());
    }

    saveProductStep3Post(productIdentifier: string, object: any) {
        return this.http.post<BaseResponseDTO<ContainerDto>>(`${this.productUrl(productIdentifier)}/discountDetails`, object).pipe(first());
    }

    getProductDescById(productIdentifier: string) {
        return this.http.get<BaseResponseDTO<AddContainerDescDTO>>(`${this.productUrl(productIdentifier)}`, {}).pipe(first());
    }

    saveProductDescByPost(productIdentifier: string, object: any) {
        return this.http.post<BaseResponseDTO<ContainerDto>>(`${this.productUrl(productIdentifier)}/desc`, object).pipe(first());
    }

    saveProductPreviewPost(productIdentifier: string) {
        return this.http.post<BaseResponseDTO<boolean>>(`${this.productUrl(productIdentifier)}/termsAndCondition`, {}).pipe(first());
    }

    submitProduct(productIdentifier: string) {
        return this.http.post<BaseResponseDTO<boolean>>(`${this.productUrl(productIdentifier)}/confirm`, {}).pipe(first());
    }
    searchProductsForTab(tab: string, object: object) {
        const params = Utils.toQueryParam(object);
        const url = `${this.productUrl(undefined)}search/${tab}`;
        return this.http.get<BaseResponseDTO<ContainerDto[]>>(url, { params }).pipe(first());
    }
    searchProductForTabCount(tab: string, object: object) {
        const params = Utils.toQueryParam(object);
        const url = `${this.productUrl(undefined)}search/count/${tab}`;
        return this.http.get<BaseResponseDTO<number>>(url, { params }).pipe(first());
    }
    updateContainerPrice(productIdentifier: string, object: any) {
        const headers = new HttpHeaders({ 'api-message-code': 'container.price.updated' });
        return this.http.post<BaseResponseDTO<ContainerDto>>(`${this.productUrl(productIdentifier)}/updatePrice`, object, { headers: headers }).pipe(first());
    }
    updateAvailability(productIdentifier: string, object: any) {
        return this.http.post<BaseResponseDTO<AvailabilityUpdateDTO>>(`${this.productUrl(productIdentifier)}/updateAvailability`, object, {}).pipe(first());
    }
    // ##Replace
}
