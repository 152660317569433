import { Component, Injector } from '@angular/core';
import { AbstractNavigationModalComponent } from '@marketplace/abstract/abstract-navigation-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'marketplace-cart-payment-modal',
  templateUrl: './cart-payment-modal.component.html',
  styleUrls: ['./cart-payment-modal.component.scss']
})
export class CartPaymentModalComponent extends AbstractNavigationModalComponent {


  constructor(
    protected injector: Injector,
    public bsModalRef: BsModalRef
  ) { super(bsModalRef, injector); }
}

