<div *ngIf="notification">
  <div class="row">
    <div class="col-lg-9 col-md-8" [innerHTML]="notification.heading"></div>
    <div class="col-lg-3 col-md-4 text-right">
      <div (click)="listingActionClick($event)">
        <shared-notification-widget-listing-item-action [userType]="userType" [config]="config" [notification]="notification">
        </shared-notification-widget-listing-item-action>
    </div>
    <div>{{notificationTime(notification) | date:'dd-MMM-yyyy hh:mm a'}}</div>
    </div>
  </div>
</div>
