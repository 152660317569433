import { Component, Input, OnInit } from "@angular/core";
import { AbstractBaseComponent } from "@shared/abstracts/abstract-base.component";
import { AuthSellerOnlyGuardService } from "@shared/auth/auth.selleronly.guard";
import { AuthService } from "@shared/auth/auth.service";

@Component({
  selector: "marketplace-radar",
  templateUrl: "./marketplace-radar.component.html",
  styleUrls: ["./marketplace-radar.component.scss"],
})
export class MarketplaceRadarComponent
  extends AbstractBaseComponent
  implements OnInit
{
  @Input() isLoggedIn = false;
  @Input() isSeller = false;
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
