import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';

import { BaseResponseDTO } from '@shared/dto/BaseResponseDTO';
@Injectable({ providedIn: 'root' })
export class UserWishlistService {


    constructor(private http: HttpClient) { }

    addProductToWishlistPost(object: any) {
        return this.http.post<BaseResponseDTO<any>>('/api/users/wishlist/{productId}', object).pipe(first());
    }

    wishlistGet(object: any) {
        return this.http.get<BaseResponseDTO<any[]>>('/api/users/wishlist/', object).pipe(first());
    }

    wishlistDelete(object: any) {
        return this.http.delete<BaseResponseDTO<any>>('/api/users/wishlist/{id}', object).pipe(first());
    }

    moveToCartPut(object: any) {
        return this.http.put<BaseResponseDTO<any>>('/api/users/wishlist/{id}/product/{productId}', object).pipe(first());
    }

    // ##Replace
}
