import { HttpClient } from '@angular/common/http';
import { Component, OnInit ,Input} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractBaseComponent } from '@shared/abstracts/abstract-base.component';
import { ConstantService } from '@shared/constant/shared.constant';
import { landingPageDTO } from '@shared/dto/LandingPageDTO';
import { MarketplaceLandingPageService } from '@shared/services/marketplace-landing-page.service';
import { CustomMetaTagService } from '@shared/_services/custom.meta.tag.service';
import { CustomCanonicalTagService } from '@shared/_services/custom.cannoincal.tag.service';
//import { HttpClient } from "@angular/common/http";
//import { Component, Injector, Input } from "@angular/core";
//import { Router } from "@angular/router";
import { AbstractBaseBlockUIComponent } from "@shared/abstracts/abstract-base-blockui.component";
import { AuthService } from "@shared/auth/auth.service";
//import { ConstantService } from "@shared/constant/shared.constant";
import { LocaleUtils } from "@shared/constant/shared.localeUtils";
import {
  GoogleMapMarkerClickEvent,
  MapLoaderService,
} from "@shared/map/map-loader.service";
import { Subscription } from "rxjs";
@Component({
  selector: 'marketplace-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent extends AbstractBaseComponent implements OnInit {
  sub: any;

  private pageUrl: string;
  landingPageDTO: landingPageDTO;
  newsCategoryId: string;
  toggleButtons = [
    {
      name: "Buy Containers",
      key: "default",
    },
    {
      name: "Sell Containers",
      key: "selling",
    }, 
    {
      name: "BuyBack",
      key: "buyback",
    },
    {
      name: "Container Radar",
      key: "radar",
    },
  ];
  @Input() searchStrategy: string = this.toggleButtons[0].key;

  isLoggedIn = false;
  isSeller = false;
  isNavOpen = false;
  landingpage;
  protected subs: Array<Subscription> = [];
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute, private metaService: CustomMetaTagService, private titleService: Title,
    private landingpageService: MarketplaceLandingPageService,
    private customCanonicalTagService: CustomCanonicalTagService,
    private authService: AuthService,
    private http: HttpClient) {
    super();
  }

  ngOnInit() {
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.pageUrl = params['url'];
      if (this.pageUrl) {
        this.landingpageService.getLandingPageDetailsByUrl(this.pageUrl)
          .subscribe(data => {
            if (data.result && ConstantService.get('WORDPRESS_DATA_URL')) {
              this.landingPageDTO = data.result;
              this.metaService.updateTag({ name: 'description', content: this.landingPageDTO.metaDescription });
              if (this.landingPageDTO.title) {
                this.titleService.setTitle(this.landingPageDTO.title);
                this.landingpage = this.landingPageDTO.location;
              }
              this.http.get(ConstantService.get('WORDPRESS_DATA_URL') + 'categories?_fields=name,slug,id', { responseType: 'text' }).subscribe(data => {
                const newsCategory = JSON.parse(data).filter(category => category['slug'] === 'boxxport-news')[0];
                this.newsCategoryId = newsCategory['id'];
              });
              this.customCanonicalTagService.createCanonicalURL();
            } else {
              this.redirectToDefaultHome();
            }
          }, error => {
            this.redirectToDefaultHome();
          });
      } else {
        this.redirectToDefaultHome();
      }
    });
    this.isLoggedIn = this.authService.isUserLoggedIn();
    this.isSellerUser();
    this.subscriptions.push(
      this.authService.getLoggedIn.subscribe((bool: boolean) => {
        this.isLoggedIn = bool;
        if (this.isLoggedIn) {
          this.isSellerUser();
        }
      })
    );
  }
  redirectToDefaultHome() {
    this.router.navigate(['/buyer/welcome']);
  }
  isSellerUser() {
    this.authService.isUserSeller().then((res) => {
      this.isSeller = res;
    });
}
}
