

import { BaseDTO } from '@shared/dto/BaseDTO';
import { GeoAddressDTO } from '@shared/dto/GeoAddressDTO';

export class AddressDTO extends BaseDTO {

    id: number;

    formattedAddress: string;

    addressLine1: string;

    addressLine2: string;

    city: string;

    state: string;

    zipCode: string;

    country: string;

    countryShortName: string;

    county: string;

    phoneNumber: string;

    isDefault: boolean;

    geoAddress: GeoAddressDTO;

    addressType: string;

    static toAddressString(addressDTO: AddressDTO) {
        let returnStr = '';
        if (addressDTO) {
            let needComma = false;
            [
                addressDTO.addressLine1,
                addressDTO.addressLine2,
                addressDTO.zipCode,
                addressDTO.city,
                addressDTO.state,
                addressDTO.county,
                addressDTO.country
            ].forEach(value => {
                if (value) {
                    if (needComma) {
                        returnStr += ', ';
                    }
                    returnStr += value;
                    needComma = true;
                }
            });
            return returnStr;
        }
    }

    static toAddressStringWoPostalCode(addressDTO: AddressDTO) {
        let returnStr = '';
        if (addressDTO) {
            let needComma = false;
            [
                addressDTO.addressLine1,
                addressDTO.addressLine2,
                addressDTO.city,
                addressDTO.state,
                addressDTO.county,
                addressDTO.country
            ].forEach(value => {
                if (value) {
                    if (needComma) {
                        returnStr += ', ';
                    }
                    returnStr += value;
                    needComma = true;
                }
            });
            return returnStr;
        }
    }

}
