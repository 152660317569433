import {
  Component,
  Injector,
  Input,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { AbstractDashboardCoreComponent } from "@marketplace/marketplace-dashboard-core/abstract/abstract-dashboard-core.component";

import { AbstractBaseComponent } from "@shared/abstracts/abstract-base.component";
import { AuthService } from "@shared/auth/auth.service";
import { OrganizationDTO } from "@shared/dto/OrganizationDTO";
import { OrganizationService } from "@shared/services/organization.service";
import { Observable } from "rxjs";
import { finalize, map } from "rxjs/operators";

@Component({
  selector: "marketplace-sell-container",
  templateUrl: "./marketplace-sell-container.component.html",
  styleUrls: ["./marketplace-sell-container.component.scss"],
})
export class MarketplaceSellContainerComponent extends AbstractDashboardCoreComponent {
  @Input() isLoggedIn = false;
  @Input() isSeller = false;
  orderCount = 0;
  listingCount = 0;
  negotiationCount = -1;
  orgDTO: Observable<OrganizationDTO>;

  constructor(
    protected injector: Injector,
    private orgService: OrganizationService
  ) {
    super(injector);
  }

  OnInit(): void {
    this.orgDTO = this.orgService
      .getCurrentUserOrgnization()
      .pipe(map((res) => res.result));
    this.userType = "seller";
    this.startBlockUi();
    this.sellerDashboardService
      .ordersCount(this.userType, "-1", "live")
      .pipe(
        finalize(() => {
          this.stopBlockUi();
          if (this.orderCount === -1) {
            this.orderCount = 0;
          }
        })
      )
      .subscribe((data) => {
        this.orderCount = data.result || 0;
      });
    this.sellerDashboardService
      .containerCount(this.userType, "-1", "ACTIVE")
      .pipe(
        finalize(() => {
          this.stopBlockUi();
          if (this.listingCount === -1) {
            this.listingCount = 0;
          }
        })
      )
      .subscribe((data) => {
        this.listingCount = data.result || 0;
      });
  }

  ngOnInit(): void {
    if (this.isSeller) {
      this.OnInit();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.isSeller) {
      this.OnInit();
    }
  }
}
