import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseResponseDTO } from "@shared/dto";
import { NegotiationDTO } from "@shared/dto/NegotiationDTO";
import { AbstractDashboardService } from "../abstract/abstract-dashboard.service";

@Injectable({ providedIn: "root" })
export class BuyerDashboardService extends AbstractDashboardService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  public followingListing(
    userType: string,
    orgIdentifiers: string[] | string = "-1"
  ) {
    const params = new HttpParams().set("isExport", "false");
    return this.httpClient.get<BaseResponseDTO<any>>(
      this.url(userType, orgIdentifiers, "followingListing"),
      {
        params,
      }
    );
  }
  public negotiations(
    userType: string,
    status,
    orgIdentifiers: string[] | string = "-1"
  ) {
    const params = new HttpParams().set("isExport", "false");
    const url =
      this.url(userType, orgIdentifiers, "negotiations") + "/" + status;
    return this.httpClient.get<BaseResponseDTO<NegotiationDTO[]>>(url, {
      params,
    });
  }
  public purchaseUnitCount(
    userType: string,
    orgIdentifiers: string[] | string = "-1"
  ) {
    return this.httpClient.get<BaseResponseDTO<any>>(
      this.url(userType, orgIdentifiers, "purchaseUnitCount")
    );
  }

  public todoPendingNegotiationCountGet(
    userType: string,
    orgIdentifiers: string[] | string = "-1"
  ) {
    return this.httpClient.get<BaseResponseDTO<Number>>(
      this.url(userType, orgIdentifiers, "todo/negotiations")
    );
  }

  // https://api-local.boxxport-internal.com/admin/getPricing/details/callPricingDetailsReport?p_user_email=Andrii.shevchuk@boxxport.com&p_is_buyer=0&p_cnt_type=20DV&p_cnt_grade=CW&p_city=hamburg&p_state=hamburg&p_country=Germany&p_lat=53.551086&p_long=9.993682
  public pricingReports(userType: number, filterData?: object, email?: string) {
    let params = new HttpParams();
    if (!filterData) {
      params = new HttpParams()
        .set("p_user_email", email)
        .set("p_is_buyer", userType)
        .set("p_cnt_type", null)
        .set("p_cnt_grade", null)
        .set("p_city", null)
        .set("p_state", null)
        .set("p_country", null)
        .set("p_lat", null)
        .set("p_long", null);
    } else {
      Object.entries(filterData).forEach((item) => {
        params = params.set("p_" + item[0], item[1]);
      });
      params = params.set("p_user_email", email).set("p_is_buyer", userType);
      //   .set("p_cnt_type", null)
      //   .set("p_cnt_grade", null)
      //   .set("p_city", null)
      //   .set("p_state", null)
      //   .set("p_country", null)
      //   .set("p_lat", null)
      //   .set("p_long", null);
    }
    return this.httpClient.get<BaseResponseDTO<any>>(
      `${this.apiBaseUrl}admin/getPricing/details/callPricingDetailsReport`,
      { params: params }
      //     return this.httpClient.get<BaseResponseDTO<any>>(
      //       `${this.apiBaseUrl}admin/getPricing/details/callPricingDetailsReport?p_user_email=Andrii.shevchuk@boxxport.com&p_is_buyer=0&p_cnt_type=20DV&p_cnt_grade=CW&p_city=hamburg&p_state=hamburg&p_country=Germany&p_lat=53.551086&p_long=9.993682
      // `
    );
  }
}
