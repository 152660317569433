import { Injectable } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';

let evenCounter = 4;
let oddCounter = 3;

const evenCount = () => {
    evenCounter = evenCounter + 2;
    return evenCounter;
};
const oddCount = () => {
    oddCounter = oddCounter + 2;
    return oddCounter;
};

@Injectable({ providedIn: 'root' })
export class NavigationService {

    public lastValue: any;
    public newValue: any;
    public animationValue: any;

    constructor(
        private router: Router,
    ) {
        this.router
            .events
            .subscribe((event) => {
                if (event instanceof ActivationEnd) {
                    const snapshot = event.snapshot;
                    const child = snapshot.children.length;
                    if (snapshot.data.num && snapshot.data.num > -1 && child === 0) {
                        this.lastValue = this.newValue;
                        this.newValue = snapshot.data.num;
                        if (this.lastValue !== this.newValue) {
                            this.animationValue = this.getAnimationNumber(this.lastValue, this.newValue);
                        }
                    }
                }
            });
    }

    getAnimationNumber(lastValue: number, newValue: number) {
        let result = 0;
        if (lastValue && newValue) {
            result = 2;
            if (lastValue) {
                if (newValue > lastValue) {
                    result = evenCount();
                } else {
                    result = oddCount();
                }
            } else {
                result = 1;
            }
        }
        return result;
    }
}
