<div class="container testimonials">
    <h2 class="font36">Testimonials</h2>
    <div class="row">
        <div class="col-md-8 offset-md-2 col-10 offset-1 text-center boxxportSlider">
            <carousel [isAnimated]="true">
                <slide *ngFor="let slide of testimonials; let index=index">
                    <p>{{slide.mesg}}</p>
                    <div class="testilogo">
                        <img [src]="slide.img" alt="">
                    </div>
                    <div class="testititle">
                        {{slide.title}}
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
</div>