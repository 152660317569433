import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@shared/auth/auth.service";

@Component({
  selector: "marketplace-get-started-action",
  templateUrl: "./marketplace-get-started-action.component.html",
  styleUrls: ["./marketplace-get-started-action.component.scss"],
})
export class MarketplaceGetStartedActionComponent implements OnInit {
  redirectLink;

  @Input() css = "btn btn-pink pl-4 pr-4";

  @Input() text = "Get started";

  @Input() link = "/";

  @Input() nonLoggedInLink = "/signup";

  constructor(private authService: AuthService, public router: Router) {}

  ngOnInit() {
    this.redirectLink = this.authService.isUserLoggedIn()
      ? this.link
      : this.nonLoggedInLink;
  }
}
