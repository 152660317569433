import {
  ApplicationRef,
  ComponentFactoryResolver,
  ElementRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  ViewContainerRef,
} from "@angular/core";
import { MarketplaceAppComponent } from "@marketplace/marketplace.component";
import { PlacesAutocompleteComponent } from "./places-autocomplete/places-autocomplete.component";

@Injectable({
  providedIn: "root",
})
export class MapUtilsService {
  viewRef: ViewContainerRef;
  constructor(private applicationRef: ApplicationRef) {
    //uncomment one property at main component ts file to use this
    // this.viewRef = (
    //   applicationRef.components[0].instance as MarketplaceAppComponent
    // ).viewRootRef;
  }
  initPlaceResults(
    inputRef: ElementRef,
    inputCallback: (placeResult: google.maps.places.PlaceResult) => void
  ) {
    console.log(this.viewRef);
    let placesRef = this.viewRef.createComponent(PlacesAutocompleteComponent);
    console.log(placesRef);
    placesRef.instance.input = inputRef;
    placesRef.instance.output.subscribe((res) => {
      inputCallback(res);
    });
  }
}
