import { Location } from "@angular/common";
import { Component, Injector } from "@angular/core";
import { PushNotificationUtils } from "@marketplace/utils/PushNotification.utils";
import { TranslateService } from "@ngx-translate/core";
import { NotificationModel } from "../dto/NotificationModel.dto";
import { NotificationWidgetListingComponent } from "../notification-widget-listing/notification-widget-listing.component";

@Component({
  selector: "shared-notification-widget-listing-small",
  templateUrl: "./notification-widget-listing-small.component.html",
  styleUrls: ["./notification-widget-listing-small.component.scss"],
})
export class NotificationWidgetListingSmallComponent extends NotificationWidgetListingComponent {
  constructor(
    protected injector: Injector,
    protected translateService: TranslateService,
    protected location: Location
  ) {
    super(injector, translateService, location);
  }

  hideWindow() {
    this.hideEvent.emit(true);
  }

  isShow(notificationModels: NotificationModel[]) {
    return notificationModels.find((e) => e.status !== "HIDE");
  }
}
