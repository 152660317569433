import { Component, Input } from '@angular/core';
import { AbstractBaseComponent } from '@shared/abstracts/abstract-base.component';
import { landingPageSectionDTO } from '@shared/dto/LandingPageSectionDTO';

@Component({
  selector: 'landing-page-info-section',
  templateUrl: './landing-page-info-section.component.html',
  styleUrls: ['./landing-page-info-section.component.scss']
})
export class LandingPageInfoSectionComponent extends AbstractBaseComponent {

  @Input() sectionDetail: landingPageSectionDTO;
  constructor() { super(); }

}
