<main class="main">
   <div class="marginTB20">
        <a href="javascript:void(0);" (click)="goBack()" class="bold marginT10 marginL20">&lt; Back</a>
        <h1 class="marginTB10 marginL20 bold">Notifications</h1>
       <div class="container">
        <div class="row" *ngIf="notificationMap.size === 0">
            <div class="col-lg-9" *ngIf="isLoading">
                Loading....
            </div>
            <div class="col-lg-9" *ngIf="!isLoading">
                <shared-notification-widget-no-notification></shared-notification-widget-no-notification>
            </div>
        </div>
        <div class="row" *ngIf="notificationMap.size>0">
            <div class="col-lg-9">
               <ng-container *ngFor="let notificationObject of notificationMap | keyvalue: reverseKeyOrder">
                    <h2 class="font16 marginTB20">
                        <shared-notification-widget-listing-header [config]="config" [heading]="notificationObject.key">
                        </shared-notification-widget-listing-header>
                    </h2>
                    <ul class="notification">
                        <li *ngFor="let notification of notificationObject.value" [class]="notificationStatus(notification).toLowerCase()">
                            <shared-notification-widget-listing-item [userType]="userType" [config]="config" [notification]="notification" (click)="listingItemClick($event, notification)">
                            </shared-notification-widget-listing-item>
                        </li>
                    </ul>
                </ng-container>
                <div *ngIf="((page+1)* size)<notificationCount?.total" class="text-center">
                    <span class="text-blue bold cursorPointer" (click)="seeMore()">See more</span>
                </div>
            </div>
        </div>
    </div>
</div>
</main>
