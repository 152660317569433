import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { BaseResponseDTO } from '@shared/dto/BaseResponseDTO';
@Injectable({ providedIn: 'root' })
export class UserPublicRegistrationService {


    constructor(private http: HttpClient) { }

    checkEmailAvailabilityPost(object: any) {
        return this.http.post<BaseResponseDTO<any>>
            ('/api/p/users/registration/checkEmailAvailability', object).pipe(first());
    }

    registerUserPost(object: any) {
        return this.http.post<BaseResponseDTO<any>>('/api/p/users/registration/', object).pipe(first());
    }

    verifyEmailPost(object: any) {
        return this.http.post<BaseResponseDTO<any>>('/api/p/users/registration/verify', object).pipe(first());
    }

    // ##Replace
}
