import { HttpParams } from '@angular/common/http';
export class PageRequest {

    page = 0;

    size = 10;


    public static toQueryParam(pageRequest: PageRequest): HttpParams {
        const page = pageRequest.page;
        const size = pageRequest.size;
        return new HttpParams().set('page', page.toString()).set('size', size.toString());
    }
}
export const DEFAULT_PAGE_REQUEST = new PageRequest();
