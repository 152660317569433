<main class="main">
    <!-- Main Content Section  -->
    <div class="rightSection">
        <div *ngIf="fromDifferentOrganization" class="row">
            <div class="col-md-8 offset-md-2 dataNotFound">
                <p class="marginB20"><img src="{{assetPath}}/images/svg/icn-listing.svg" alt="" width="50"></p>
                The Listings are under ‘<span class="bold">{{organizationDTO.companyName}}</span>’. <br>
                To see Listing details, go to your <a class="bold" href="javascript:void(0)"
                    (click)="gotoCompanyDetail()">Company
                    details</a> and set ‘<span class="bold">{{organizationDTO.companyName}}</span>’ as default.
            </div>
        </div>
        <div class="middle" *ngIf="organizationDTO && !fromDifferentOrganization">
            <marketplace-update-default-company-header [organizationDTO]="organizationDTO">
            </marketplace-update-default-company-header>
            <h1 class="margin0 marginB10">Update your Stocks</h1>
            <div class="marginB10">Please update your stocks availability and prices below to keep your stocks
                up to date</div>
            <div class="tab-pane fade show active" id="active" role="tabpanel" aria-labelledby="active-tab">
                <div class="row">
                    <div class="col-sm-5">
                        <div *ngIf="error" class="text-red marginT10">
                            <img src="{{assetPath}}/images/svg/icn-alert.svg" alt=""> {{error}}
                        </div>
                    </div>
                    <div class="col-sm-7">
                        <div class="filter text-right marginT10">
                            <span class="marginR20">
                                <marketplace-product-listing-pagination [defaultPage]="pageCount"
                                    [totalCount]="totalCount" [defaultLength]="pageLength"
                                    (paginationEvent)="paginationEvent($event)">
                                </marketplace-product-listing-pagination>
                            </span>
                        </div>
                    </div>
                </div>
                <hr class="margin0 marginT10 dark">
                <div *ngIf="!containers" class="pt-5 mt-5 pb-5 text-center">
                    <img src="{{assetPath}}/images/svg/icn-listing-40px.svg" width="100" class="opacity50" alt="">
                    <div class="mb-4 mt-4 text-grey-blue">There are no Listing here yet. </div>
                </div>
                <div *ngIf="containers" class="marginR10">
                    <table class="table borderedBody table-sm table-tab">
                        <thead>
                            <tr>
                                <th (click)='sort("LOCATION")' [class]="addSorting('LOCATION')">
                                    <span>Location</span>
                                </th>
                                <th (click)='sort("TYPE")' [class]="addSorting('TYPE')"><span>Container Type</span></th>
                                <th (click)='sort("GRADE")' [class]="addSorting('GRADE')"><span>Grade</span>
                                </th>
                                <th> Live date</th>
                                <th class="text-right" (click)='sort("QUANTITY")' [class]="addSorting('QUANTITY')">
                                    <span>Stocks</span>
                                </th>
                                <th class="text-lg-right">Current sales <span class="priceSpace">price</span>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let container of containers" seller-listing-update-item [container]="container"
                                (reloadPage)="reloadPage($event)"></tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-2"><a href="{{productListingLink}}" class="btn btn-primary btn-block">Listing
                        Management</a></div>
            </div>
        </div>
    </div>