<main *ngIf="sidebars" class="main">
  <div class="d-flex">
    <div class="sidebar">
      <ul>
        <li
          id="switch"
          class="{{ isSeller ? 'sellerDashboard' : 'buyerDashboard' }}"
          container="body"
          popover="{{ isSeller ? 'Switch to Buy' : 'Switch to Sell' }}"
          triggers="mouseenter:mouseleave"
          containerClass="{{ isSeller ? 'blue' : 'orange' }}"
        >
          <input
            (click)="toggleDashboard()"
            type="checkbox"
            name="switch"
            id="sellbuy"
            [checked]="!isSeller"
            value="on"
          />
          <label for="sellbuy">Sell <br />Buy</label>
        </li>

        <ng-container *ngFor="let sidebar of sidebars">
          <li
            *ngIf="!sidebar.hide"
            container="body"
            [class]="sidebar.color"
            popover="{{ sidebar.label }}"
            triggers="mouseenter:mouseleave"
            containerClass="font16"
          >
            <a
              [routerLink]="[sidebar.routerLink]"
              routerLinkActive="active"
              [class.cursorDiabled]="sidebar.isDisabled"
              [routerLinkActiveOptions]="{ exact: false }"
            >
              <span [class]="sidebar.class">{{ sidebar.label }}</span>
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="rightSection">
      <router-outlet (activate)="setRoutedComponent($event)"></router-outlet>
    </div>
  </div>
</main>
<div *ngIf="!sidebars">
  <router-outlet (activate)="setRoutedComponent($event)"></router-outlet>
</div>
