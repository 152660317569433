import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';

import { BaseResponseDTO } from '@shared/dto/BaseResponseDTO';
import { ChangePasswordModel } from '@shared/dto';
import { InviteMemberModel } from '@shared/dto/InviteMemberModel';
@Injectable({ providedIn: 'root' })
export class UserService {


    constructor(private http: HttpClient) { }

    changePasswordPut(changePassword: ChangePasswordModel) {
        return this.http.put<BaseResponseDTO<any>>('/api/users/changePassword', changePassword).pipe(first());
    }

    logoutPut(object: any) {
        return this.http.put<BaseResponseDTO<any>>('/api/users/logout', object).pipe(first());
    }
    sendMemberInvitation(inviteMemberModel: InviteMemberModel) {
        return this.http.post<BaseResponseDTO<any>>('/api/users/invite/member', inviteMemberModel).pipe(first());
    }
    getInvitationRoles() {
        return this.http.get<BaseResponseDTO<any>>('/api/users/invite/member/roles').pipe(first());
    }

    updateMember(inviteMemberModel: InviteMemberModel, memberIdentifier: number) {
        const url = `/api/organization/profile/${memberIdentifier}`;
        return this.http.post<BaseResponseDTO<any>>(url, inviteMemberModel).pipe(first());
    }
    // ##Replace
}
