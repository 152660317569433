import { MapsAPILoader } from "@agm/core";
import { Injectable, EventEmitter, Output } from "@angular/core";
import { AbstractBaseService } from "@shared/abstracts/abstract-base-service";

export interface MapLoaderOption {
  nativeElement: HTMLInputElement;

  onLoad: () => void;

  onAutoCompleteLoad: (autocomplete: google.maps.places.Autocomplete) => void;

  listenerCallback: (place: google.maps.places.PlaceResult) => void;

  defaultNullCheck: boolean;
}

export interface GoogleMapMarkerClickEvent {
  latitude: number;
  longitude: number;
  zoomLevel: any;
  city: string;
  state: string;
  country: string;
}

@Injectable({ providedIn: "root" })
export class MapLoaderService extends AbstractBaseService {
  @Output()
  public callToGoogleMapMarkerClickEvent: EventEmitter<GoogleMapMarkerClickEvent> =
    new EventEmitter();
  constructor(private mapsAPILoader: MapsAPILoader) {
    super();
  }

  mapLoader(mapLoaderOption: MapLoaderOption, opts?: any) {
    this.mapsAPILoader.load().then(() => {
      const onLoad = mapLoaderOption.onLoad;
      if (onLoad) {
        onLoad();
      } else {
        const autocomplete = new google.maps.places.Autocomplete(
          mapLoaderOption.nativeElement,
          opts
        );
        const onAutoCompleteLoad = mapLoaderOption.onAutoCompleteLoad;
        if (onAutoCompleteLoad) {
          onAutoCompleteLoad(autocomplete);
        } else {
          let listenerCallback = mapLoaderOption.listenerCallback;
          if (mapLoaderOption.defaultNullCheck) {
            listenerCallback = (place: google.maps.places.PlaceResult) => {
              this.defaultNullCallback(place, mapLoaderOption.listenerCallback);
            };
          }
          this.addListener(autocomplete, listenerCallback);
        }
      }
    });
  }

  getPlacePredictions(input: any) {
    return new Promise<any[]>((resolve) => {
      this.mapsAPILoader.load().then(() => {
        const autocompleteService =
          new google.maps.places.AutocompleteService();
        if (input) {
          autocompleteService.getPlacePredictions(
            { input },
            (result: any[], status: any) => {
              resolve(result);
              // resolve(result);
            }
          );
        } else {
          resolve([]);
        }
      });
    });
  }

  addListener(
    autocomplete: google.maps.places.Autocomplete,
    callback: (place: google.maps.places.PlaceResult) => void
  ) {
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      callback(place);
    });
  }

  addZoomListener(
    map: google.maps.Map,
    callback: (zoomNumber: number) => void
  ) {
    map.addListener("zoom_changed", () => {
      const zoomNumber = map.getZoom();
      callback(zoomNumber);
    });
  }

  defaultNullCallback(
    place,
    callback: (place: google.maps.places.PlaceResult) => void
  ) {
    console.log(place);
    if (!place || !place.geometry) {
      window.alert('No details available for input: "' + place.name + '"');
      return;
    }
    callback(place);
  }
}
