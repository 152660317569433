import { ConstantService, STORAGE_PREFIX } from '@shared/constant/shared.constant';
import { Utils } from '@shared/constant/shared.utils';

export abstract class AbstractStorageService<T> {

    private storage;

    private prefix;

    constructor(storage: Storage) {
        this.storage = storage;
        this.prefix = ConstantService.get(STORAGE_PREFIX);
    }

    public createId() {
        const id = this.prefix + '_' + Utils.uuid();
        return id;
    }

    public setItem(id: string, object: T) {
        const strObject = typeof object === 'string' ? object : JSON.stringify(object);
        this.storage.setItem(id, strObject);
    }

    public getItem(id: string): any {
        const strObject: string = this.storage.getItem(id);
        return strObject ? JSON.parse(strObject) : null;
    }
}
