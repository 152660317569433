export interface PlaceResultOption {
  name: string;

  city: string;

  state: string;

  country: string;

  countryShortName: string;

  zipCode: string;

  latitude: number;

  longitude: number;

  location: string;

  addressLine1: string;

  addressLine2: string;
}

export class MapUtils {
  public static placeResultGeoCoder(
    place: google.maps.GeocoderResult
  ): PlaceResultOption {
    let addressLine1 = null;
    let addressLine2 = null;
    const returnObject = {} as PlaceResultOption;
    if (place.geometry) {
      if (place.geometry.location) {
        returnObject.latitude = place.geometry.location.lat();
        returnObject.longitude = place.geometry.location.lng();
      }
    }
    returnObject.location = place.formatted_address;
    place.address_components.forEach((addressComponent) => {
      addressComponent.types.forEach((addressType) => {
        switch (addressType) {
          case "floor":
            if (addressLine1) {
              addressLine1 = addressLine1 + ", " + addressComponent.long_name;
            } else {
              addressLine1 = addressComponent.long_name;
            }
            break;
          case "street_number":
            if (addressLine1) {
              addressLine1 = addressLine1 + ", " + addressComponent.long_name;
            } else {
              addressLine1 = addressComponent.long_name;
            }
            break;
          case "route":
            if (addressLine1) {
              addressLine1 = addressLine1 + ", " + addressComponent.long_name;
            } else {
              addressLine1 = addressComponent.long_name;
            }
            break;
          case "locality":
            if (addressLine2) {
              addressLine2 = addressLine2 + ", " + addressComponent.long_name;
            } else {
              addressLine2 = addressComponent.long_name;
            }
            if (!returnObject.city) {
              returnObject.city = addressComponent.long_name;
            }
            break;
          case "administrative_area_level_2":
            if (addressComponent.long_name) {
              returnObject.city = addressComponent.long_name;
            }
            break;
          case "administrative_area_level_1":
            returnObject.state = addressComponent.long_name;
            break;
          case "country":
            returnObject.country = addressComponent.long_name;
            returnObject.countryShortName = addressComponent.short_name;
            break;
          case "postal_code":
            returnObject.zipCode = addressComponent.long_name;
            break;
        }
      });
    });
    returnObject.addressLine1 = addressLine1;
    returnObject.addressLine2 = addressLine2;
    return returnObject;
  }

  public static placeResult(
    place: google.maps.places.PlaceResult
  ): PlaceResultOption {
    let addressLine1 = null;
    let addressLine2 = null;
    const returnObject = {} as PlaceResultOption;
    returnObject.name = place.name;
    if (place.geometry) {
      if (place.geometry.location) {
        returnObject.latitude = place.geometry.location.lat();
        returnObject.longitude = place.geometry.location.lng();
      }
    }
    returnObject.location = place.formatted_address;
    place.address_components.forEach((addressComponent) => {
      addressComponent.types.forEach((addressType) => {
        switch (addressType) {
          case "floor":
            if (addressLine1) {
              addressLine1 = addressLine1 + ", " + addressComponent.long_name;
            } else {
              addressLine1 = addressComponent.long_name;
            }
            break;
          case "street_number":
            if (addressLine1) {
              addressLine1 = addressLine1 + ", " + addressComponent.long_name;
            } else {
              addressLine1 = addressComponent.long_name;
            }
            break;
          case "route":
            if (addressLine1) {
              addressLine1 = addressLine1 + ", " + addressComponent.long_name;
            } else {
              addressLine1 = addressComponent.long_name;
            }
            break;
          case "locality":
            if (addressLine2) {
              addressLine2 = addressLine2 + ", " + addressComponent.long_name;
            } else {
              addressLine2 = addressComponent.long_name;
            }
            if (!returnObject.city) {
              returnObject.city = addressComponent.long_name;
            }
            if (!returnObject.state) {
              returnObject.state = addressComponent.long_name;
            }
            break;
          case "administrative_area_level_2":
            if (addressComponent.long_name) {
              returnObject.city = addressComponent.long_name;
            }
            break;
          case "administrative_area_level_1":
            returnObject.state = addressComponent.long_name;
            break;
          case "country":
            returnObject.country = addressComponent.long_name;
            returnObject.countryShortName = addressComponent.short_name;
            break;
          case "postal_code":
            returnObject.zipCode = addressComponent.long_name;
            break;
        }
      });
    });
    returnObject.addressLine1 = addressLine1;
    returnObject.addressLine2 = addressLine2;
    return returnObject;
  }
}
