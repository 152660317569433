import { ProductListingActionStatus } from "@marketplace/marketplace-product-core/enums/ProductListingActionStatus.enums";
import { BaseDTO } from "@shared/dto/BaseDTO";
import { BuyerContainerDto } from "@shared/dto/BuyerContainerDto";
import { NegotiationDTO } from "@shared/dto/NegotiationDTO";
import { ReDeliveryLocationPriceDTO } from "@shared/dto/ReDeliveryLocationPriceDTO";

export class ProductListingDTO extends BaseDTO {
  cartIdentifier;

  followStatus: ProductListingActionStatus;

  isAddedForCompare = false;

  isAddedForCart = false;

  viewCount = 0;

  container: BuyerContainerDto;

  negotiable: boolean = false;

  negotiationDTO: NegotiationDTO;

  buyback: boolean = false;
  buybackLocations: ReDeliveryLocationPriceDTO[] = [];
  //   currencyBlock: boolean;
}
