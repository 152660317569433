import { Component, Injector } from '@angular/core';
import { NotificationWidgetListingHeaderComponent } from '@shared/notification-widget/notification-widget-listing/notification-widget-listing-header/notification-widget-listing-header.component';

@Component({
  selector: 'shared-notification-widget-listing-small-header',
  templateUrl: './notification-widget-listing-small-header.component.html',
  styleUrls: ['./notification-widget-listing-small-header.component.scss']
})
export class NotificationWidgetListingSmallHeaderComponent extends NotificationWidgetListingHeaderComponent {

  constructor(
    protected injector: Injector,
  ) {
    super(injector);
  }

}
