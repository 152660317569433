import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService, private router: Router) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    if (this.authService.isUserLoggedIn()) {
      const loggedInUser = this.authService.getLoggedInUser();
      const roles = loggedInUser && loggedInUser.roles;
      if (roles) {
        return true;
      }
    }
    this.authService.logoutUser();
    this.authService.setRedirectUrl(url);
    this.router.navigate([this.authService.getLoginUrl()], { queryParams: { returnUrl: state.url } });
    return false;
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}
