<div class="text-center font20" style="color: #162e4d">
  <!-- <div class="imgOverFlow"><img src="{{assetPath}}/images/third.jpg" title="Used containers prices" alt="Used shipping containers prices in USA"></div> -->
  <div class="mt-5 pt-2 animation-scaling">
    <img
      src="{{ assetPath }}/images/svg/subscription_success.svg"
      alt="Welcome"
    />
  </div>
  <div class="animation-upDown">
    <div class="font36 marginT40 bold">Welcome aboard!</div>
    <div class="marginT20">
      Your subscription to receive the latest news and stock updates <br />
      from Boxxport.com has been <b style="color: #16b3af"> confirmed </b>.
      <br />
      <br />
      <br />
      <i style="color: #526783"
        >Stay informed and ahead of the game with our newsletters.</i
      >
    </div>
  </div>
  <div class="animation-upDown2">
    <div class="marginT15">You can also find us</div>
    <div class="marginT20">
      <a href="https://linkedin.com/company/boxxport" target="_blank"
        ><img src="{{ assetPath }}/images/svg/icn-in.svg" alt="Linked IN"
      /></a>
      <a href="https://instagram.com/boxxport" target="_blank" class="mx-3"
        ><img src="{{ assetPath }}/images/svg/icn-insta.svg" alt="Instagram"
      /></a>
      <a href="https://facebook.com/boxxport.gmbh" target="_blank"
        ><img src="{{ assetPath }}/images/svg/icn-fb.svg" alt="Facebook"
      /></a>
    </div>
  </div>
</div>
