import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@shared/auth/auth.service';
import { PermissionsService } from '@shared/services/permissions.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

export const ORG_DATA = {
  currentOrg: undefined
};

@Injectable({
  providedIn: 'root'
})
export class AuthBuyGuardService implements CanActivate, CanActivateChild {

  constructor(
    private permissionsService: PermissionsService,
    private authService: AuthService, private router: Router) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isUserLoggedIn() &&
      this.permissionsService.permission()
        .pipe(
          take(1),
          map(data => data.result),
          map(data => data && data.buyEnabled),
          map(isAuthenticated => {
            if (!isAuthenticated) {
              this.router.navigate(['/pending/buyer']);
              return false;
            }
            return true;
          })
        );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
