import { Component, Injector } from '@angular/core';
import { AbstractMarketplaceViewComponent } from '../abstract/marketplace-view-abstract.component';
import { CartEvent, CartService } from '../service/cart.service';

@Component({
  selector: 'marketplace-cart-icon',
  templateUrl: './marketplace-cart-icon.component.html',
  styleUrls: ['./marketplace-cart-icon.component.scss']
})
export class MarketplaceCartIconComponent extends AbstractMarketplaceViewComponent {

  count = 0;

  constructor(
    injector: Injector,
    private cartService: CartService,
  ) {
    super(injector);
  }

  renderView() {
    this.cartService.init();
    this.subscriptions.push(
      this.cartService.updateCartEvent
        .subscribe((cartEvent: CartEvent) => {
          this.count = this.cartService.count();
        })
    );
  }

}
