import { BaseDTO } from '@shared/dto/BaseDTO';


export class OrganizationConfigDTO extends BaseDTO {

    organizationIdentifier: string;

    configKey: string;

    value: string;

    remarks:string;
    dataType:string;
}
