import { DOCUMENT } from "@angular/common";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AuthService } from "@shared/auth/auth.service";
import { Utils } from "@shared/constant/shared.utils";
import { Observable } from "rxjs";
import { ConstantService } from "@shared/constant/shared.constant";

@Injectable({ providedIn: "root" })
export class JwtInterceptor implements HttpInterceptor {
  private apiDomain: string;
  private apiDomainPrefix: string;
  private protocol: string;

  constructor(
    private authService: AuthService,
    @Inject(DOCUMENT) document: Document
  ) {
    const apiDomainArr = document.location.hostname.split(".");
    const apiDomain =
      apiDomainArr[apiDomainArr.length - 2] +
      "." +
      apiDomainArr[apiDomainArr.length - 1];
    this.apiDomain = apiDomain;
    this.protocol = document.location.protocol;
    this.apiDomainPrefix = ConstantService.get("apiDomainPrefix");
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      ConstantService.get("PERSONIO_JOB_XML_URL") === request.url ||
      request.url.startsWith(ConstantService.get("WORDPRESS_DATA_URL"))
    ) {
      return next.handle(request);
    }
    const xCorrelationIdKey =
      this.authService.getStorageName() + "xCorrelationId";
    let xCorrelationId = localStorage.getItem(xCorrelationIdKey);
    if (Utils.isNull(xCorrelationId)) {
      xCorrelationId = Utils.uuid();
      localStorage.setItem(xCorrelationIdKey, xCorrelationId);
    }
    const headers = {
      "x-correlation-id": xCorrelationId,
      "Accept-Language": "en",
    };
    if (request.url.indexOf("/media/") === -1) {
      const contentType = request.headers.get("Content-Type");
      headers["Content-Type"] = contentType
        ? contentType
        : "application/json; charset=utf-8";
    }
    // add authorization header with jwt accessToken if available
    const currentUser = JSON.parse(
      localStorage.getItem(this.authService.getUserStorageName())
    );
    if (currentUser && currentUser.accessToken) {
      headers["Authorization"] = `Bearer ${currentUser.accessToken}`;
    }
    let url = request.url;
    if (url && url.startsWith("/api/")) {
      headers["isRestApi"] = "true";
      if (this.apiDomainPrefix) {
        url = `${this.protocol}//${this.apiDomainPrefix}.${
          this.apiDomain
        }${url.substring(4)}`;
      }
    }
    request = request.clone({
      setHeaders: headers,
      withCredentials: false,
      url: url,
    });
    // tslint:disable-next-line:no-console
    console.debug(request);
    return next.handle(request);
  }
}
