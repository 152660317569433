<button
  type="button"
  class="close hideData closeButton"
  (click)="close()"
  aria-label="Close"
>
  <span aria-hidden="true">&times;</span>
</button>
<div class="close-me" (click)="close()">
  <img alt="" src="{{ assetPath }}/images/close.png" />
</div>
<video
  class="videoPlayer"
  poster="{{ url }}.jpg?_q={{ time }}"
  [attr.autoplay]="autoplay ? '' : null"
  [attr.controls]="controls ? '' : null"
>
  <source src="{{ url }}.mp4?_q={{ time }}" type="video/mp4" />
  Your browser does not support the video tag.
</video>
