import { PlatformLocation } from '@angular/common';
import { Injector, Directive } from '@angular/core';
import { AbstractBaseBlockUIComponent } from '@shared/abstracts/abstract-base-blockui.component';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Directive()
export abstract class AbstractModalComponent extends AbstractBaseBlockUIComponent {

    modalErrorMsg: string;

    callbackFunc: (bsModalRef: BsModalRef, object: any) => void;

    constructor(
        public bsModalRef: BsModalRef,
        protected injector: Injector,
    ) {
        super(injector);
        const location = injector.get(PlatformLocation);
        location.onPopState(() => {
            if (this.bsModalRef) {
                this.bsModalRef.hide();
            }
        });
    }

    OnInit() {
        super.OnInit();
        this.OnModalInit();

    }

    protected OnModalInit() { }

    close() {
        this.bsModalRef.hide();
    }

    save(object = {}) {
        if (this.callbackFunc) {
            const blockUIuuid = this.blockUIuuid;
            object['blockUIuuid'] = blockUIuuid;
            this.callbackFunc(this.bsModalRef, object);
        }
    }
}
