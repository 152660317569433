import { AddContainerStep1DTO } from './AddContainerStep1DTO';
import { BaseDTO } from './BaseDTO';
import { ContainerDto } from './ContainerDto';


export abstract class AbstractContainerProductDTO extends BaseDTO {

    static formatDate(date: Date) {
        return date;
    }

    private static inventoryLocation(step: AddContainerStep1DTO | ContainerDto) {
        let inventoryLocation;
        if (step) {
            inventoryLocation = step['inventoryLocation'];
            if (!inventoryLocation) {
                const inventoryLocationList = step['inventoryLocationList'];
                if (inventoryLocationList) {
                    inventoryLocation = inventoryLocationList[0];
                }
            }
        }
        return inventoryLocation;
    }

    static inventoryLocationName(step: AddContainerStep1DTO | ContainerDto) {
        let inventoryLocation;
        if (step) {
            inventoryLocation = step['inventoryLocation'];
            if (!inventoryLocation) {
                const inventoryLocationList = step['inventoryLocationList'];
                if (inventoryLocationList) {
                    inventoryLocation = inventoryLocationList[0];
                }
            }
        }
        return inventoryLocation.name;
    }

    static inventoryDepotCode(step: AddContainerStep1DTO | ContainerDto) {
        let inventoryLocation;
        if (step) {
            inventoryLocation = step['inventoryLocation'];
            if (!inventoryLocation) {
                const inventoryLocationList = step['inventoryLocationList'];
                if (inventoryLocationList) {
                    inventoryLocation = inventoryLocationList[0];
                }
            }
        }
        return inventoryLocation.depotCode;
    }

    private static inventoryLocationAddress(step: AddContainerStep1DTO | ContainerDto) {
        let inventoryAddress;
        if (step) {
            let inventoryLocation = step['inventoryLocation'];
            if (!inventoryLocation) {
                const inventoryLocationList = step['inventoryLocationList'];
                if (inventoryLocationList) {
                    inventoryLocation = inventoryLocationList[0];
                }
            }
            if (inventoryLocation) {
                inventoryAddress = inventoryLocation.inventoryAddress;
            }
        }
        return inventoryAddress;
    }

    static getDepoLocation(step: AddContainerStep1DTO | ContainerDto) {
        const inventoryAddress = AbstractContainerProductDTO.inventoryLocationAddress(step);
        if (inventoryAddress) {
            return inventoryAddress.addressLine1;
        }
        return '';
    }

    static getCity(step: AddContainerStep1DTO | ContainerDto) {
        const inventoryAddress = AbstractContainerProductDTO.inventoryLocationAddress(step);
        if (inventoryAddress) {
            return inventoryAddress.city;
        }
        return '';
    }

    static getCountry(step: AddContainerStep1DTO | ContainerDto) {
        const inventoryAddress = AbstractContainerProductDTO.inventoryLocationAddress(step);
        if (inventoryAddress) {
            return inventoryAddress.country;
        }
        return '';
    }
}
