import {
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AbstractBaseStaticComponent } from "@marketplace/static/abstract/abstract-base-static.component";
import { MarketplaceStocksService } from "./Services/marketplace-stocks.service";
import * as Odometer from "projects/marketplace/src/assets/scripts/odometer/odometer.js";
@Component({
  selector: "marketplace-stocks",
  templateUrl: "./marketplace-stocks.component.html",
  styleUrls: ["./marketplace-stocks.component.scss"],
})
export class MarketplaceStocksComponent
  extends AbstractBaseStaticComponent
  implements OnInit
{
  constructor(
    protected injector: Injector,
    private marketplaceStocksService: MarketplaceStocksService
  ) {
    super(injector);
  }
  @ViewChild("avalUnits") avalUnits: ElementRef;
  @ViewChild("totalStock") totalStock: ElementRef;
  stocks: any;

  // stocks = { totalStock: 0, stockValue: 0 };

  ngOnInit(): void {
    // let el = document.querySelector(".odometer");
    // let od = new Odometer({
    //   el: el,
    //   value: 0,
    //   format: ",ddd",
    // });
    this.marketplaceStocksService.getAvailableStocks().subscribe((res) => {
      this.stocks = res.result;
      this.checkLoadedOdometerJs();
      // console.log(Odometer, el);
      // od.update(this.stocks.totalStock);
      // document.getElementsByClassName('odometer').innerHTML=
    });
  }
  checkLoadedOdometerJs() {
    setTimeout(() => {
      console.log(Odometer);
      if (!Odometer) {
        this.checkLoadedOdometerJs();
      } else {
        this.triggerOdometerUpdate(this.avalUnits, this.stocks.totalStock);
        this.triggerOdometerUpdate(this.totalStock, this.stocks.stockValue);
      }
    }, 500);
  }
  triggerOdometerUpdate(el: ElementRef, finalValue: number) {
    console.log(el);
    let od = new Odometer({
      el: el.nativeElement,
      format: ",ddd",
    });
    od.update(finalValue);
  }
}
