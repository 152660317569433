import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Utils } from "@shared/constant/shared.utils";
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class UploadFileService {
  // API_URL = '/api/media/uploadMultipleFiles';
  API_URL = "/api/media/uploadFile";
  // API_URL = '/api/media/uploadFile';

  constructor(private http: HttpClient) {}

  pushFileToStorage(
    files: File[],
    documentUploadPathType: string
  ): Observable<HttpEvent<{}>> {
    const uuid = Utils.uuid();
    return this.pushFileToStorageWithId(files, uuid, documentUploadPathType);
  }

  pushFileToStorageWithId(
    files: File[],
    identifier: string | number,
    documentUploadPathType: string,
    needsAuthentication: boolean = false
  ): Observable<HttpEvent<{}>> {
    const formdata: FormData = this.getFormData(files, "files");
    formdata.append("modelIdentifier", identifier + "");
    formdata.append("documentUploadPathType", documentUploadPathType);
    const req = new HttpRequest(
      "POST",
      this.API_URL +
        (needsAuthentication ? "/staticPage/z$C&F)J@NcRfUjXn" : ""),
      formdata,
      {
        reportProgress: true,
        responseType: "json",
      }
    );
    return this.http.request(req);
  }
  getFormData(files: File[], name: string): FormData {
    const formdata: FormData = new FormData();
    console.log(files);
    files.forEach((file) => formdata.append(name, file));
    return formdata;
  }

  fileDownloadPost(url: string, payload = {}, headers = {}) {
    return this.http.post(url, payload, {
      responseType: "blob",
      headers,
      observe: "response",
    });
  }

  fileDownloadGet(url: string, headers = {}) {
    return this.http.get(url, {
      responseType: "blob",
      headers,
      observe: "response",
    });
  }

  getFiles(): Observable<any> {
    return this.http.get("/getallfiles");
  }
}
