import { Router } from "@angular/router";
import { AuthService } from "@shared/auth/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "@shared/_services/authentication.service";
import { finalize, Subscription } from "rxjs";
import { AbstractBaseComponent } from "./abstract-base.component";
import { Directive } from "@angular/core";
import { PushNotificationUtils } from "@marketplace/utils/PushNotification.utils";

@Directive()
export abstract class AbstractHeaderComponent extends AbstractBaseComponent {
  userName: string;

  private sub: Subscription;

  constructor(
    protected router: Router,
    protected authService: AuthService,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private pushNotificationUtils?: PushNotificationUtils
  ) {
    super();
  }

  OnInit() {
    this.translate.addLangs([
      "en",
      "fr",
      "ur",
      "es",
      "it",
      "fa",
      "de",
      "zh-CHS",
    ]);
    this.translate.setDefaultLang("en");
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(
      browserLang.match(/en|fr|ur|es|it|fa|de|zh-CHS/) ? browserLang : "en"
    );
    this.handleLoginEvent(this.authService.isUserLoggedIn());
    this.sub = this.authService.getLoggedIn.subscribe((data) => {
      this.handleLoginEvent(data);
    });

    this.handlerRouteChangeEvent(undefined);
    this.router.events.subscribe((res) => {
      this.handlerRouteChangeEvent(res);
    });
  }

  protected handleLoginEvent(isLogin: boolean) {
    this.setUserName();
  }

  protected handlerRouteChangeEvent(res) {}

  private setUserName() {
    if (this.authService.isUserLoggedIn()) {
      const loggedInUser = this.authService.getLoggedInUser();
      this.userName = loggedInUser.firstName || loggedInUser.email;
    } else {
      this.userName = undefined;
    }
  }

  onLoggedout() {
    // const url = this.router.url;
    const url = "";
    const logout = (sub?: any) => {
      this.authenticationService
        .logout(sub)
        .pipe(
          finalize(() => {
            this.authService.logoutUser();
            this.authService.setRedirectUrl(url);
            this.navigateOnLogout();
          })
        )
        .subscribe((data) => {
          if (!data.error) {
            this.pushNotificationUtils?.removeServiceWorker().then((res) => {});
          }
        });
    };
    if (this.pushNotificationUtils) {
      this.pushNotificationUtils.getPushSubscription().then((res) => {
        let sub = JSON.parse(JSON.stringify(res));
        if (sub) {
          delete sub?.expirationTime;
        }
        logout(sub);
      });
    } else {
      logout();
    }
  }
  protected navigateOnLogout() {
    this.router.navigate([this.authService.getLoginUrl()]);
  }
  changeLang(language: string) {
    this.translate.use(language);
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
