import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponseDTO, ContainerDto } from '@shared/dto';
import { forkJoin } from 'rxjs';
import { AbstractDashboardService } from '../abstract/abstract-dashboard.service';
import { NegotiationDTOWrapper } from '@shared/dto/NegotiationDTOWrapper';

@Injectable({ providedIn: 'root' })
export class SellerDashboardService extends AbstractDashboardService {

    constructor(protected httpClient: HttpClient) { super(httpClient); }

    public topViewedListings(userType: string = 'seller', orgIdentifiers: string[] | string = '-1') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'topViewedListings'));
    }

    public listing(userType: string = 'seller', orgIdentifiers: string[] | string = '-1', selectedTab = 'live') {
        return this.httpClient.get<BaseResponseDTO<ContainerDto[]>>(this.url(userType, orgIdentifiers, `listing/${selectedTab}`));
    }

    public followersCount(userType: string = 'seller', orgIdentifiers: string[] | string = '-1') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'followersCount'));
    }

    public soldUnitCount(userType: string = 'seller', orgIdentifiers: string[] | string = '-1') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'soldUnitCount'));
    }

    public viewCount(userType: string = 'seller', orgIdentifiers: string[] | string = '-1') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'viewCount'));
    }

    public containerCount(userType: string = 'seller', orgIdentifiers: string[] | string = '-1', tab = 'ACTVE') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, `listing/count/${tab}`));
    }

    public rating(userType: string = 'seller', orgIdentifiers: string[] | string = '-1') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'rating'));
    }

    public amountInPipeLineSeller(userType: string, orgIdentifiers: string[] | string = '\-1') {
        const active = this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'listing/price/ACTIVE'));
        const inactive = this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'listing/price/INACTIVE'));
        const soldUnitPrice = this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'soldUnitPrice'));
        return forkJoin([active, inactive, soldUnitPrice]);
    }

    public negotiationCount(userType: string, orgIdentifiers: string[] | string = '-1') {
        return this.httpClient.get<BaseResponseDTO<number>>(this.url(userType, orgIdentifiers, 'todo/negotiations'));
    }
    public negotiations(userType: string, orgIdentifiers: string[] | string = '-1') {
        const url = this.url(userType, orgIdentifiers, 'negotiations');
        return this.httpClient.get<BaseResponseDTO<NegotiationDTOWrapper[]>>(url, {});
    }
}
