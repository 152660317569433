import { Component, Injector } from '@angular/core';
import { AbstractModalComponent } from '@marketplace/abstract/abstract-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { VERSION } from '@version/version';

const VIDEO_URL = './staticVideo';

const TYPE_MAP = {
  'BUYING': VIDEO_URL + '/buyingon-boxxport',
  'SELLING_BLUK': VIDEO_URL + '/bulklising',
  'SELLING_INDIVIDUAL': VIDEO_URL + '/sellingon-boxxport',
};

@Component({
  selector: 'marketplace-video-player-modal',
  templateUrl: './video-player-modal.component.html',
  styleUrls: ['./video-player-modal.component.scss']
})
export class VideoPlayerModalComponent extends AbstractModalComponent {

  videoUrl = VIDEO_URL;

  type: string;

  url: string;

  time = VERSION.timestamp;

  controls = true;

  autoplay = true;

  constructor(
    protected injector: Injector,
    public bsModalRef: BsModalRef,
  ) { super(bsModalRef, injector); }

  OnModalInit() {
    if (!this.url) {
      this.url = TYPE_MAP[this.type];
    }
    if (!this.url) {
      throw new Error('provide config');
    }
  }

}
