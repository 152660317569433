import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractBaseService } from '@shared/abstracts/abstract-base-service';
import { Utils } from '@shared/constant/shared.utils';
import { BaseResponseDTO } from '@shared/dto';

export enum AnalyticType {

  SELLER = 'SELLER',

  PRODUCT = 'PRODUCT',
}

@Injectable({ providedIn: 'root' })
export class MarkerplaceProductAnalyticService extends AbstractBaseService {

  constructor(private http: HttpClient) { super(); }

  private url(type: AnalyticType, identifier) {
    const url = ['p', 'analytic', 'view'];
    url.push(type);
    let identifiers = identifier;
    if (Utils.isArray(identifier)) {
      identifiers = identifier.join(',');
    }
    url.push(identifiers);
    const pathUrl = url.filter(e => Utils.isNotNull(e)).join('/');
    return `${this.apiBaseUrl}${pathUrl}`;
  }

  public count(type: AnalyticType, identifier) {
    return this.http.get<BaseResponseDTO<void>>(this.url(type, identifier));
  }

}
