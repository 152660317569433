import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PreventBackService {

    private isBackClicked: boolean;

    public getBackClicked(): boolean {
        return this.isBackClicked;
    }

    public setBackClicked(isBackClicked: boolean): boolean {
        return this.isBackClicked = isBackClicked;
    }

}
