import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationWidgetListingHeaderComponent } from './notification-widget-listing/notification-widget-listing-header/notification-widget-listing-header.component';
import { NotificationWidgetListingItemComponent } from './notification-widget-listing/notification-widget-listing-item/notification-widget-listing-item.component';
import { NotificationWidgetListingComponent } from './notification-widget-listing/notification-widget-listing.component';
import { NotificationWidgetComponent } from './notification-widget.component';
import { NotificationWidgetService } from './service/notification-widget.service';
import { NotificationWidgetListingItemActionComponent } from './notification-widget-listing/notification-widget-listing-item/notification-widget-listing-item-action/notification-widget-listing-item-action.component';
import { NotificationWidgetCountComponent } from './notification-widget-count/notification-widget-count.component';
import { NotificationWidgetListingSmallComponent } from './notification-widget-listing-small/notification-widget-listing-small.component';
import { NotificationWidgetListingSmallHeaderComponent } from './notification-widget-listing-small/notification-widget-listing-small-header/notification-widget-listing-small-header.component';
import { NotificationWidgetListingSmallItemComponent } from './notification-widget-listing-small/notification-widget-listing-small-item/notification-widget-listing-small-item.component';
import { NotificationWidgetListingSmallItemActionComponent } from './notification-widget-listing-small/notification-widget-listing-small-item/notification-widget-listing-small-item-action/notification-widget-listing-small-item-action.component';
import { SharedModule } from '@shared/shared.module';
import { NotificationWidgetListingOrderItemComponent } from './item/notification-widget-listing-order-item/notification-widget-listing-order-item.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { NotificationWidgetNoNotificationComponent } from './notification-widget-no-notification/notification-widget-no-notification.component';
import { NegotiationNotificationModalComponent } from './notification-widget-count/negotiation-notification-modal/negotiation-notification-modal.component';

@NgModule({
  declarations: [NotificationWidgetComponent, NotificationWidgetListingComponent, NotificationWidgetListingHeaderComponent, NotificationWidgetListingItemComponent, NotificationWidgetListingItemActionComponent, NotificationWidgetCountComponent, NotificationWidgetListingSmallComponent, NotificationWidgetListingSmallHeaderComponent, NotificationWidgetListingSmallItemComponent, NotificationWidgetListingSmallItemActionComponent, NotificationWidgetListingOrderItemComponent, NotificationWidgetNoNotificationComponent, NegotiationNotificationModalComponent],
  imports: [
    CommonModule,
    ClickOutsideModule,
    SharedModule,
  ],
  providers: [NotificationWidgetService],
  exports: [NotificationWidgetComponent, NotificationWidgetCountComponent]
})
export class NotificationWidgetModule { }
