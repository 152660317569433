<div class="modal-header" *ngIf="isServerDown">
  <h4 class="modal-title pull-left">
    Error
  </h4>
  <button type="button" class="close" (click)="close();" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *blockUI="blockUIuuid">
  <p *ngIf="isServerDown">
    You have hit server downtime. Usually we are back within minute. You may try Ctrl+Shift+R on keyboard. If issue persists then please report it as issue.
  </p>
  <div *ngIf="!isServerDown">
    <div class="bold font24 marginB20">Error has occurred</div>
      <p>Refreshing the page and try again. </p>
      <p>If the error still persist please contact us with reference : ‘{{id}}’</p>
  </div>
</div>
<div class="modal-footer" *ngIf="isServerDown">
  <a href="javascript:void(0);" (click)="save();" class="errorBtn btn btn-primary btn-block">Close</a>
</div>
