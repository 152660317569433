<main class="main">
  <div class="container mt-5">
    <div class="row">
      <div class="col-sm-4 order-sm-2 text-center text-sm-left mb-5">
        <img src="{{ assetPath }}/images/svg/container-3box.svg" alt="" />
      </div>
      <div class="col-sm-7">
        <h1 class="marginB10">
          Oops! You do not have permission to view this page. <br />Please
          contact support if this is not supposed to happen.
        </h1>
        <p class="bold">Error code : 403</p>
        <p>Here are some helpful links instead:</p>
        <div class="marginT5">
          <a routerLink="/buyer/welcome" class="bold"
            >Find the best container price</a
          >
        </div>
        <div class="marginT5">
          <a routerLink="/help" class="bold">Help</a>
        </div>
        <div class="marginT5">
          <a routerLink="/buy-shipping-containers" class="bold"
            >Buying on BOXXPORT</a
          >
        </div>
        <div class="marginT5">
          <a routerLink="/sell-shipping-containers" class="bold"
            >Selling on BOXXPORT</a
          >
        </div>
      </div>
    </div>
  </div>
</main>
