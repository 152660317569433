import { Injectable } from '@angular/core';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';

@Injectable({ providedIn: 'root' })
export class ScrollService {

    constructor(
        private _scrollToService: ScrollToService,
    ) {
    }

    public scrollTaget(target: string, timeout = 1) {
        setTimeout(() => {
            const config: ScrollToConfigOptions = {
                easing: 'easeOutElastic',
                target: target
            };
            this._scrollToService.scrollTo(config)
                .subscribe(
                    value => { console.log(value) },
                    err => console.error(err) // Error is caught and logged instead of thrown
                );
        }, timeout);
    }
}
