import { Injectable } from "@angular/core";
import { NgxZendeskWebwidgetConfig } from "ngx-zendesk-webwidget";
import { ConstantService } from "@shared/constant/shared.constant";
@Injectable({ providedIn: "root" })
export class ZendeskWidgetConfig extends NgxZendeskWebwidgetConfig {
  lazyLoad = false;
  accountUrl = ConstantService.get("ZendeskURL");

  callback(zE) {
    zE("webWidget", "setLocale", "en");
    zE("webWidget", "hide");
  }
}
