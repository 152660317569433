import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { MarketplaceModule } from "@marketplace/marketplace.module";
import { environment } from "@marketplaceEnv/environment";
import { Directive, ElementRef } from "@angular/core";

// tslint:disable-next-line:no-console
const debug = console.debug;
// tslint:disable-next-line:no-console
console.debug = function () {};
if (environment.production) {
  enableProdMode();
  /*
  const log = console.log;
  const info = console.info;
  const error = console.error;
  const warn =  console.warn;
  */

  console.log = function () {};
  console.warn = function () {};
  // tslint:disable-next-line:no-console
  console.info = function () {};
  // console.error = function () { };
}
let isAppLoad = false;
const loadApp = () => {
  if (isAppLoad) {
    return;
  }
  isAppLoad = true;
  platformBrowserDynamic()
    .bootstrapModule(MarketplaceModule)
    .catch((err) => console.log(err));
};

const loadLocale = function () {
  const locale = sessionStorage.getItem("locale");
  if (locale) {
    loadApp();
    return;
  }
  const xhttp = new XMLHttpRequest();
  xhttp.onerror = loadApp;
  xhttp.onreadystatechange = function () {
    // https://stackoverflow.com/questions/30522565/what-is-meaning-of-xhr-readystate-4
    if (this.readyState === 4) {
      if (this.status === 200) {
        if (this.responseText) {
          try {
            const json = JSON.parse(this.responseText);
            if (json && json.msg) {
              sessionStorage.setItem("locale", json.msg);
            }
          } catch (e) {}
        }
      }
      loadApp();
    }
  };
  // const apiDomainArr = document.location.hostname.split(".");
  // const apiDomain =
  //   apiDomainArr[apiDomainArr.length - 2] +
  //   "." +
  //   apiDomainArr[apiDomainArr.length - 1];
  const apiDomain = document.location.hostname.replace("www", "");
  const protocol = document.location.protocol;
  const port = document.location.port;
  const apiDomainPrefix = window["configuration"].apiDomainPrefix;

  const apiUrl = `${protocol}//${apiDomainPrefix ?? ""}${apiDomain}:${
    port ?? ""
  }/p/locale/?`;
  xhttp.open("GET", apiUrl + Math.random(), true);
  xhttp.send();
};
loadLocale();

@Directive({ selector: "img" })
export class LazyImgDirective {
  constructor({ nativeElement }: ElementRef<HTMLImageElement>) {
    const supports = "loading" in HTMLImageElement.prototype;

    if (supports) {
      nativeElement.setAttribute("loading", "lazy");
    }
  }
}
