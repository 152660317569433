import {
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Directive,
} from "@angular/core";
import { Router } from "@angular/router";
import { AbstractHeaderComponent } from "@shared/abstracts/abstract-header.component";
import { AuthService } from "@shared/auth/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "@shared/_services/authentication.service";
import { HeaderType } from "@marketplace/enums/header-types.enums";
import { PushNotificationUtils } from "@marketplace/utils/PushNotification.utils";

@Directive()
export class AbstractMarkeplaceHeaderComponent
  extends AbstractHeaderComponent
  implements OnInit, OnChanges
{
  isSeller;
  isLogin = false;

  @Input() flow: HeaderType;

  constructor(
    router: Router,
    authService: AuthService,
    translate: TranslateService,
    authenticationService: AuthenticationService,
    pushNotificationUtils?: PushNotificationUtils
  ) {
    super(
      router,
      authService,
      translate,
      authenticationService,
      pushNotificationUtils
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.onInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.flow && !changes.flow.firstChange) {
      this.onInit();
    }
  }

  protected handleLoginEvent(isLogin: boolean) {
    super.handleLoginEvent(isLogin);
    this.isLogin = isLogin;
  }

  onInit() {
    this.isSeller =
      this.flow === HeaderType.HEADER_SELLER ||
      this.flow === HeaderType.HEADER_SELLER_PENDING;
  }
}
