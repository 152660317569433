import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CartDTO } from "@marketplace/dto/CartDTO";
import { CartSummaryDTO } from "@marketplace/dto/CartSummaryDTO";
import { AbstractBaseService } from "@shared/abstracts/abstract-base-service";
import { BaseResponseDTO } from "@shared/dto";

@Injectable({
  providedIn: "root",
})
export class CartApiService extends AbstractBaseService {
  private apiUrl: string;

  constructor(private http: HttpClient) {
    super();
    this.apiUrl = this.apiBaseUrl + "cart/";
  }

  cart() {
    return this.http.get<BaseResponseDTO<CartDTO[]>>(this.apiUrl);
  }

  clearCart() {
    return this.http.delete<BaseResponseDTO<any>>(this.apiUrl);
  }

  getCartSummary() {
    return this.http.get<BaseResponseDTO<CartSummaryDTO>>(
      `${this.apiUrl}summary`
    );
  }

  getCartSummaryByIdentifier(cartIdentifier) {
    return this.http.get<BaseResponseDTO<CartSummaryDTO>>(
      `${this.apiUrl}summary/${cartIdentifier}`
    );
  }

  addProductToCart(
    productIdentifier: string,
    quantity = 1,
    fixed = false,
    paramMap = {},
    includeHeader = true,
    fromNegotiationFlow,
    redeliveryLocations
  ) {
    let headers = {};
    // if (includeHeader) {
    //   headers = new HttpHeaders({ 'api-message-code': 'add.to.cart' });
    // }
    const params = {
      fromNegotiationFlow: fromNegotiationFlow,
      buyerTaxRequestDTO: paramMap,
      reDeliveryLocations: redeliveryLocations
        ? JSON.stringify(redeliveryLocations)
        : undefined,
    };
    return this.http.post<BaseResponseDTO<string>>(
      `${this.apiUrl}${productIdentifier}/quantity/${quantity}/${fixed}`,
      params,
      { headers: headers }
    );
  }

  addProductToCartByCartIdentifier(cartIdentifier: string, quantity = 1) {
    return this.http.put<BaseResponseDTO<string>>(
      `${this.apiUrl}${cartIdentifier}/quantity/${quantity}`,
      undefined
    );
  }

  removeFromCartByProductDelete(productIdentifier: string) {
    const headers = new HttpHeaders({ "api-message-code": "remove.from.cart" });
    const url = `${this.apiUrl}product/${productIdentifier}`;
    return this.http.delete<BaseResponseDTO<string>>(url, { headers: headers });
  }

  removeFromCart(cartIdentifier: string) {
    const headers = new HttpHeaders({ "api-message-code": "remove.from.cart" });
    const url = `${this.apiUrl}${cartIdentifier}`;
    return this.http.delete<BaseResponseDTO<string>>(url, { headers: headers });
  }
  removeLocationFromCart(cartIdentifier, locationIdentifier) {
    const url = `${this.apiUrl}${cartIdentifier}/${locationIdentifier}`;
    return this.http.delete<BaseResponseDTO<string>>(url, {});
  }
  updateToCartLocationQty(
    cartIdentifier: string,
    locationIdentifier,
    quantity = 1
  ) {
    return this.http.post<BaseResponseDTO<string>>(
      `${this.apiUrl}${cartIdentifier}/${locationIdentifier}/quantity/${quantity}`,
      undefined
    );
  }
}
