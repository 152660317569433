import { BaseDTO } from "@shared/dto/BaseDTO";
import { AddressDTO } from "@shared/dto/AddressDTO";
import { PhoneDTO } from "@shared/dto/PhoneDTO";
import { BankAccountDTO } from "./BankAccountDTO";
import { DocumentDetailDTO } from "./DocumentDetailDTO";
import { OrganizationConfigDTO } from "./OrganizationConfigDTO";

export class OrganizationDTO extends BaseDTO {
  customOrgName: string;

  companyName: string;

  buyerStatus: string;

  sellerStatus: string;

  companyType: string;

  otherCompanyType: string;

  registrationNumber: string;

  taxId: string;

  designation: string;

  companyWebsite: string;

  orgType: string;

  isTrusted: boolean;

  isVerified: boolean;

  marketPlaceCommission: number;

  orderCancellationFee: number;

  address: AddressDTO[];

  billingAddress: AddressDTO;

  phones: PhoneDTO[];

  organizationPaymentAccountDetails: BankAccountDTO[];

  defaultOrganizationPaymentAccountDetails: BankAccountDTO;

  identifier: string;

  defaultOrganization: boolean;

  isEditable: boolean;

  adminOrganization: boolean;

  submittedDate: Date;

  negotiationDuration: number;

  canNegotiate: boolean;

  defaultMarketPlaceCommission: number;
  userCreditFinalAmount: number;

  verificationScheduled: boolean;
  verificationCreatedAt: Date;
  additionalInfo: string;
  organizationDocuments: DocumentDetailDTO[] = [];
  organizationConfigs: OrganizationConfigDTO[] = [];
  sprinqueOrgConfig: {
    is_sprinque_enabled: boolean;
    sprinqueBuyerID?: string;
    sprinqueBuyerIDEditMode: boolean;
  };
  boxxportChoice: boolean;
  org_wallet_status: orgWalletStatus;

  public static getDefaultAddress(organization: OrganizationDTO): AddressDTO {
    return organization.address && organization.address[0];
  }

  public static formatAddressWoPostalCode(address: AddressDTO) {
    return AddressDTO.toAddressStringWoPostalCode(address);
  }
}
export type orgWalletStatus = "PENDING" | "APPROVED" | "REJECTED" | undefined |"DISABLED";
