<div
  class="content h-100 w-100 p-0 d-flex flex-column align-items-center justify-content-between"
>
  <h1
    class="font50 font-md-32 text-white text-center m-0 pt-4 display-4 fw-350"
  >
    <span class="bold font-freightSans-pro fw-700">Buy shipping containers</span
    ><br />
    {{ landingpage }} at best prices
  </h1>

  <marketplace-stocks class="w-100"></marketplace-stocks>

  <form
    *ngIf="containerDetailsBuyerData"
    class="text-white w-100"
    [formGroup]="containerDetailsBuyerData.filterForm"
  >
    <div
      class="row bg-blue-lightest-2 rounded-border m-0 py-2 py-lg-2 align-items-center"
    >
      <div class="col-lg-5 col-md-4 col-sm-6 mb-2 mb-md-0">
        <div class="form-group m-0" *ngIf="shouldAddressAutoCompleteWork()">
          <input
            #mapLocation
            type="text"
            class="form-control location-input location py-2"
            placeholder="Location*"
            formControlName="location"
          />
          <!-- <input
            #mapLocation4
            type="text"
            class="form-control location"
            placeholder="Location*"
          /> -->
          <control-messages
            [control]="containerDetailsBuyerData.filterForm.controls.location"
          ></control-messages>
        </div>
        <div class="row" *ngIf="!shouldAddressAutoCompleteWork()">
          <div class="col-sm-6">
            <div class="form-group m-0">
              <ng-select
                [labelForId]="'box_country'"
                [searchFn]="countrySearchFN"
                [clearable]="false"
                class="form-control ngSelectClass"
                formControlName="country"
                name="box_export_country"
                autocomplete="off"
                bindLabel="value"
                bindValue="key"
                [items]="
                  containerDetailsBuyerData?.countryForSearchWrapper
                    ?.countryNames
                "
                placeholder="Country*"
                (change)="fetchAndPopulateCityData()"
              >
                <ng-template ng-header-tmp>
                  <p>Types of Containers</p>
                </ng-template>
              </ng-select>
              <control-messages
                [control]="
                  containerDetailsBuyerData.filterForm.controls.country
                "
              ></control-messages>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group m-0">
              <ng-select
                [labelForId]="'box_city'"
                [clearable]="false"
                class="form-control ngSelectClass"
                formControlName="city"
                name="box_city"
                autocomplete="off"
                bindLabel="city"
                bindValue="city"
                [items]="geoTaggedLocations"
                placeholder="City*"
              >
              </ng-select>
              <control-messages
                [control]="containerDetailsBuyerData.filterForm.controls.city"
              ></control-messages>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-2 col-md-3 col-sm-6">
        <div class="form-group">
          <div class="input-group">
            <input
              type="text"
              readonly
              class="form-control radius text-right"
              placeholder="100"
              id="radius"
              formControlName="radius"
              [outsideClick]="true"
              placement="bottom"
              container="body"
              [popover]="homeRadiusPopContent"
              [popoverContext]="popHomeRadiusPopContent"
              #popHomeRadiusPopContent="bs-popover"
            />
            <div class="input-group-append metallic-blue">Radius(km)</div>
          </div>
          <control-messages
            [control]="containerDetailsBuyerData.filterForm.controls.radius"
          ></control-messages>
        </div>
      </div> -->
      <div class="col-md-3 col-6 mb-2 mb-md-0">
        <div class="form-group m-0">
          <!-- <label for="box_type">Box type:</label> -->
          <ng-select
            [labelForId]="'box_type'"
            [clearable]="false"
            class="form-control ngSelectClass"
            formControlName="checklistType"
            name="box_in_type"
            autocomplete="off"
            bindLabel="containerTypeCodeDesc"
            bindValue="containerTypeCodeDesc"
            [items]="containerDetailsBuyerData.containerTypeData"
            placeholder="Type"
          >
          </ng-select>
          <control-messages
            [control]="
              containerDetailsBuyerData.filterForm.controls.checklistType
            "
          >
          </control-messages>
        </div>
      </div>
      <div class="col-md-3 col-6 mb-2 mb-md-0">
        <div class="form-group m-0">
          <!-- <label for="box_grade">Box grade:</label> -->

          <ng-select
            [labelForId]="'box_grade'"
            [clearable]="false"
            class="form-control ngSelectClass"
            formControlName="checklistGrade"
            name="box_in_grade"
            autocomplete="off"
            bindLabel="description"
            bindValue="description"
            [items]="containerDetailsBuyerData.containerGradeData"
            placeholder="Grade"
          >
          </ng-select>
          <control-messages
            [control]="
              containerDetailsBuyerData.filterForm.controls.checklistGrade
            "
          >
          </control-messages>
        </div>
      </div>
      <div class="col-md-2 col-lg-1 col-12">
        <button
          (click)="gotoSerach()"
          class="btn btn-pink gtm-home-container-search align-item-center py-2 w-100"
          type="button"
          aria-label="apply"
        >
          <img
            class="sear"
            src="{{ assetPath }}/images/new-svg/rightpinkarrow.svg"
            alt=""
          />
        </button>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-md-5 col-12">
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend form-check">
              <input
                type="checkbox"
                (change)="isExportChange($event)"
                formControlName="isExport"
                id="Export"
              />
              <label for="Export" class="sm">Export</label>
              <span
                class="icn-info"
                container="body"
                popover="{{
                  'Please tick Export box if Container(s) will leave the country of purchase (Export or EU intracommunity transport). Please provide destination Country to ensure the right documentation flow and TAX calculations.'
                    | translate
                }}"
                >Information</span
              >
            </div>
            <ng-select
              #exportCountry
              [searchFn]="countrySearchFN"
              [labelForId]="'box_export_country'"
              [clearable]="false"
              [ngClass]="{ 'disabled-dropdown': !isExport }"
              class="form-control ngSelectClass"
              formControlName="exportCountry"
              name="box_export_country"
              id="box_export_country"
              autocomplete="off"
              bindLabel="value"
              bindValue="key"
              [items]="
                containerDetailsBuyerData?.countryWrapperDTO?.countryNames
              "
              placeholder="Select"
            >
            </ng-select>
          </div>
          <control-messages
            [control]="
              containerDetailsBuyerData.filterForm.controls.exportCountry
            "
          >
          </control-messages>
        </div>
      </div> 
      <div class="col-lg-3 offset-lg-1 col-md-4 col-sm-6">
        <div class="form-group">
          <div class="input-group">
            <input
              NumbersOnly
              type="text"
              class="form-control text-sm"
              placeholder="Year of manufacture"
              formControlName="yearOfManufacture"
            />
            <div class="input-group-append metallic-blue">& above</div>
          </div>
          <control-messages
            [control]="
              containerDetailsBuyerData.filterForm.controls.yearOfManufacture
            "
          ></control-messages>
        </div>
      </div>-->
    </div>
  </form>
  <!-- <ng-template #homeRadiusPopContent>
    <input
      type="range"
      min="100"
      max="1000"
      value="{{ getRadiusVal() }}"
      (change)="setRadiusVal($event.target.value)"
    />
    <div>
      <span class="pull-left">100</span>
      <span class="pull-right">1000</span>
    </div>
  </ng-template> -->
</div>
