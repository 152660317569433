import { Component, Injector } from '@angular/core';
import { AbstractModalComponent } from '@marketplace/abstract/abstract-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Utils } from '@shared/constant/shared.utils';
import { stat } from 'fs';

@Component({
  selector: 'marketplace-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent extends AbstractModalComponent {

  isServerDown = false;

  errorEvent: CustomEvent;

  id = Utils.uuid();

  constructor(
    protected injector: Injector,
    public bsModalRef: BsModalRef
  ) { super(bsModalRef, injector); }

  OnModalInit() {
    super.OnModalInit();
    console.log(this.errorEvent);
    if (this.errorEvent && this.errorEvent.detail) {
      const detail = this.errorEvent.detail;
      const status = detail.status;
      this.id = `${detail.correlationId}/${detail.requestId ? detail.requestId : status}`;
      if (Utils.isNotNull(status)) {
        if ((status > 500 && status < 505) || status === 0) {
          this.isServerDown = true;
        }
      }
    }
  }

  close() {
    super.save();
  }

}
