import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Params } from '@angular/router';

const PRODUCT_LISTING_PAGINATIONEVENT: EventEmitter<any> = new EventEmitter();
@Component({
  selector: 'marketplace-product-listing-pagination',
  templateUrl: './product-listing-pagination.component.html',
  styleUrls: ['./product-listing-pagination.component.scss']
})
export class BuyerProductListingPaginationComponent implements OnInit, OnChanges {

  @Output() paginationEvent = PRODUCT_LISTING_PAGINATIONEVENT;

  @Input() totalCount = 0;

  @Input() paginationReset = false;

  @Input() defaultLength = 5;

  @Input() defaultPage = 0;

  @Input() isDisabled = false;

  @Input() options = [5, 10, 20, 50];

  pageLength = this.defaultLength;

  pageNumber = this.defaultPage;

  protected subscriptions: Subscription[] = [];

  @Output() before = new EventEmitter<any>();

  constructor(
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.pageLength = this.defaultLength;
    this.pageNumber = this.defaultPage;
    this.subscriptions.push(
      this.paginationEvent
        .subscribe((pagination: Params) => {
          this.defaultLength = pagination.pageLength;
          this.pageLength = pagination.pageLength;
          this.pageNumber = pagination.pageNumber;
        })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.totalCount && !changes.totalCount.firstChange) {
        this.pageNumber = Number(this.defaultPage);
        this.cd.detectChanges();
      }
      if (changes.isDisabled && !changes.isDisabled.firstChange) {
        this.cd.detectChanges();
      }
      if (changes.paginationReset && changes.paginationReset.currentValue) {
        this.pageNumber = 0;
        this.cd.detectChanges();
      }
    }
  }

  prevPage() {
    this.paginationReset = false;
    if (!this.isFirst()) {
      this.pageNumber--;
      this.changeEvent();
    }
  }

  nextPage() {
    this.paginationReset = false;
    if (!this.isLast()) {
      this.pageNumber++;
      this.changeEvent();
    }
  }

  isLast(): boolean {
    return this.isDisabled || this.pageLength * (this.pageNumber + 1) >= this.totalCount;
  }

  isFirst(): boolean {
    return this.isDisabled || this.pageNumber === 0;
  }

  changeLength($event) {
    this.paginationReset = false;
    const value = parseInt($event.target.value, 10);
    this.pageNumber = 0;
    this.pageLength = value;
    this.changeEvent();
  }

  private changeEvent() {
    this.before.emit({});
    const pageNumber = this.pageNumber;
    const pageLength = this.pageLength;
    this.paginationEvent.emit({
      pageNumber, pageLength
    });
  }
  getFirstDisp() {
    if (this.getLastDisp() === 0) {
      return 0;
    }
    return this.pageNumber * this.pageLength + 1;
  }
  getLastDisp() {
    let value = (this.pageNumber + 1) * this.pageLength;
    if (value > this.totalCount) {
      value = this.totalCount;
    }
    return value;
  }

}
