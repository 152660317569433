import { EventEmitter, Injectable, Output } from '@angular/core';

export interface SellerEvent {

    isSeller;

    isPending;
}

@Injectable({
    providedIn: 'root'
})
export class MarketplaceEventService {

    @Output() public sellerEvent: EventEmitter<any> = new EventEmitter();

    constructor() { }

}
