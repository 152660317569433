import { Component } from '@angular/core';
import { AbstractBaseComponent } from '@shared/abstracts/abstract-base.component';

@Component({
  selector: 'landing-page-faqs',
  templateUrl: './landing-page-faqs.component.html',
  styleUrls: ['./landing-page-faqs.component.scss']
})
export class LandingPageFaqsComponent extends AbstractBaseComponent {

  constructor() { super(); }

}
