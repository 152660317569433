import { Component, Input } from '@angular/core';
import { HeaderType } from '@marketplace/enums/header-types.enums';
import { AbstractBaseComponent } from '@shared/abstracts/abstract-base.component';
import { VERSION as PrevVersion } from '@version/prev.version';
import { VERSION } from '@version/version';
import { ConstantService } from '@shared/constant/shared.constant';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ValidationService } from '@shared/error/validation.service';
import { Utils } from '@shared/constant/shared.utils';
import { finalize } from 'rxjs/operators';
import { ExternalSubscriptionService } from '@shared/services/external-subscription.service';
import { Router } from '@angular/router';

const DEFAULT_FOOTER_CLASS = 'justify-content-end';

const DEFAULT_CSS_MAP = {
  'SELLER_SIDE_MENU': 'justify-content-center',
  'HEADER_NO': 'hideData'
};

@Component({
  selector: 'marketplace-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends AbstractBaseComponent {

  private connection;

  footerClass = DEFAULT_FOOTER_CLASS;

  effectiveType: string;

  downlinkMax: number;

  headerType: HeaderType;

  subscriptionForm: FormGroup;

  subscriptionError: Boolean = false;

  subscriptionSuccess: Boolean = false;

  @Input() set flow(headerType: HeaderType) {
    let footerClass;
    if (headerType) {
      footerClass = DEFAULT_CSS_MAP[headerType];
    }
    if (!footerClass) {
      footerClass = DEFAULT_FOOTER_CLASS;
    }
    this.footerClass = footerClass;
    this.headerType = headerType;
  }
  version: string;
  private currentVersion = VERSION;

  private prevVersion = PrevVersion;

  isDev = false;

  constructor(private fb: FormBuilder,
    private route: Router,
    private externalSubscriptionService: ExternalSubscriptionService) {
    super();
  }

  OnInit() {
    this.version = `${this.versionInfo(this.prevVersion)} => ${this.versionInfo(this.currentVersion)}`;
    this.isDev = ConstantService.get('isDev') === true;
    const connection = navigator['connection'] || navigator['mozConnection'] || navigator['webkitConnection'];
    if (connection) {
      this.updateCOnnectionInfo(connection);
      const updateConnectionStatus = () => {
        this.updateCOnnectionInfo(connection);
      };
      connection.addEventListener('change', updateConnectionStatus);
      this.connection = connection;
    }

    this.subscriptionForm = this.fb.group({
      'email': [undefined, [Validators.required, ValidationService.emailValidator].concat(ValidationService.EMAIL)]
    });
    this.subscriptionForm['formName'] = 'subscription';
  }

  subscriptionFormSubmit() {
    Utils.markFormGroupTouched(this.subscriptionForm);
    if (this.subscriptionForm.dirty && this.subscriptionForm.valid) {
      const subscriptionModel = this.subscriptionForm.getRawValue();
      this.blockUI.start();
      this.externalSubscriptionService.createContactInformation(subscriptionModel.email)
        .pipe(
          finalize(() => {
            this.blockUI.stop(); // Stop blocking
          })
        )
        .subscribe(data => {
          if (data.result) {
            this.subscriptionError = false;
            this.subscriptionSuccess = true;
          } else {
            this.subscriptionSuccess = false;
            this.subscriptionError = true;
          }
        }, error => {
          this.subscriptionSuccess = false;
          this.subscriptionError = true;
        });
    }
  }

  changeEmailID() {
    this.subscriptionSuccess = false;
    this.subscriptionError = false;
  }

  private updateCOnnectionInfo(connection) {
    this.effectiveType = connection.effectiveType;
    this.downlinkMax = connection.downlinkMax || connection.downlink;
  }

  private versionInfo(version) {
    return `${version.hash}(${version.branchName} ${this.formatDate(version.timestamp)})`;
  }

  private formatDate(timeStampValue: number) {
    return new Date(timeStampValue).toLocaleString();
  }
  protected unsubscribeAll() {
    super.unsubscribeAll();
    if (this.connection) {
      this.connection.removeEventListener('change');
    }
  }

  goToListing() {
    window.scroll(0, 0);
  }
}
