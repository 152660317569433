<div
  class="row"
  *ngIf="sectionDetail && sectionDetail.imageAlignment == 'left'"
>
  <div class="col-md-5">
    <div class="imgOverFlow">
      <img
        src="{{ sectionDetail.imageUrl }}"
        title="{{
          sectionDetail.imgTitle
            ? sectionDetail.imgTitle
            : sectionDetail.heading
        }}"
        alt="{{
          sectionDetail.imgAlt ? sectionDetail.imgAlt : sectionDetail.heading
        }}"
      />
    </div>
  </div>
  <div class="col-md-5 offset-md-1">
    <div class="articleInformation">
      <h2 class="font36">{{ sectionDetail.heading }}</h2>
      <div
        [innerHTML]="sectionDetail.description"
        class="description marginR20"
      ></div>
    </div>
  </div>
</div>
<div
  class="row"
  *ngIf="sectionDetail && sectionDetail.imageAlignment == 'right'"
>
  <div class="col-md-5 offset-md-1 order-md-1 order-2">
    <div class="articleInformation">
      <h2 class="font36">{{ sectionDetail.heading }}</h2>
      <div [innerHTML]="sectionDetail.description" class="description"></div>
    </div>
  </div>
  <div class="col-md-5 offset-md-1 order-md-2 order-1">
    <div class="imgOverFlow">
      <img
        src="{{ sectionDetail.imageUrl }}"
        title="{{
          sectionDetail.imgTitle
            ? sectionDetail.imgTitle
            : sectionDetail.heading
        }}"
        alt="{{
          sectionDetail.imgAlt ? sectionDetail.imgAlt : sectionDetail.heading
        }}"
      />
    </div>
  </div>
</div>
