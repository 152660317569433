import { Component } from '@angular/core';
import { AbstractBaseComponent } from '@shared/abstracts/abstract-base.component';
import { BlockUiDTO } from './blockui.dto';

@Component({
  selector: 'shared-block-ui',
  templateUrl: './block-ui.component.html',
  styleUrls: ['./block-ui.component.scss']
})
export class BlockUiComponent extends AbstractBaseComponent {

  smallLoader = 'smallLoader';

  message;

  constructor() {
    super();
  }

  protected OnInit(): void {
  }

  getObect(message) {
    if (message && typeof message === 'object') {
      return message;
    }
    return BlockUiDTO.of(message);
  }

  getObectType(message) {
    const blockUiDTO: BlockUiDTO = this.getObect(message);
    if (blockUiDTO.isSmall) {
      return this.smallLoader;
    }
  }

}
