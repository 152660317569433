import { NgModule } from '@angular/core';
import { ConstantService, RE_CAPTCHA_KEY } from '@shared/constant/shared.constant';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS } from 'ng-recaptcha';

@NgModule({
  imports: [
    RecaptchaModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: ConstantService.get(RE_CAPTCHA_KEY)
      } as RecaptchaSettings,
    }, {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'en', // use French language
    },

  ],
  declarations: [],
  exports: [
    RecaptchaModule
  ]
})
export class RecaptchaCoreModule { }
