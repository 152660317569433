<div class="modal-body v2 text-center">
    <a href="javascript:void()" class="close" (click)="close()"></a>
    <div class="row">
      <div class="col-sm-6 offset-sm-3 heading">BOXXPORT Negotiation</div>
    </div>
    <p>There is an update in Your Negotiations.</p>
    <div class="row marginT20">
      <div class="col-md-4 offset-md-4"  *ngIf="inNegotiationFlow">
        <button type="button" class="btn btn-primary btn-block" (click)="refresh()">REFRESH PAGE</button>
        </div>
        <div class="col-md-6 offset-md-3" *ngIf="!inNegotiationFlow">
          <button type="button" class="btn btn-primary btn-block" (click)="refresh()">Negotiation Management</button>
          </div>
    </div>
  </div>