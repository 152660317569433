import { EventEmitter, Injectable, Output } from "@angular/core";
import {
  CONFIG,
  ConstantService,
  STORAGE_PREFIX,
} from "@shared/constant/shared.constant";
import { Utils } from "@shared/constant/shared.utils";
import { LoggendInUser } from "@shared/auth/LoggendInUser";
import { resolve } from "path";
import { UserBecomeAsSellerService } from "@shared/services";
import { finalize } from "rxjs";
import { ORG_DATA } from "./auth.selleronly.guard";
import { ErrorUtils } from "@shared/constant/error.utils";

@Injectable({ providedIn: "root" })
export class AuthService {
  constructor(private userBecomeAsSellerService: UserBecomeAsSellerService) {}
  @Output() getLoggedIn: EventEmitter<boolean> = new EventEmitter();

  private redirectUrl = "/";

  private loginUrl = "/public/login";

  public getStorageName() {
    return ConstantService.get(STORAGE_PREFIX);
  }

  public getUserStorageName() {
    return this.getStorageName() + "currentUser";
  }

  isUserLoggedIn(): boolean {
    return Utils.isNotNull(localStorage.getItem(this.getUserStorageName()));
  }

  isUserSeller(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.isUserLoggedIn()) {
        this.userBecomeAsSellerService
          .step1Get()
          .pipe(finalize(() => {}))
          .subscribe(
            (data) => {
              const userAsSellerStep1DTO = data.result;
              ORG_DATA.currentOrg = userAsSellerStep1DTO;
              if (userAsSellerStep1DTO.isSeller) {
                resolve(true);
              } else if (userAsSellerStep1DTO.isPending) {
                resolve(true);
              } else {
                resolve(false);
              }
            },
            (error) => {
              ErrorUtils.logError(error);
              resolve(false);
            }
          );
      } else {
        resolve(false);
      }
    });
  }

  getRedirectUrl(): string {
    return this.redirectUrl;
  }
  setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }
  getLoginUrl(): string {
    return ConstantService.get("loginUrl") || this.loginUrl;
  }

  setLoginUrl(loginUrl) {
    this.loginUrl = loginUrl;
  }

  getLoggedInUser(): LoggendInUser {
    if (this.isUserLoggedIn()) {
      const currentUser = JSON.parse(
        localStorage.getItem(this.getUserStorageName())
      );
      const loggendInUser = <LoggendInUser>currentUser.user;
      return loggendInUser;
    }
  }
  setLoggedInUser(currentUser: any) {
    localStorage.setItem(this.getUserStorageName(), currentUser);
    this.getLoggedIn.emit(true);
  }
  isAdmin(): boolean {
    const loggendInUser: LoggendInUser =
      CONFIG["isAdmin"] && this.getLoggedInUser();
    return (
      Utils.isNotNull(loggendInUser) &&
      Utils.isNotNull(loggendInUser.roles) &&
      loggendInUser.roles.indexOf("ROLE_ADMIN") !== -1
    );
  }

  logoutUser(): void {
    localStorage.removeItem(this.getUserStorageName());
    this.getLoggedIn.emit(false);
  }
}
