import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RatingComponent } from '@marketplace/marketplace-rating-core/rating/rating.component';
import { SharedModule } from '@shared/shared.module';
import { RatingListComponent } from './rating-list/rating-list.component';
import { RatingDisplayComponent } from './rating-display/rating-display.component';
import { RatingListingPaginationComponent } from './rating-listing-pagination/rating-listing-pagination.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        RatingListComponent,
        RatingComponent,
        RatingDisplayComponent,
        RatingListingPaginationComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
    ],
    exports: [
        RatingListComponent,
        RatingComponent,
        RatingDisplayComponent,
        RatingListingPaginationComponent
    ]
})
export class MarketplaceRatingCoreModule { }
