export enum MasterDataType {

    SystemConfiguration = 'systemConfig',
    ContainerCondition = 'containerCondition',
    ContainerGrade = 'containerGrade',
    ContainerMedia = 'containerMedia',
    ContainerType = 'containerType',
    ColorOuter = 'colorOuter',
    ColorInner = 'colorInner',
    CountryTax = 'countryTax',
    TrustedBy = 'trustedBy',
    SpecialEquipment = 'specialEquipment',
    innerColorRange = 'innerColorRange',
    outerColorRange = 'outerColorRange',
    BankAccountDetailsCountryWise = 'bankAccountDetailsCountryWise',
    Newsletter = 'newsletter',
    CountryCity = 'countryCity',
}

export const toDisplayedAction = (masterDataType: MasterDataType): object => {
    let displayedAction = {};
    switch (masterDataType) {
        case MasterDataType.ContainerGrade:
        case MasterDataType.SpecialEquipment:
        case MasterDataType.ColorOuter:
        case MasterDataType.ColorInner:
        case MasterDataType.CountryTax:
        case MasterDataType.SystemConfiguration:
        case MasterDataType.ContainerCondition:
        case MasterDataType.Newsletter:
            displayedAction = {
                isEditable: true,
                isDeleteable: true,
            };
            break;
        case MasterDataType.ContainerType:
            displayedAction = {
                isDeleteable: true,
            };
            break;
        case MasterDataType.ContainerMedia:
            displayedAction = {
                isDeleteable: true,
            };
            break;
    }
    return displayedAction;
};

export const toDisplayedColumns = (masterDataType: MasterDataType): string[] => {
    let displayedColumns;
    switch (masterDataType) {
        case MasterDataType.ContainerGrade:
        case MasterDataType.SpecialEquipment:
        case MasterDataType.ContainerCondition:
            displayedColumns = ['code', 'description'];
            break;
        case MasterDataType.ContainerType:
            displayedColumns = ['containerTypeCategory', 'containerTypeCode', 'isoContainerTypeCode', 'containerTypeCodeDesc','customTypeCodeDesc'];
            break;
        case MasterDataType.ContainerMedia:
            displayedColumns = ['details.containerCondition.code', 'details.containerGrade.code', 'details.containerType.containerTypeCode', 'mediaType', 'documentId'];
            break;
        case MasterDataType.SystemConfiguration:
            displayedColumns = ['key', 'value', 'dataType', 'portal'];
            break;
        case MasterDataType.TrustedBy:
            displayedColumns = ['name', 'url'];
            break;
        case MasterDataType.CountryTax:
            displayedColumns = ['countryName', 'isEu'];
            break;
        case MasterDataType.ColorOuter:
        case MasterDataType.ColorInner:
            displayedColumns = ['code', 'colorCode'];
            break;
        case MasterDataType.Newsletter:
            displayedColumns = ['topic', 'link', 'newsletterType'];
            break;
    }
    return displayedColumns;
};

