import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { AbstractNavigationModalComponent } from '@marketplace/abstract/abstract-navigation-modal.component';
import { UpdatePriceModalComponent } from '@marketplace/marketplace-core/update-price-modal/update-price-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class PriceUpdateNavigationGuard implements CanDeactivate<AbstractNavigationModalComponent> {

    constructor(private modalService: BsModalService) { }
    canDeactivate(component: AbstractNavigationModalComponent): Observable<boolean> {

        if (!component.canDeactivate()) {
            const subject = new Subject<boolean>();
            const initialState = {
                callbackFunc: (bsModalRef: BsModalRef, { blockUIuuid }) => {
                    bsModalRef.hide();
                },
            };
            const modal = this.modalService.show(UpdatePriceModalComponent, { initialState } as any);
            modal.content.subject = subject;
            return subject.asObservable();
        } else {
            return new Observable<boolean>(resolve => {
                resolve.next(true);
                resolve.complete();
            });
        }
    }
}

