import { Component, Injector, Input } from '@angular/core';
import { AbstractNotificationWidgetComponent } from '@shared/notification-widget/abstract/abstract-notification-widget.component';

@Component({
  selector: 'shared-notification-widget-listing-header',
  templateUrl: './notification-widget-listing-header.component.html',
  styleUrls: ['./notification-widget-listing-header.component.scss']
})
export class NotificationWidgetListingHeaderComponent extends AbstractNotificationWidgetComponent {

  @Input() heading;

  constructor(
    protected injector: Injector,
  ) {
    super(injector);
  }

  protected OnChatWidgetInit() {
    super.OnChatWidgetInit();

  }


}
