import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';


@Injectable({
    providedIn: 'root'
})
export class SWService {

    constructor(updates: SwUpdate) {
        if (!updates || !updates.isEnabled) {
            console.warn('Nope SwUpdate 🙁');
            return;
        }
        if (updates.available) {
            updates.available
                .subscribe(event => {
                    console.warn('current version is', event.current);
                    console.warn('available version is', event.available);
                    window.location.reload();
                });
        }
        if (updates.activated) {
            updates.activated
                .subscribe(event => {
                    console.warn('old version was', event.previous);
                    console.warn('new version is', event.current);
                });
        }
    }
}
