import { Component } from '@angular/core';
import { AbstractBaseComponent } from '@shared/abstracts/abstract-base.component';

@Component({
  selector: 'marketplace-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent extends AbstractBaseComponent {

  constructor() { super(); }

}
