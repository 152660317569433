import { Injectable, Injector } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { AbstractBaseService } from '@shared/abstracts/abstract-base-service';
import { ConstantService, DISPLAY_META_TAG } from '@shared/constant/shared.constant';

@Injectable()
export class CustomMetaTagService extends AbstractBaseService {

    displayMetaTag: Boolean = ConstantService.get(DISPLAY_META_TAG);

    constructor(
        protected injector: Injector,
        private metaService: Meta
    ) {
        super();
    }

    updateTag(obj: any) {
        if (this.displayMetaTag === true) {
            this.metaService.updateTag(obj);
        }
    }
}
