<td>{{container.inventoryLocationList[0].inventoryAddress.city}} ,
    {{container.inventoryLocationList[0].inventoryAddress.country}}</td>
<td>{{container.type.containerTypeCodeDesc}}</td>
<td>{{container.grade.description}}</td>
<td>
    <div>{{container.validFrom | date:'dd-MMM-yyyy'}} - <br></div>
    <div *ngIf="container.validTo">{{container.validTo| date:'dd-MMM-yyyy'}}</div>
    <div *ngIf="!container.validTo">Open ended</div>
</td>
<td class="text-lg-right">
    <div>
        <span *ngIf="!editMode">{{container.quantityAvailable}}</span>
        <input NumbersOnly type="text" class="quantity text-sm-right withBorder" value="{{container.quantityAvailable}}"
            [(ngModel)]="newQuantity" *ngIf="editMode">
        <div *ngIf="container.sellAsLot" class="text-grey-blue">Sold as a lot</div>
        <div *ngIf="!container.sellAsLot" class="text-grey-blue">out of {{container.totalQuantity}}</div>
    </div>
</td>
<td class="text-lg-right">
    <div class="pl-2" *ngIf="!container.onRequest">
        <span *ngIf="!editMode">{{container.containerListingPrice?.priceAsOfTodayWithFee |number:'1.2-2'}}</span>
        <input NumbersOnly [allowDecimals]="false" type="text" class="priceAsOfTodayWithFee text-sm-right withBorder"
            value="{{container.containerListingPrice?.priceAsOfTodayWithFee}}" [(ngModel)]="newPrice" *ngIf="editMode" [disabled]="priceUpdateNotAllowed">
    </div>
    <div class="pl-2" *ngIf="container.onRequest">
        On Request
    </div>
</td>
<td class="last">
    <div class="actiondn">
        <a href="javascript:void(0);" (click)="toggleEditMode()" *ngIf="!editMode">Update</a>
        <a href="javascript:void(0);" (click)="saveContainer()" *ngIf="editMode">Save</a>
        <a href="javascript:void(0);" class="marginL20 text-danger" (click)="toggleEditMode()" *ngIf="editMode">Cancel</a>
    </div>
</td>