/**
 * This module is used to language translations.
 * The translations are saved in a json file in /src/app/assets/i18n directory
 * Docs: https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-angular7-app-with-ngx-translate
 */
import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
// import ngx-translate and the http loader
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@NgModule({
  declarations: [],
  imports: [
    TranslateModule.forRoot({})
  ],
  exports: [TranslateModule]
})
export class LanguageTranslationModule {
  public static forRoot(defaultLanguage: any, cacheBusting?: any): ModuleWithProviders<LanguageTranslationModule> {
    return {
      ngModule: LanguageTranslationModule,
      providers: [
        { provide: 'defaultLanguageI18n', useValue: defaultLanguage },
        { provide: 'cacheBustingI18n', useValue: cacheBusting },
      ]
    };
  }

  constructor(
    private translate: TranslateService,
    @Inject('defaultLanguageI18n') defaultLanguageI18n,
    @Inject('cacheBustingI18n') cacheBustingI18n,
  ) {
    // Gets Default language from browser if available, otherwise set English ad default
    this.translate.addLangs(['en']);
    this.translate.setTranslation('en', defaultLanguageI18n);
    const browserLang = 'en';
    this.translate.use(browserLang.match(/en/) ? browserLang : 'en');
  }
}
