import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const CONNECTION_MONITOR: Observable<boolean> = new Observable((observer) => {
    window.addEventListener('offline', (e) => {
        observer.next(false);
    });
    window.addEventListener('online', (e) => {
        observer.next(true);
    });
});

@Injectable({
    providedIn: 'root'
})
export class ConnectionService {

    monitor(): Observable<boolean> {
        return CONNECTION_MONITOR;
    }
}
