import { EventEmitter, Injectable, Output } from "@angular/core";
import { CartDTO } from "@marketplace/dto/CartDTO";
import { AuthService } from "@shared/auth/auth.service";
import { finalize } from "rxjs/operators";
import { CartApiService } from "./cart-api.service";

export interface CartEvent {
  isInitial: boolean;
  isAdd: boolean;
  isDelete: boolean;
}

export interface AddCartEvent {
  productIdentifier: string;
  cartIdentifier: string;
  quantityAvailabelForAdd: number;
  currencyBlock: any;
}

export interface DeleteCartEvent {
  productIdentifier: string;
  cartIdentifier: string;
}

const UPDATE_CART_EVENT: EventEmitter<CartEvent> = new EventEmitter();
const ADD_CART_SUMMARY_EVENT: EventEmitter<AddCartEvent> = new EventEmitter();
const DELETE_CART_SUMMARY_EVENT: EventEmitter<DeleteCartEvent> =
  new EventEmitter();
const CART_PRODUCTS: Map<string, CartDTO> = new Map<string, CartDTO>();
const CART_PRODUCT_LOCK: Set<string> = new Set<string>();

const isInitial = true;

let isAdd = true;

const isDelete = true;

@Injectable({
  providedIn: "root",
})
export class CartService {
  private isLogin = false;

  private cartProductLock: Set<string> = CART_PRODUCT_LOCK;

  private cartProducts: Map<string, CartDTO> = CART_PRODUCTS;

  @Output() updateCartEvent = UPDATE_CART_EVENT;

  @Output() addCartSummaryEvent = ADD_CART_SUMMARY_EVENT;

  @Output() deleteCartSummaryEvent = DELETE_CART_SUMMARY_EVENT;

  constructor(
    private cartApi: CartApiService,
    private authService: AuthService
  ) {
    this.authService.getLoggedIn.subscribe((data) => {
      this.handleLoginEvent(data);
    });
    this.handleLoginEvent(this.authService.isUserLoggedIn());
  }
  init() {
    this.handleLoginEvent(this.authService.isUserLoggedIn());
  }
  private handleLoginEvent(isLogin = false) {
    this.isLogin = isLogin;
    if (this.isLogin) {
      this.cartApi.cart().subscribe((data) => {
        if (data && data.result) {
          data.result.forEach((cart: CartDTO) => {
            this.cartProducts.set(cart.identifier, cart);
          });
        }
        this.updateCartEvent.emit({ isInitial } as CartEvent);
      });
    }
  }

  getCartItems(): CartDTO[] {
    return Array.from(this.cartProducts.values());
  }

  resetCart() {
    this.cartProducts.clear();
    this.cartApi.cart().subscribe((data) => {
      if (data && data.result) {
        data.result.forEach((cart: CartDTO) => {
          this.cartProducts.set(cart.identifier, cart);
        });
      }
      this.updateCartEvent.emit({ isAdd } as CartEvent);
    });
  }

  count() {
    let count = 0;
    this.cartProducts.forEach((cart: CartDTO) => {
      count += cart.quantity;
    });
    return count;
  }

  productCount() {
    let count = 0;
    this.cartProducts.forEach((cart: CartDTO) => {
      count += 1;
    });
    return count;
  }
  productWiseCount(productIdentifier) {
    let count = 0;
    this.cartProducts.forEach((cart: CartDTO) => {
      if (cart.productIdentifier === productIdentifier) {
        count += cart.quantity;
      }
    });
    return count;
  }
  addProductToCartByIdentifier(
    quantityAvailable: number,
    productIdentifier: string,
    cartIdentifier: string,
    quantity = 1,
    fixed = false,
    queryParams,
    includeHeader = true,
    isNegotiationFlow = false,
    redeliveryLocations,
    callback?: (data: CartDTO, error?: any) => void
  ) {
    if (!this.cartProductLock.has(productIdentifier)) {
      this.cartProductLock.add(productIdentifier);
      let api = null;
      if (cartIdentifier) {
        api = this.cartApi.addProductToCartByCartIdentifier(
          cartIdentifier,
          quantity
        );
      } else {
        api = this.cartApi.addProductToCart(
          productIdentifier,
          quantity,
          fixed,
          queryParams,
          includeHeader,
          isNegotiationFlow,
          redeliveryLocations
        );
      }
      api
        .pipe(
          finalize(() => {
            this.cartProductLock.delete(productIdentifier);
          })
        )
        .subscribe(
          (data) => {
            console.log("Test", data);
            const currencyBlock = data.result["currencyBlock"];

            cartIdentifier = data.result.cartIdentifier;
            const quantityAvailabelForAdd =
              data.result["quantityAvailableToAdd"];

            let cart = this.cartProducts.get(cartIdentifier);
            const newQuantity = data.result["quantity"];

            if (!cart) {
              cart = new CartDTO();
              cart.quantity = 0;
              cart.productIdentifier = productIdentifier;
            }
            cart.identifier = cartIdentifier;
            if (fixed) {
              cart.quantity = quantity;
            } else {
              cart.quantity = newQuantity;
            }
            this.cartProducts.set(cartIdentifier, cart);
            if (callback) {
              callback(cart);
            }
            this.updateCartEvent.emit({ isAdd } as CartEvent);
            this.addCartSummaryEvent.emit({
              productIdentifier,
              cartIdentifier,
              quantityAvailabelForAdd,
              currencyBlock,
            } as AddCartEvent);
          },
          (error) => {
            if (callback) {
              callback(undefined, error);
            }
          }
        );
    }
  }

  addProductToCart(
    quantityAvailable: number,
    productIdentifier: string,
    quantity = 1,
    fixed = false,
    queryParams,
    includeHeader = true,
    isNegotiationFlow = false,
    redeliveryLocations,
    callback?: (data: CartDTO, error?: any) => void
  ) {
    this.addProductToCartByIdentifier(
      quantityAvailable,
      productIdentifier,
      undefined,
      quantity,
      fixed,
      queryParams,
      includeHeader,
      isNegotiationFlow,
      redeliveryLocations,
      callback
    );
  }

  removeFromCartByProductDelete(productIdentifier: string) {
    return this.cartApi
      .removeFromCartByProductDelete(productIdentifier)
      .subscribe((data) => {
        const cartIdentifier = data.result;
        this.cartProducts.delete(cartIdentifier);
        this.updateCartEvent.emit({ isDelete } as CartEvent);
        this.deleteCartSummaryEvent.emit({
          productIdentifier,
        } as DeleteCartEvent);
      });
  }

  removeFromCart(cartIdentifier, productIdentifier) {
    return this.cartApi.removeFromCart(cartIdentifier).subscribe((data) => {
      this.cartProducts.delete(data.result);
      this.updateCartEvent.emit({ isDelete } as CartEvent);
      this.deleteCartSummaryEvent.emit({
        productIdentifier,
        cartIdentifier,
      } as DeleteCartEvent);
    });
  }
}
