import { HttpClient } from "@angular/common/http";
import { Injectable, Injector, NgZone } from "@angular/core";
import { AuthService } from "@shared/auth/auth.service";
import { BaseResponseDTO } from "@shared/dto/BaseResponseDTO";
import { NgxZendeskWebwidgetService } from "ngx-zendesk-webwidget";
import { BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";

// https://developer.zendesk.com/embeddables/docs/widget/core#setlocale

@Injectable({ providedIn: "root" })
export class ZendeskService {
  private ngxZendeskWebwidgetService: NgxZendeskWebwidgetService;
  isZendeskInitialized = new BehaviorSubject(false);

  constructor(
    injector: Injector,
    protected zone: NgZone,
    private http: HttpClient,
    private authService: AuthService
  ) {
    try {
      this.ngxZendeskWebwidgetService = injector.get(
        NgxZendeskWebwidgetService
      );
    } catch (e) {
      console.log("Error injecting zendesk service");
    }
  }

  initZendesk(counter = 0) {
    if (this.ngxZendeskWebwidgetService) {
      if (this.ngxZendeskWebwidgetService.isInitialized) {
        if (this.authService.getLoggedInUser()) {
          this.ngxZendeskWebwidgetService.zE.identify({
            name: this.authService.getLoggedInUser().firstName,
            email: this.authService.getLoggedInUser().email,
          });
        } else {
          this.ngxZendeskWebwidgetService.zE.identify({
            name: "",
            email: "",
          });
        }
        this.showHideChatWidget();
      } else if (counter < 15) {
        setTimeout(() => {
          this.initZendesk(++counter);
        }, 1000);
      }
    }
  }

  hideZendesk() {
    this.ngxZendeskWebwidgetService.zE("webWidget", "hide");
    this.isZendeskInitialized.next(false);
  }
  showZendesk() {
    this.ngxZendeskWebwidgetService.zE("webWidget", "show");
    this.isZendeskInitialized.next(true);
  }

  showHideChatWidget() {
    const url = "/api/p/users/chatSupportStatus";
    this.http
      .get<BaseResponseDTO<boolean>>(url)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data && data.result) {
            this.ngxZendeskWebwidgetService.zE("webWidget", "updateSettings", {
              contactOptions: {
                enabled: true,
                contactButton: { "*": "Help" },
              },
            });
            this.showZendesk();
          } else {
            this.hideZendesk();
          }
        },
        () => {
          this.showZendesk();
        }
      );
  }

  showContactUs() {
    let i = 0;
    const callback = () => {
      const zopim = window["$zopim"];
      if (zopim && zopim.livechat && zopim.livechat.window) {
        zopim.livechat.window.show();
      } else if (i < 20) {
        i++;
        setTimeout(callback, 5000);
      }
    };
    setTimeout(callback, 1000);
  }

  openChatWindow() {
    this.ngxZendeskWebwidgetService.zE("webWidget", "open");
  }
}
