import { FormGroup, Validators } from '@angular/forms';

export class ValidationService {

    static PASSWORD_CHECK = [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@%+'\/\\!#$^?.:,(){}\[\]~_-])[A-Za-z\d@%+'\/\\!#$^?.:,(){}\[\]~_-]{8,22}$/)];

    static PRODUCT_PRICE = [Validators.max(9999999), Validators.min(1), Validators.maxLength(5)];
    static LOCATION = [Validators.maxLength(255), ValidationService.freeTextCheck];
    static FIRST_NAME = [Validators.maxLength(20), ValidationService.wordsWithSpace];
    static LAST_NAME = [Validators.maxLength(40), ValidationService.wordsWithSpace];
    static COMPANY_NAME = [Validators.maxLength(100), ValidationService.freeTextCheck];
    static COMPANY_OTHER = [Validators.maxLength(250), ValidationService.freeTextCheck];
    static REGISTRATION_NUMBER = [Validators.maxLength(20), ValidationService.aphaNumeric];
    static TAX_ID = [Validators.maxLength(18), Validators.minLength(8), ValidationService.aphaNumeric];
    static ADDRESS_LINE1 = [Validators.maxLength(100), ValidationService.freeTextCheck];
    static ADDRESS_LINE1_DEPOT = [Validators.maxLength(255), ValidationService.freeTextCheck];
    static STREET = [Validators.maxLength(100)];
    static CITY = [Validators.maxLength(189), Validators.minLength(2), ValidationService.freeTextCheck];
    static STATE = [Validators.maxLength(70)];
    static COUNTRY = [Validators.maxLength(70)];
    static POSTALCODE = [Validators.maxLength(20), ValidationService.freeTextCheck];
    static EMAIL = [Validators.maxLength(254)];
    static BANK_NAME = [Validators.maxLength(40), ValidationService.freeTextCheck];
    static ACCOUNT_HOLDER_NAME = [Validators.maxLength(80), ValidationService.fullName];
    static IBAN = [Validators.maxLength(35), ValidationService.aphaNumeric];
    static ACCOUNT_NUMBER = [Validators.maxLength(30), ValidationService.aphaNumeric];
    static BANK_CODE = [Validators.maxLength(25), ValidationService.aphaNumeric];
    static BIC = [Validators.maxLength(11), ValidationService.aphaNumeric];
    static MOBILE_NUMBER = [Validators.maxLength(25), ValidationService.digitOnly];
    static LANDLINE_NUMBER = [Validators.maxLength(25), ValidationService.digitOnly];
    static WEBSITE_URL = [Validators.maxLength(2000), ValidationService.urlCheck];
    static DEPOT_NAME = [Validators.maxLength(255), ValidationService.freeTextCheck];
    static CONTACT_PERSON_NAME = [Validators.maxLength(80), ValidationService.fullName];
    static DEPOT_INFRA_TOTAL_AREA = [Validators.max(99999)];
    static PRODUCT_QUANTITY = [Validators.maxLength(4)];
    static CONTAINER_PREFIX = [Validators.maxLength(8), ValidationService.aphaNumeric];
    static CANCEL_INFO_MIN_LENGTH = [Validators.minLength(1)];
    static checkPasswords(group: FormGroup) {
        if (group.controls.password.touched && group.controls.confirmPassword.touched) {
            const pass = group.controls.password.value;
            const confirmPass = group.controls.confirmPassword.value;
            if (confirmPass && confirmPass !== '' && pass !== confirmPass) {
                group.controls.confirmPassword.setErrors({ notSame: true });
            }
        }
        return null;
    }

    static digitOnly(control: FormGroup) {
        const numbers = /^[0-9]+$/;
        if (control.value && !control.value.match(numbers)) {
            return { 'invalidDigit': true };
        }
    }

    static chipText(control: FormGroup) {
        const val = /^[a-zA-Z _ ,]+$/;
        if (control.value && !control.value.match(val)) {
            return { 'invalidChip': true };
        }
        if (control.value) {
            const chips = control.value.split(',');
            let isValid = true;
            chips.forEach(chip => {
                if (chip && chip.length > 54) {
                    isValid = false;
                }
            });
            if (!isValid) {
                return { 'invalidChiplength': true };
            }
        }
    }

    static freeTextCheck(control: FormGroup) {
        if (control.value && control.value[0] === ' ') {
            return { 'invalidFreeText': true };
        }
    }

    static fullName(control: FormGroup) {
        const text = /^[a-zA-Z ]+$/;
        if (control.value && (!control.value.match(text) || control.value[0] === ' ')) {
            return { 'invalidCharacters': true };
        }
    }

    static wordsWithSpace(control: FormGroup) {
        const text = /^[a-zA-Z]+( [a-zA-Z]+)*$/;
        if (control.value && !control.value.match(text)) {
            return { 'invalidCharacters': true };
        }
    }

    static aphabates(control: FormGroup) {
        const text = /^[A-Za-z]+$/;
        if (control.value && !control.value.match(text)) {
            return { 'invalidCharacters': true };
        }
    }

    static aphaNumeric(control: FormGroup) {
        const text = /^[A-Za-z0-9]+$/;
        if (control.value && !control.value.match(text)) {
            return { 'invalidCharacters': true };
        }
    }

    static urlCheck(control) {
        const regex = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
        return (!control.value || regex.test(control.value)) ? null : { 'invalidUrl': true };
    }

    static landingPageUrlSlugPattern(control) {
        const regex = /^[a-zA-Z0-9 _ -]+$/;
        return (!control.value || regex.test(control.value)) ? null : { 'invalidUrl': true };
    }
    static dateRangeCheck(control) {
        const regex = /^\d{4}(?:-\d{4})?$/;
        if (!control.value) {
            return null;
        }
        if (regex.test(control.value)) {
            const strArray = control.value.split('-');
            const currentYear = new Date().getFullYear();
            if (strArray.length === 2) {
                const data1 = parseInt(strArray[0], 10);
                const data2 = parseInt(strArray[1], 10);
                if (data2 <= data1 || data2 > currentYear || data1 > currentYear) {
                    return { 'invalidDateRange': true };
                }
            }

            if (strArray.length === 1) {
                const data1 = parseInt(strArray[0], 10);
                if (data1 > currentYear) {
                    return { 'invalidDateRange': true };
                }
            }
            return null;
        }
        return { 'invalidDateRange': true };
    }


    static checked(control) {
        return control.value === true ? null : { 'required': true };
    }

    static emailValidator(control) {
        if (control.value && control.value.length > 254) {
            return { 'invalidEmailAddress': true };
        }
        if (control.value && control.value.indexOf(',') > -1) {
            return { 'invalidEmailAddress': true };
        }
        // RFC 2822 compliant regex
        if (!control.value || control.value.toLowerCase().match(/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/)) {
            return null;
        } else {
            return { 'invalidEmailAddress': true };
        }
    }

    static containerPreviewTermsAndCondition(group: FormGroup, isNegotiationAllowed: Boolean) {
        if (group.controls.termsAndCondition.value === true) {
            group.controls.termsAndCondition.setErrors(null);
        } else {
            if (isNegotiationAllowed) {
                group.controls.termsAndCondition.setErrors({ required: true });
            } else {
                group.controls.termsAndCondition.setErrors({ requiredWithoutNegotiation: true });
            }
        }
    }

    static negotiableAmountValidator(group: FormGroup) {
        const negotiable = group.controls.negotiable.value;
        if (negotiable && !group.controls.negotiationAmount.value) {
            group.controls.negotiationAmount.setErrors({ negotiationAmountEmpty: true });
        } else {
            group.controls.negotiationAmount.setErrors(null);
        }
    }

    static checkForPriceTillValidation(group: FormGroup) {
        const priceFrom = Number(group.controls.priceFrom.value);
        const priceTill = Number(group.controls.priceTill.value);
        if (priceFrom && priceTill && priceFrom > priceTill) {
            group.controls.priceTill.setErrors({ priceTillIsSmallerThanPriceFrom: true });
        } else {
            group.controls.priceTill.updateValueAndValidity();
        }
    }

    static checkForRadiusValidation(group: FormGroup) {
        const radius = group.controls.radius.value;
        if (radius < 100) {
            group.controls.radius.setErrors({ radiusNeededMoreThan100: true });
        } else {
            group.controls.radius.setErrors(null);
        }
    }

    static passwordValidator(control) {
        // {6,100}           - Assert password is between 6 and 100 characters
        // (?=.*[0-9])       - Assert a string has at least one number
        if (control.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@%+'\/\\!#$^?.:,(){}\[\]~_-])[A-Za-z\d@%+'\/\\!#$^?.:,(){}\[\]~_-]{8,22}$/)) {
            return null;
        } else {
            return { 'invalidPassword': true };
        }
    }

    static eitherOfTwoRequired(control1, control2) {
        if (control1.value !== undefined || control2.value !== undefined) {
            return null;
        } else {
            return { 'invalidEndDateOrNoEndDate': true };
        }
    }

    static checkPhoneNumber(group: FormGroup) {
        const controls = group.controls;
        if (controls) {
            const phoneCode = controls.phoneNumberCode;
            const phoneNumber = controls.phoneNumber;
            phoneNumber.setValidators(ValidationService.MOBILE_NUMBER);
            if ((phoneCode && phoneCode.value !== '' && phoneCode.value !== null && phoneCode.value !== undefined)
                && (phoneNumber && (phoneNumber.value === null || phoneNumber.value === undefined))) {
                phoneNumber.markAsDirty();
                phoneNumber.setErrors({ 'required': true });
            } else if ((phoneNumber && phoneNumber.value !== '' && phoneNumber.value !== null && phoneNumber.value !== undefined)
                && (phoneCode && (phoneCode.value === null || phoneCode.value === undefined))) {
                phoneCode.markAsDirty();
                phoneCode.setErrors({ 'required': true });
            } else if (phoneCode
                && phoneNumber
                && (phoneNumber.value === '' || phoneNumber.value === null || phoneNumber.value === undefined)
                && (phoneCode.value === '' || phoneCode.value === null || phoneCode.value === undefined)) {
                phoneCode.markAsUntouched();
                phoneNumber.markAsUntouched();
                phoneCode.setErrors(null);
                phoneNumber.setErrors(null);
            }
        }

    }
}
