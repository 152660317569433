import { Injectable } from "@angular/core";
import { PlaceResultOption } from "@shared/map/map.utils";

@Injectable({
  providedIn: "root",
})
export class BoxxportBuyBackUtils {
  getRedeliveryLocationName(redeliveryLocation: PlaceResultOption) {
    let location = "";
    if (redeliveryLocation.city) {
      location = redeliveryLocation.city;
    }
    if (location && redeliveryLocation.state) {
      location = location + ", " + redeliveryLocation.state;
    } else if (redeliveryLocation.state) {
      location = redeliveryLocation.state;
    }
    if (location && redeliveryLocation.country) {
      location = location + ", " + redeliveryLocation.country;
    } else if (redeliveryLocation.country) {
      location = redeliveryLocation.country;
    }
    return location;
  }
}
