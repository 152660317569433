import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'marketplace-beacome-as-seller-home',
  templateUrl: './beacome-as-seller-home.component.html',
  styleUrls: ['./beacome-as-seller-home.component.scss']
})
export class BeacomeAsSellerHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
