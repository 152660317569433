<div
  class="d-flex justify-content-center align-items-center align-items-lg-start flex-column flex-wrap flex-lg-row stocks"
>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <img
        class="icon p-0"
        src="{{ assetPath }}/images/new-svg/stock_unit.svg"
        alt=""
      />
      <h2 class="m-0 p-0" #avalUnits>0</h2>
    </div>
    <div class="d-flex">
      <p class="text-white p-0 text-nowrap">Containers available</p>
    </div>
  </div>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <img
        class="icon p-0"
        src="{{ assetPath }}/images/new-svg/stock_value.svg"
        alt=""
      />
      <h2 class="d-inline m-0 p-0" #totalStock>0</h2>
      <p class="text-white align-self-end d-inline m-0">&nbsp;USD</p>
    </div>
    <div class="d-flex">
      <div class="icon"></div>
      <p class="text-white p-0">Total Stock Value</p>
    </div>
  </div>
</div>
