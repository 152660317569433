import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageRequest } from '@boss/secure/dto/PageRequest';
import { AbstractBaseService } from '@shared/abstracts/abstract-base-service';
import { BaseResponseDTO } from '@shared/dto';
import { MasterDataType } from '@shared/enums/MasterDataType';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ReadMasterDataService extends AbstractBaseService {

    constructor(public httpClient: HttpClient) { super(); }

    findAll(masterDataType: MasterDataType, pageRequest: PageRequest) {
        return this.httpClient.get<BaseResponseDTO<any>>(`${this.apiBaseUrl}masterData/${masterDataType}/`, { params: PageRequest.toQueryParam(pageRequest) }).pipe(first());
    }

    findDataAll(masterDataType: MasterDataType) {
        return this.httpClient.get<BaseResponseDTO<any[]>>(`${this.apiBaseUrl}masterData/${masterDataType}/all`).pipe(first());
    }

    distinctAll(masterDataType: MasterDataType) {
        return this.httpClient.get<BaseResponseDTO<any[]>>(`${this.apiBaseUrl}masterData/${masterDataType}/distinct`).pipe(first());
    }

    count(masterDataType: MasterDataType) {
        return this.httpClient.get<BaseResponseDTO<number>>(`${this.apiBaseUrl}masterData/${masterDataType}/count`, {}).pipe(first());
    }

}
