<main class="main">
  <div class="container mt-5">
    <div class="row">
      <div class="col-sm-4 order-sm-2 text-center text-sm-left mb-5"><img
          src="{{assetPath}}/images/svg/container-3box.svg" alt=""></div>
      <div class="col-sm-7">
          <h1>Sorry, we’re down for maintenance</h1>
          <p class="bold marginT30  marginB20">We ‘ll be back shortly.</p>
          <p>We are still available via email on <a href="mailto:hello@boxxport.com" class="bold">hello@boxxport.com</a> <br>
            please check out our social media for updates.</p>
      </div>
    </div>
  </div>
</main>