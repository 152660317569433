import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { CookieLawModule } from 'angular2-cookie-law';
import { CookieConsentComponent } from './cookie-consent.component';

// https://www.npmjs.com/package/angular2-cookie-law

@NgModule({
  declarations: [
    CookieConsentComponent,
  ],
  exports: [
    CookieConsentComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CookieLawModule // import Angular's CookieLaw modules
  ]
})
export class CookieConsentModule { }
