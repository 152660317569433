import { Component, OnInit, Injector, Sanitizer } from '@angular/core';
import { AbstractModalComponent } from '@marketplace/abstract/abstract-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ExternalSubscriptionService } from '@shared/services/external-subscription.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@shared/auth/auth.service';

@Component({
  selector: 'marketplace-subscribe-external-app',
  templateUrl: './subscribe-external-app.component.html',
  styleUrls: ['./subscribe-external-app.component.scss']
})
export class SubscribeExternalAppComponent extends AbstractModalComponent {

  resourceUrl: any;

  constructor(
    protected injector: Injector,
    public bsModalRef: BsModalRef,
    private translateService: TranslateService,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private externalSubscriptionService: ExternalSubscriptionService
  ) { super(bsModalRef, injector); }

  OnModalInit() {
    this.resourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.externalSubscriptionService.getSendInBlueIframeUrl());
    super.OnModalInit();
  }

  checkAndPrefillForUserDetails() {
    /* const loggedInUser = this.authService.getLoggedInUser();
    const iframe   = document.getElementById('subscriptionIframe') as HTMLIFrameElement;
    const doc = iframe.contentDocument || iframe.contentWindow.document;
    console.log(doc);

    const firstNameInputField = document.getElementById('FIRSTNAME') as HTMLInputElement;
    if (firstNameInputField) {
      firstNameInputField.value = 'Rajnish';
    }
    const lastNameInputField = doc.getElementById('LASTNAME') as HTMLInputElement;
    if (lastNameInputField) {
      lastNameInputField.value = 'Rajnish';
    }
    const emailInputField = doc.getElementById('EMAIL') as HTMLInputElement;
    if (emailInputField) {
      emailInputField.value = 'Rajnish';
    } */
  }

  close() {
    super.close();
  }
}
