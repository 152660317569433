import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ParamMap } from "@angular/router";
import { LocaleUtils } from "@shared/constant/shared.localeUtils";
import { Utils } from "@shared/constant/shared.utils";
import { ContainerDto } from "@shared/dto";
import { ValidationService } from "@shared/error/validation.service";
import { MapUtils, PlaceResultOption } from "@shared/map/map.utils";
import { intersection } from "lodash";

const DEFAULT_FILTER_PARAM = {
  get: (key) => {},
} as ParamMap;

class DefaultPlaceResultOption implements PlaceResultOption {
  name = "default";
  city = "default";
  state = "default";
  country = "default";
  countryShortName = "default";
  zipCode = "default";
  latitude = -1;
  longitude = -1;
  location = "default";
  addressLine1 = "default";
  addressLine2 = "default";
}

const DEFAULT_PLACE_RESULT_OPTION: DefaultPlaceResultOption =
  new DefaultPlaceResultOption();
const DEFAULT_PLACE_RESULT_OPTION_VALIDATION = {
  location: [Validators.required].concat(ValidationService.freeTextCheck),
};
const DEFAULT_PLACE_RESULT_OPTION_VALIDATION_NON_GA = {
  location: [Validators.required],
  city: [Validators.required],
  country: [Validators.required],
};
export class ProductSearchUtils {
  public static searchForm(
    filterParam: ParamMap = DEFAULT_FILTER_PARAM,
    defaultValues
  ): FormGroup {
    let radius = null;
    if (defaultValues && defaultValues.get("radius")) {
      radius = Utils.parseInt(
        filterParam.get("radius"),
        defaultValues.get("radius")
      );
    } else {
      radius = Utils.parseInt(filterParam.get("radius"), 0);
    }
    let container = Utils.parseInt(filterParam.get("container"));
    const sellAsLot = filterParam.get("sellAsLot") === "true";
    const boxxportChoiceOnly = filterParam.get("boxxportChoiceOnly") === "true";
    const isExport = filterParam.get("isExport") === "true";
    const exportCountry = filterParam.get("exportCountry");
    const yearOfManufacture = filterParam.get("yearOfManufacture");
    const cscCertificateAvailable =
      filterParam.get("cscCertificateAvailable") === "true";
    const cscPlateAvailable = filterParam.get("cscPlateAvailable") === "true";
    if (container <= 0) {
      container = undefined;
    }
    const formControls = {
      checklistType: new FormControl(undefined, []),
      checklistGrade: new FormControl(undefined, []),
      radius: new FormControl(radius, []),
      checklistSpecialEquipment: new FormControl(),
      container: new FormControl(container, [
        Validators.min(1),
        Validators.maxLength(4),
      ]),
      sellAsLot: new FormControl(sellAsLot),
      boxxportChoiceOnly: new FormControl(boxxportChoiceOnly),
      cscCertificateAvailable: new FormControl(cscCertificateAvailable),
      cscPlateAvailable: new FormControl(cscPlateAvailable),
      radiusReadonly: new FormControl(radius),
      isExport: new FormControl(isExport),
      exportCountry: new FormControl(exportCountry),
      addressCountryList: new FormControl(),
      yearOfManufacture: new FormControl(yearOfManufacture, [
        Validators.minLength(4),
        Validators.maxLength(4),
      ]),
      reDeliveryLocations: new FormControl(),
    };
    let validation = DEFAULT_PLACE_RESULT_OPTION_VALIDATION;
    if (!LocaleUtils.shouldAddressAutoCompleteWork()) {
      validation = DEFAULT_PLACE_RESULT_OPTION_VALIDATION_NON_GA;
    }
    Object.keys(DEFAULT_PLACE_RESULT_OPTION).forEach((key) => {
      formControls[key] = new FormControl(
        filterParam.get(key),
        validation[key] || []
      );
    });
    const form = new FormGroup(formControls);
    form["formName"] = "buyerSearch";
    return form;
  }

  public static updateFormByPlace(
    filterForm: FormGroup,
    place: google.maps.places.PlaceResult
  ) {
    if (filterForm && place) {
      const placeResult = MapUtils.placeResult(place);
      console.log(placeResult);
      Object.keys(DEFAULT_PLACE_RESULT_OPTION).forEach((key) => {
        const formControl: FormControl = filterForm.get(key) as FormControl;
        if (formControl) {
          formControl.setValue(placeResult[key]);
        }
      });
    }
  }

  public static clearPlace(filterForm: FormGroup) {
    Object.keys(DEFAULT_PLACE_RESULT_OPTION).forEach((key) => {
      const formControl: FormControl = filterForm.get(key) as FormControl;
      if (formControl) {
        formControl.setValue(undefined);
      }
    });
  }

  public static filterParamstoObject(filterParams: ParamMap): object {
    return filterParams.keys.reduce((obj, key) => {
      obj[key] = filterParams.get(key);
      return obj;
    }, {});
  }

  public static filterContainer(containerBulkDTO: ContainerDto, queryParams) {
    if (queryParams) {
      if (queryParams.checklistType) {
        const checklistType = queryParams.checklistType.split(",");
        if (
          checklistType.indexOf(containerBulkDTO.type.containerTypeCodeDesc) ===
          -1
        ) {
          return false;
        }
      }
      if (queryParams.addressCountryList) {
        const addressCountryList = queryParams.addressCountryList.split(",");
        if (
          intersection(
            addressCountryList,
            containerBulkDTO.inventoryLocationList
              ? containerBulkDTO.inventoryLocationList
                  .map((e) => e.inventoryAddress)
                  .filter((e) => e)
                  .map((e) => e.country)
              : []
          ).length === 0
        ) {
          return false;
        }
      }
      if (queryParams.checklistGrade) {
        const checklistGrade = queryParams.checklistGrade.split(",");
        if (checklistGrade.indexOf(containerBulkDTO.grade.description) === -1) {
          return false;
        }
      }
      if (queryParams.checklistSpecialEquipment) {
        if (!containerBulkDTO.specialEquipmentList) {
          return false;
        }
        const checklistSpecialEquipment =
          queryParams.checklistSpecialEquipment.split(",");
        if (
          intersection(
            checklistSpecialEquipment,
            containerBulkDTO.specialEquipmentList.map((e) => e.description)
          ).length === 0
        ) {
          return false;
        }
      }
      if (queryParams.cscCertificateAvailable) {
        const cscCertificateAvailable = queryParams.cscCertificateAvailable;
        if (
          cscCertificateAvailable !== containerBulkDTO.cscCertificateAvailable
        ) {
          return false;
        }
      }
      if (queryParams.sellAsLot) {
        const sellAsLot = queryParams.sellAsLot;
        if (sellAsLot !== containerBulkDTO.sellAsLot) {
          return false;
        }
      }
      if (queryParams.cscPlateAvailable) {
        if (!containerBulkDTO.cscValidTill) {
          return false;
        }
      }
      if (queryParams.container) {
        const container = Utils.parseInt(queryParams.container);
        if (containerBulkDTO.quantityAvailable < container) {
          return false;
        }
      }
      if (queryParams.checklistGrade) {
        const checklistGrade = queryParams.checklistGrade.split(",");
        if (checklistGrade.indexOf(containerBulkDTO.grade.description) === -1) {
          return false;
        }
      }
    }
    return true;
  }
}
