import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';

import { BaseResponseDTO } from '@shared/dto/BaseResponseDTO';
@Injectable({ providedIn: 'root' })
export class UserPublicService {


    constructor(private http: HttpClient) { }

    forgotPasswordPost(object: any) {
        return this.http.post<BaseResponseDTO<any>>('/api/p/users/forgotPassword', object).pipe(first());
    }

    forgotPasswordVerifyPost(object: any) {
        return this.http.post<BaseResponseDTO<any>>('/api/p/users/forgotPassword/verify', object).pipe(first());
    }

    authenticateUserPost(object: any) {
        return this.http.post<BaseResponseDTO<any>>('/api/p/users/login', object).pipe(first());
    }

    enableHeaderBanner() {
        const url = '/api/p/users/enableHeaderBanner';
        return this.http.get<BaseResponseDTO<boolean>>(url).pipe(first());
    }

    // ##Replace
}
