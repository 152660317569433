import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ErrorInterceptor, JwtInterceptor } from '@shared/_helpers';
import { TimingInterceptor } from '@shared/_helpers/timing.interceptor';

@NgModule({
  imports: [
  ],
  providers: [
    TimingInterceptor,
    JwtInterceptor,
    ErrorInterceptor,
    { provide: HTTP_INTERCEPTORS, useClass: TimingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  declarations: [
  ],
  exports: [
  ]
})
export class SharedServiceInterceptorModule { }
