import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@shared/auth/auth.service';
import { ErrorUtils } from '@shared/constant/error.utils';
import { PingService } from '@shared/services';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthSecureGuardService implements CanActivate, CanActivateChild {

  constructor(
    private pingService: PingService,
    private authService: AuthService, private router: Router) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      const fail = (url: string) => {
        this.router.navigate([url]);
        resolve(false);
      };
      if (this.authService.isUserLoggedIn()) {
        this.pingService.pingGet()
          .pipe(
            finalize(() => {
            })
          )
          .subscribe(data => {
            resolve(true);
          }, error => {
            ErrorUtils.logError(error);
            fail('login');
          });
      } else {
        fail('login');
      }
    });
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
