import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DataProvider {

    public storage: any;

    public constructor() { }

}
