import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { AbstractBaseService } from '@shared/abstracts/abstract-base-service';
import { ConstantService, DISPLAY_META_TAG } from '@shared/constant/shared.constant';

@Injectable()
export class CustomCanonicalTagService extends AbstractBaseService {

    displayMetaTag: Boolean = ConstantService.get(DISPLAY_META_TAG);

    constructor(protected injector: Injector, @Inject(DOCUMENT) private dom) {
        super();
    }

    createCanonicalURL() {
        let link: HTMLLinkElement = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.dom.head.appendChild(link);
        link.setAttribute('href', this.dom.URL);
    }
}
