import { Component, OnInit } from "@angular/core";
import { AbstractBaseComponent } from "@shared/abstracts/abstract-base.component";
import { PingService } from "@shared/services";
import { finalize } from "rxjs/operators";
import { Router } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "marketplace-under-maintenance",
  templateUrl: "./under-maintenance.component.html",
  styleUrls: ["./under-maintenance.component.scss"],
})
export class UnderMaintenanceComponent extends AbstractBaseComponent {
  private interval: any;
  previousUrl = "/";

  constructor(
    private pingService: PingService,
    private router: Router,
    private _location: Location
  ) {
    super();
  }

  protected OnInit(): void {
    this.interval = setInterval(() => this.checkServerUpStatus(), 5000);
  }

  private checkServerUpStatus(): void {
    this.pingService
      .pingGet()
      .pipe(finalize(() => {}))
      .subscribe((data) => {
        clearInterval(this.interval);
        this._location.back();
        this.router.navigate(["/"]);
      });
  }
}
