import { Component, Injector, Input } from '@angular/core';
import { AbstractNotificationWidgetComponent } from '@shared/notification-widget/abstract/abstract-notification-widget.component';
import { NotificationModel } from '@shared/notification-widget/dto/NotificationModel.dto';
@Component({
  selector: 'shared-notification-widget-listing-item-action',
  templateUrl: './notification-widget-listing-item-action.component.html',
  styleUrls: ['./notification-widget-listing-item-action.component.scss']
})
export class NotificationWidgetListingItemActionComponent extends AbstractNotificationWidgetComponent {

  @Input() notification: NotificationModel;

  constructor(
    protected injector: Injector,
  ) {
    super(injector);
  }

  protected OnChatWidgetInit() {
    super.OnChatWidgetInit();

  }

  toggleStatus() {
    this.toggleNotificationStatus(this.notification);
  }

  hide() {
    this.updateStatus(this.statusHide, [this.notification]);
  }

}
