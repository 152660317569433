<!-- PROOF OF CONCEPT PLACE RESULT COMPONENT -->

<div
  [hidden]="!showSearchResults"
  class="transparent-bg"
  (click)="closeAutoComplete($event)"
>
  <div #dropdownContainer class="position-absolute">
    <div *ngFor="let option of computedOptions" class="bg-white">
      <span class="text-cyan">{{ option.searchMatch }}</span
      >{{ option.prediction }}
    </div>
  </div>
</div>
{{ computedOptions | json }}
