import { AddContainerStep1DTO } from './AddContainerStep1DTO';

export class ContainerPriceHistoryDTO extends AddContainerStep1DTO {

    containerId: string;
    originalPrice: number;

    updatedPrice: number;
    startDate: Date;
    endDate: Date;
    priceHistory: string;

    negotiable: Boolean;

    negotiationAmount: number;

    negotiationAmountUnlimited: Boolean;

    onRequest: Boolean;
    updatedQuantity: number;
}
