import { DatePipe } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Injector, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantService } from '@shared/constant/shared.constant';
import { Utils } from '@shared/constant/shared.utils';
import { TranslateService } from '@ngx-translate/core';
import { ClientIPService } from '@shared/_services/client-ip.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

const CALL_SUBSCRIPTION_EVENT: EventEmitter<{}> = new EventEmitter();

let externalCallAlreadyMade: Boolean = false;

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {

    private toastrService;

    // private externalCallAlreadyMade: Boolean = false;

    @Output() public callToSubscriptionEvent = CALL_SUBSCRIPTION_EVENT;

    constructor(
        private injector: Injector,
        private router: Router,
        private translateService: TranslateService,
        private datePipe: DatePipe,
        private clientIpService: ClientIPService
    ) {
        try {
            this.toastrService = injector.get(ToastrService);
        } catch (e) { }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(evt => {
                const isRestApi = request.headers.get('isRestApi');
                if (request.url && isRestApi && evt instanceof HttpResponse) {
                    const body = evt.body;
                    const apiMessageCode = request.headers.get('api-message-code');
                    if (body && body.error) {
                        if (apiMessageCode && this.toastrService) {
                            this.toastrService.error(this.translateService.instant('toast.message.' + apiMessageCode + '.error.msg'), 'Error');
                        }
                        const error = new Error(body.msg);
                        error['requestId'] = evt.headers.get('x-request-id') || evt.headers.get('X-Request-Id');
                        throw error;
                    }
                    if (body && !body.error && apiMessageCode && this.toastrService) {
                        this.toastrService.success(this.translateService.instant('toast.message.' + apiMessageCode + '.success.msg'), 'Success');
                    }
                    const locale = evt.headers.get('locale');
                    if (locale) {
                        sessionStorage.setItem('locale', locale);
                    }
                    this.checkForExternalCall();
                }
                return evt;
            }),
            catchError(err => {
                err['correlationId'] = request.headers.get('x-correlation-id');
                const requestId = err['requestId'] || (err.headers && (err.headers.get('x-request-id') || err.headers.get('X-Request-Id')));
                const status = err.status;
                if (status === 401) {
                    // auto logout if 401 response returned from api
                    localStorage.clear();
                    location.reload();
                }
                const error = Utils.isNotNull(err.error) && err.error.msg || err.statusText || err.message || 'Unknown';
                const translation = this.translateService.instant(error.split('##')[0]);
                //in case of local environment , prevent redirect to /notfound page in case of clientip url id 404 
                if (status === 404 && request.url.endsWith('/p/clientip')) {
                    return throwError(translation);
                }
                if (true || ConstantService.get('isAdmin')) {
                    window.dispatchEvent(new CustomEvent('errorEvent', {
                        detail: {
                            message: translation,
                            correlationId: err.correlationId,
                            requestId,
                            url: request.url,
                            status, err
                        }
                    }));
                }
                if (status === 404) {
                    this.router.navigate(['/notFound']);
                }
                if (status === 412) {
                    this.router.navigate(['/accessDenied']);
                }
                return throwError(translation);
            }));
    }

    private checkForExternalCall() {
        const subscriptionDate = localStorage.getItem('subscriptionDate');
        const format = 'dd/MM/yyyy';
        const todaysDateAsString = this.datePipe.transform(new Date(), format);
        if (subscriptionDate === todaysDateAsString) {
            console.log('subscription date is todays date');
            return;
        }
        if (subscriptionDate === undefined) {
            console.log('subscription date is undefined');
            this.callExternalService();
        } else if (subscriptionDate !== todaysDateAsString) {
            console.log('subscription date is ', subscriptionDate);
            console.log('todaysDateAsString date is ', todaysDateAsString);
            this.callExternalService();
        }
    }

    private callExternalService() {
        if (!externalCallAlreadyMade) {
            externalCallAlreadyMade = true;
            console.log('timeout call made at :', new Date());
            setTimeout(() => {
                this.callToSubscriptionEvent.emit({});
                setTimeout(() => {
                    externalCallAlreadyMade = false;
                }, 5000);
            }, 90000);
        }
    }
}
