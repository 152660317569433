<div class="modal-body maxWidth" *blockUI="blockUIuuid">
  <div>
    <h1 class="marginB10">Unsaved changes</h1>
    <div class="marginT10 marginB10">
      <p>There are unsaved changes. Are you sure you want to leave this page?</p>
    </div>
    <div class="row marginT20">
      <div class="col-sm-6 colsm">
        <button type="button" class="btn btn-primary btn-block" (click)="close();">Stay on this page</button>
      </div>
      <div class="col-sm-6 colsm text-left">
        <a class="btn bold paddingL30" href="javascript:void(0)" (click)="yesClicked();">Leave page</a>
      </div>
    </div>
  </div>
</div>
