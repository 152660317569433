import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponseDTO } from '@shared/dto/BaseResponseDTO';
import { first } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class ActuatorService {

    constructor(private http: HttpClient) { }

    pingGet(object: any) {
        return this.http.get<BaseResponseDTO<object>>('/api/actuator/all', object).pipe(first());
    }

    // ##Replace
}
