import { Injectable } from '@angular/core';

export const CONFIG: object = {
    'production': true
};

export const RE_CAPTCHA_KEY = 'RE_CAPTCHA_KEY';

export const GOOGLE_MAP_API_KEY = 'GOOGLE_MAP_API_KEY';
export const DISPLAY_META_TAG = 'displayMetaTag';
export const SENDINBLUE_SUBSCRIPTION_IFRAME_KEY = 'SENDINBLUE_SUBSCRIPTION_IFRAME_KEY';
export const PAYMENT_BETAS = 'payment_intent_beta_3';
export const STRIP_PUBLIC_KEY = 'STRIP_PUBLIC_KEY';
export const BRIDGER_PAY_CASHIER_KEY = 'BRIDGER_PAY_CASHIER_KEY';
export const BRIDGER_PAY_API_URL = 'BRIDGER_PAY_API_URL';
export const IS_PRODUCTION_KEY = 'production';
export const PAYPAL_CLIENT_ID = 'PAYPAL_CLIENT_ID';
export const STORAGE_PREFIX = 'STORAGE_PREFIX';
export const PAYPAL_DISABLED_METHODS = 'PAYPAL_DISABLED_METHODS';
export const USE_HASH = 'USE_HASH';
export const HELP_URL = 'HELP_URL';
export const ENCRYPTION_KEY ='ENCRYPTION_KEY';

@Injectable({ providedIn: 'root' })
export class ConstantService {

    static get(key: string) {
        return CONFIG[key] || window['configuration'][key];
    }

    static loadConf(config: object): object {
        if (CONFIG['isConfLoaded'] === null || CONFIG['isConfLoaded'] === undefined) {
            CONFIG['isConfLoaded'] = true;
            for (const key in config) {
                if (config.hasOwnProperty(key)) {
                    const val = config[key];
                    CONFIG[key] = val;
                }
            }
        }
        return CONFIG;
    }
}

