import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';

import { BaseResponseDTO } from '@shared/dto/BaseResponseDTO';
@Injectable({ providedIn: 'root' })
export class ProductCategoryService {


    constructor(private http: HttpClient) { }

    getAllProductCategoriesGet(object: any) {
        return this.http.get<BaseResponseDTO<any[]>>('/api/productCategory/', object).pipe(first());
    }

    // ##Replace
}
