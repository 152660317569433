import { Injectable } from '@angular/core';
import { AbstractBaseService } from '@shared/abstracts/abstract-base-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseResponseDTO } from '@shared/dto';
import { first } from 'rxjs/operators';
import { ConstantService, SENDINBLUE_SUBSCRIPTION_IFRAME_KEY } from '@shared/constant/shared.constant';

@Injectable({ providedIn: 'root' })
export class ExternalSubscriptionService extends AbstractBaseService {

    constructor(private http: HttpClient) { super(); }

    private getSendInBlueIframeKey(): string {
        return ConstantService.get(SENDINBLUE_SUBSCRIPTION_IFRAME_KEY);
    }

    private getBaseUrl(): string {
        return `${this.apiBaseUrl}p/subscription/`;
    }

    getSendInBlueIframeUrl(): string {
        return `https://sibforms.com/serve/${this.getSendInBlueIframeKey()}`;
    }

    getContactInformation(emailId: string): any {
        const body = `{"emailID":"${emailId }"}`;
        return this.http.post<BaseResponseDTO<any>>(`${this.getBaseUrl()}get`, body).pipe(first());
    }

    createContactInformation(emailId: string): any {
        const body = `{"emailID":"${emailId }"}`;
        return this.http.post<BaseResponseDTO<any>>(`${this.getBaseUrl()}create`, body).pipe(first());
    }
}
