import { DecimalPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { NavigationEnd } from "@angular/router";
import { CartProductDTO } from "@marketplace/dto/CartProductDTO";
import { CartSummaryDTO } from "@marketplace/dto/CartSummaryDTO";
import { AuthService } from "@shared/auth/auth.service";
import { LoggendInUser } from "@shared/auth/LoggendInUser";
import { LocaleUtils } from "@shared/constant/shared.localeUtils";
import { Utils } from "@shared/constant/shared.utils";
import { BuyerContainerDto } from "@shared/dto/BuyerContainerDto";
import { finalize } from "rxjs/operators";
import { ClientIPService } from "./client-ip.service";
@Injectable({ providedIn: "root" })
export class GoogleTagManagerService {
  constructor(
    private authService: AuthService,
    private clientIpService: ClientIPService,
    private decimalPipe: DecimalPipe
  ) {}
  pageEvent(event: NavigationEnd) {
    if (LocaleUtils.shouldGoogleAnalyticsWork()) {
      try {
        if (event instanceof NavigationEnd) {
          const urlAfterRedirects = event.urlAfterRedirects;
          if (!urlAfterRedirects.startsWith("/buyer/product/listing")) {
            const data = this.getDataForAnalytics(
              "pageview",
              urlAfterRedirects
            );
            if (
              urlAfterRedirects.startsWith(
                "/seller/product/listing/add/type/individual/"
              )
            ) {
              if (
                urlAfterRedirects.startsWith(
                  "/seller/product/listing/add/type/individual/updatePrice"
                )
              ) {
                data["priceDetail"] = undefined;
              } else {
                data["updatePriceDetail"] = undefined;
              }
            }
            this.pushToDataLayer(data);
            console.log(
              "%%% Google Analytics page view event %%% " +
                urlAfterRedirects +
                " data " +
                (<any>window).dataLayer.keys()
            );
          }
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log(
        "%%% Google Analytics disabled for locale ::" +
          LocaleUtils.getLocale() +
          "%%%"
      );
    }
  }

  /**
   * Emit google analytics event
   * Fire event example:
   * this.emitEvent("testCategory", "testAction", "testLabel", 10);
   * @param {string} eventCategory
   * @param {string} eventAction
   * @param {string} eventLabel
   * @param {number} eventValue
   */
  public emitEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: BuyerContainerDto = null
  ) {
    if (LocaleUtils.shouldGoogleAnalyticsWork()) {
      const pagePath =
        eventAction === "productDetails"
          ? "/buyer/product/listing/" + eventValue.identifier
          : "";
      const data = this.getDataForAnalytics(eventAction, pagePath);
      data["event_category"] = eventCategory;
      data["event_label"] = eventLabel;
      const searchParams = {};
      if (eventValue) {
        searchParams["checklistGrade"] = eventValue.grade;
        searchParams["checklistType"] = eventValue.type;
        searchParams["city"] = eventValue.inventoryCity;
        searchParams["productId"] = eventValue.identifier;
        let price = eventValue.finalPrice;
        if (eventValue.discountedFinalPrice) {
          price = eventValue.discountedFinalPrice;
        }
        searchParams["price"] = this.decimalPipe.transform(price, "1.2-2");
        searchParams["sellerId"] = eventValue.sellerMaskedName;
        searchParams["sellAsLot"] = eventValue.sellAsLot ? true : false;
      }
      data["container_search_params"] = searchParams;
      this.pushToDataLayer(data);
    }
  }
  public emitPurchaseEvent(
    cartSummary: CartSummaryDTO,
    cartProduct: CartProductDTO[]
  ) {
    if (LocaleUtils.shouldGoogleAnalyticsWork()) {
      let data = {
        event: "purchase",
        ecommerce: {
          shipping: 0,
          currency: "USD",
          transaction_id: cartSummary.cartIdentifierList,
          value: cartSummary.totalAmount,
          tax: "",
          items: cartProduct,
        },
      };
      this.pushToDataLayer(data);
    }
  }

  public emitEventForListing(queryParams: any, totalCount: number) {
    if (LocaleUtils.shouldGoogleAnalyticsWork()) {
      const pagePath =
        "/buyer/product/listing?" + Utils.toQueryParam(queryParams);
      const data = this.getDataForAnalytics("pageview", pagePath);
      queryParams["totalCount"] = totalCount;
      data["container_search_params"] = queryParams;
      this.pushToDataLayer(data);
    }
  }
  public emitEventForPage(path: string, dataMap: Map<any, any>) {
    if (LocaleUtils.shouldGoogleAnalyticsWork()) {
      const data = this.getDataForAnalytics("pageview", path);
      for (let [key, value] of dataMap.entries()) {
        data[key] = value;
      }
      this.pushToDataLayer(data);
    }
  }
  getDataForAnalytics(event: string, pagePath: string) {
    const data = {
      event: event,
      page_path: pagePath,
      campaignName: "(direct)",
      campaignSource: "(direct)",
      campaignMedium: "organic",
      campaignKeyword: "Boxxport",
      campaignContent: "content",
      encoding: "UTF-16",
      language: "en-us",
    };
    const loggendInUser: LoggendInUser = this.authService.getLoggedInUser();
    if (loggendInUser) {
      data["userId"] = loggendInUser.email;
    } else {
      data["userId"] = undefined;
    }
    return data;
  }

  private pushToDataLayer(data: any) {
    this.clientIpService
      .getIPAddress()
      .pipe(
        finalize(() => {
          let dlayer: any = (<any>window).dataLayer;
          (<any>window).dataLayer["container_search_params"] = {};
          (<any>window).dataLayer.push(data);
        })
      )
      .subscribe(
        (response) => {
          data["user_ip"] = response["msg"];
        },
        (error) => {
          data["user_ip"] = null;
        }
      );
  }
}
