<ng-container [ngSwitch]="isSeller">
  <div class="d-flex h-100 flex-column flex-lg-row" *ngSwitchCase="true">
    <div
      class="ml-lg-4 m-0 align-self-center text-center text-lg-left col-12 col-lg-3 flex-shrink-1 p-0"
    >
      <img
        alt=""
        class="w-25 mb-3"
        src="{{ assetPath }}/images/new-svg/Sell.svg"
      />
      <p class="text-white font32 font-md-26 display-1">
        <span class="bold">Sell</span><br />
        Shipping Containers Worldwide
      </p>
    </div>

    <div
      class="d-flex flex-column flex-grow-1 px-0 px-lg-5 pb-0 pb-lg-3 col-12 col-lg-9"
    >
      <p class="text-blue-lighter ml-3 mb-0">
        <marketplace-update-default-company-header
          [organizationDTO]="orgDTO | async"
          [showRedirectButton]="false"
        ></marketplace-update-default-company-header>
      </p>
      <div class="bg-blue-lightest-2 flex-grow-1 rounded-border p-3">
        <p class="text-color text-left bold m-0">To do</p>
        <div class="row rowsm text-center align-items-center h-100 mt-n4">
          <div class="col-6 col-lg-4" *ngIf="listingCount > -1">
            <p>
              <img
                alt=""
                src="{{ assetPath }}/images/new-svg/listingicon.svg"
              />
            </p>
            <div class="font24 bold text-blue">
              <a
                [routerLink]="['/seller/product/listing/active']"
                routerLinkActive="router-link-active"
                >{{ listingCount }}</a
              >
            </div>
            <div class="bold text-color">Active Listing</div>
          </div>
          <div class="col-6 col-lg-4 align-self-center" *ngIf="orderCount > -1">
            <p class="">
              <img alt="" src="{{ assetPath }}/images/new-svg/ordersicon.svg" />
            </p>
            <div class="font24 bold text-blue">
              <a
                [routerLink]="['/seller/orders/listing/live']"
                routerLinkActive="router-link-active"
                >{{ orderCount }}</a
              >
            </div>
            <div class="bold text-color">Pending Orders</div>
          </div>
          <button
            class="vl text-left col-12 col-lg-4 d-flex justify-content-center align-items-center align-self-stretch"
            routerLink="/seller/dashboard"
          >
            <span class="">
              <img
                class="m-0"
                src="{{ assetPath }}/images/new-svg/rightcyanarrow.svg"
              />

              <p>
                Go to
                <span class="bold"
                  >Seller<br />
                  Dashboard</span
                >
              </p>
            </span>
          </button>
        </div>
      </div>
      <button
        class="btn btn-pink mt-2"
        routerLink="/seller/product/listing/add/type"
      >
        <img
          class="mr-2"
          src="{{ assetPath }}/images/new-svg/uploadstockicon.svg"
        /><span class="bold">Upload</span> new stocks
      </button>
    </div>
  </div>
  <div
    class="d-flex flex-wrap flex-lg-nowrap justify-content-around align-items-center text-white h-100 py-3"
    *ngSwitchCase="false"
  >
    <div class="col-12 col-lg-4 text-lg-left text-center font-freightSans-pro">
      <h1 class="m-0 fw-350 font32">
        Unlock global opportunities:
        <span class="bold font32">
          Sell your shipping containers on Boxxport today!
        </span>
      </h1>
    </div>
    <div class="d-flex flex-column align-item-center col-12 col-lg-4">
      <img
        class="my-4 mx-auto"
        src="{{ assetPath }}/images/new-svg/Sell.svg"
        height="100px"
        width="100px"
      />
      <button class="btn btn-pink mb-2" routerLink="/seller/dashboard">
        Register and start Selling
        <img
          class="pl-1"
          src="{{ assetPath }}/images/new-svg/rightpinkarrow.svg"
        />
      </button>
      <button
        class="btn btn-pink-outline"
        routerLink="/sell-shipping-containers"
      >
        Learn More
      </button>
    </div>
  </div>
</ng-container>
