import { Component, Injector } from '@angular/core';
import { NotificationWidgetListingItemComponent } from '@shared/notification-widget/notification-widget-listing/notification-widget-listing-item/notification-widget-listing-item.component';

@Component({
  selector: 'shared-notification-widget-listing-small-item',
  templateUrl: './notification-widget-listing-small-item.component.html',
  styleUrls: ['./notification-widget-listing-small-item.component.scss']
})
export class NotificationWidgetListingSmallItemComponent extends NotificationWidgetListingItemComponent {

  constructor(
    protected injector: Injector,
  ) {
    super(injector);
  }

}
