import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractBaseService } from '@shared/abstracts/abstract-base-service';
import { BaseResponseDTO } from '@shared/dto';
import { landingPageDTO } from '@shared/dto/LandingPageDTO';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MarketplaceLandingPageService extends AbstractBaseService {

    constructor(private http: HttpClient) { super(); }

    private baseUrl() {
        return `${this.apiBaseUrl}p/landingPage/`;
    }

    getLandingPageDetailsByUrl(url: string) {
        return this.http.get<BaseResponseDTO<landingPageDTO>>(`${this.baseUrl()}detail/${url}`).pipe(first());
    }

}