import { AbstractControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Utils } from '@shared/constant/shared.utils';
import { uniq } from 'lodash';
import { BaseDTO } from './BaseDTO';
import { CountryDTO } from './CountryDTO';

export class CountryWrapperDTO extends BaseDTO {

    phoneCodes = [];

    countryNames = [];

    countryData: CountryDTO[];

    currentState = [];

    constructor(countryData: CountryDTO[]) {
        super();
        this.countryData = countryData;
        if (this.countryData) {
            const uniquePhoneCode = {};
            const uniqueCountry = {};
            countryData.forEach(countryDTO => {
                const state = countryDTO.state;
                let countryName = countryDTO.countryName;
                if (countryName) {
                    countryName = countryName.trim();
                    if (!uniqueCountry[countryName]) {
                        uniqueCountry[countryName] = { key: countryName, value: countryName, state: [] };
                    }
                    if (state) {
                        if (state.trim() === '-') {
                            uniqueCountry[countryName].state.push(countryName.trim());
                        } else {
                            uniqueCountry[countryName].state.push(state.trim());
                        }
                    }
                }

                const phoneCode = countryDTO.phoneCode;
                if (phoneCode) {
                    phoneCode.split('#none#')
                        .forEach(i => {
                            i = i.trim();
                            if (!uniquePhoneCode[i]) {
                                uniquePhoneCode[i] = { key: i, value: i };
                            }
                        });
                }
            });
            this.phoneCodes = Object.keys(uniquePhoneCode).map(e => uniquePhoneCode[e]).sort();
            this.countryNames = Object.keys(uniqueCountry).map(e => uniqueCountry[e]);
        }
    }

    changeCountry($event) {
        if ($event) {
            this.currentState = uniq($event.state);
        } else {
            this.currentState = [];
        }
    }

    changeCountryName(countryName, ngSelectComponent?: NgSelectComponent, abstractControl?: AbstractControl, stateValue?: string) {
        if (ngSelectComponent) {
            const selectedItems = ngSelectComponent.selectedItems;
            if (Utils.isNotEmpty(selectedItems)) {
                selectedItems.pop();
                if (abstractControl) {
                    abstractControl.markAsUntouched();
                    abstractControl.setValue(stateValue);
                }
            }
        }
        const country = this.countryNames.find(e => e.key === countryName) || {};
        this.changeCountry(country);
    }


}
