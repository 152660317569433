import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { AbstractBaseService } from '@shared/abstracts/abstract-base-service';
import { BaseResponseDTO } from '@shared/dto';
import { NotificationCountModel } from '../dto/NotificationCountModel.dto';
import { NotificationModel } from '../dto/NotificationModel.dto';


const UPDATE_NOTIFICATION_EVENT: EventEmitter<any> = new EventEmitter();

const UPDATE_NOTIFICATION_COUNT_EVENT: EventEmitter<any> = new EventEmitter();

@Injectable({ providedIn: 'root' })
export class NotificationWidgetService extends AbstractBaseService {

  @Output() updateNotificationEvent = UPDATE_NOTIFICATION_EVENT;

  @Output() updateNotificationCountEvent = UPDATE_NOTIFICATION_COUNT_EVENT;

  constructor(private http: HttpClient) { super(); }

  private apiUrl(userType: string, status: string) {
    return `${this.apiBaseUrl}notification/${userType}/${status}/`;
  }

  public list(userType: string, status: string, filter: any) {
    const params = this.toParam(filter);
    return this.http.get<BaseResponseDTO<NotificationModel[]>>(this.apiUrl(userType, status), { params });
  }

  private toParam(filter: any) {
    let params = new HttpParams();
    if (filter) {
      Object.keys(filter)
        .forEach(key => {
          params = params.set(key, filter[key]);
        });
    }
    return params;
  }

  public count(userType: string, status: string, filter: any) {
    // const params = this.toParam(filter);
    return this.http.post<BaseResponseDTO<NotificationCountModel>>(`${this.apiUrl(userType, status)}count`, filter);
  }

  public post(userType: string, status: string, identifiers: string[] | number[]) {
    return this.http.put<BaseResponseDTO<void>>(`${this.apiUrl(userType, status)}${identifiers.join(',')}`, undefined);
  }



}
