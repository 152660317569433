import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';

import { BaseResponseDTO } from '@shared/dto/BaseResponseDTO';
import { UserProfileDTO } from '@shared/dto';
@Injectable({ providedIn: 'root' })
export class UserProfileService {


    constructor(private http: HttpClient) { }

    profileGet(object: any) {
        return this.http.get<BaseResponseDTO<any>>('/api/users/profile/', object).pipe(first());
    }

    addressPut(object: any) {
        return this.http.put<BaseResponseDTO<any>>('/api/users/profile/address/{id}', object).pipe(first());
    }

    addressPost(object: any) {
        return this.http.post<BaseResponseDTO<any>>('/api/users/profile/address', object).pipe(first());
    }

    addressDelete(object: any) {
        return this.http.delete<BaseResponseDTO<any>>('/api/users/profile/address/{id}', object).pipe(first());
    }

    addressGet(object: any) {
        return this.http.get<BaseResponseDTO<any[]>>('/api/users/profile/address', object).pipe(first());
    }

    preferencesGet(object: any) {
        return this.http.get<BaseResponseDTO<any[]>>('/api/users/profile/preferences', object).pipe(first());
    }

    preferencesPut(object: any) {
        return this.http.put<BaseResponseDTO<any>>('/api/users/profile/preferences/{id}', object).pipe(first());
    }

    preferencesPost(object: any) {
        return this.http.post<BaseResponseDTO<any>>('/api/users/profile/preferences', object).pipe(first());
    }

    preferencesDelete(object: any) {
        return this.http.delete<BaseResponseDTO<any>>('/api/users/profile/preferences/{id}', object).pipe(first());
    }

    addressDefaultPut(object: any) {
        return this.http.put<BaseResponseDTO<any>>('/api/users/profile/address/default/{id}', object).pipe(first());
    }

    profileSetPost(object: any) {
        return this.http.post<BaseResponseDTO<any>>('/api/users/profile/', object).pipe(first());
    }

    commissionGet() {
        return this.http.get<BaseResponseDTO<number>>('/api/users/profile/commission').pipe(first());
    }

    organizationPhonePut(object: any) {
        return this.http.put<BaseResponseDTO<any>>('/api/organization/phone', object).pipe(first());
    }
    userPhonePut(object: any) {
        return this.http.put<BaseResponseDTO<any>>('/api/users/profile/phone', object).pipe(first());
    }

    removeUserProfile(identifier: string) {
        return this.http.delete<BaseResponseDTO<any>>('/api/organization/profile/remove/' + identifier).pipe(first());
    }
    removeUserInvite(email: string) {
        return this.http.post<BaseResponseDTO<any>>('/api/users/invite/member/remove', { email }).pipe(first());
    }
    assignAsAdmin(identifier: string) {
        return this.http.put<BaseResponseDTO<any>>('/api/organization/profile/' + identifier + '/assignAsAdmin', {}).pipe(first());
    }
    assignAsMember(identifier: string) {
        return this.http.put<BaseResponseDTO<any>>('/api/organization/profile/' + identifier + '/assignAsMember', {}).pipe(first());
    }
    // ##Replace
}
