<div class="paginationDiv filter font16 fw-400">
  <span
    class="icn-ltarrow marginL20 marginR10"
    [ngClass]="isFirst() ? 'opacity50 cursorDisabled' : 'cursorPointer'"
    (click)="prevPage()"
    >Previous</span
  >
  <select
    class="rounded1"
    [disabled]="isDisabled"
    [(ngModel)]="defaultLength"
    (change)="changeLength($event)"
  >
    <option *ngFor="let option of options" [value]="option">
      {{ option }}
    </option>
  </select>
  <span class="rounded1"
    >{{ getFirstDisp() }}-{{ getLastDisp() }} of {{ totalCount }}</span
  >
  <span
    class="icn-rtarrow"
    [ngClass]="isLast() ? 'opacity50 cursorDisabled' : 'cursorPointer'"
    (click)="nextPage()"
    >Previous</span
  >
</div>
