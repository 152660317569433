import { Component, OnInit } from '@angular/core';
import { AbstractBaseComponent } from '@shared/abstracts/abstract-base.component';

@Component({
  selector: 'shared-notification-widget-no-notification',
  templateUrl: './notification-widget-no-notification.component.html',
  styleUrls: ['./notification-widget-no-notification.component.scss']
})
export class NotificationWidgetNoNotificationComponent extends AbstractBaseComponent {

}
