import { Component, ElementRef, EventEmitter, Injector, Input, Output, ViewChild, AfterViewInit } from '@angular/core';
import { AbstractBaseBlockUIComponent } from '@shared/abstracts/abstract-base-blockui.component';
import { ConstantService, STORAGE_PREFIX } from '@shared/constant/shared.constant';
import { Angular2CookieLawService, CookieLawContainerComponent } from 'angular2-cookie-law';
import { Utils } from '@shared/constant/shared.utils';

@Component({
  selector: 'shared-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent extends AbstractBaseBlockUIComponent implements AfterViewInit {

  isAgreeAccept = false;

  @Output() isChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() cookieLawName = ConstantService.get(STORAGE_PREFIX) + '_CookieLaw';

  @Input() expiration = 365;

  @Input() position = 'bottom';

  @ViewChild('cookieLaw')
  public cookieLawEl: CookieLawContainerComponent;

  @ViewChild('contentProjection') contentProjection: ElementRef;

  public cookieLawSeen: boolean;

  constructor(
    protected injector: Injector,
    private angular2CookieLawService: Angular2CookieLawService
  ) { super(injector); }

  ngAfterViewInit(): void {
    this.cookieLawSeen = this.cookieLawEl.cookieLawSeen;
  }

  hasContentProjection() {
    if (!this.contentProjection) { return; }
    const detailContentProjection: NodeList = (this.contentProjection
      .nativeElement as HTMLElement).childNodes;
    for (let x = 0; x < detailContentProjection.length; x++) {
      console.log(detailContentProjection.item(x).nodeType);
      if (detailContentProjection.item(x).nodeType !== 8) {
        return true; // nodeType == 8 means is a comment
      }
    }
  }

  public seen(evt: boolean) {
    const cookieLawEl = this.cookieLawEl;
    this.isChange.emit({
      cookieLawEl,
      cookieLawSeen: evt
    });
  }

  accept() {
    if (this.isAgreeAccept) {
      this.angular2CookieLawService.storeCookie(this.cookieLawName + 'Accept', this.expiration);
    }
    this.dismiss();
  }

  isAccept() {
    return this.angular2CookieLawService.seen(this.cookieLawName + 'Accept');
  }
  public dismiss(): void {
    this.cookieLawEl.dismiss();
  }
}
