<div
  delayClickOutsideInit="true"
  attachOutsideOnClick="true"
  (clickOutside)="hideEvent(true)"
>
  <span
    class="d-none d-lg-block"
    aria-label="notification icon"
    (mouseenter)="bellIconClick()"
  >
    <span class="icn-alarm" aria-label="alarm icon"></span>
    <span *ngIf="count" class="badge badge-pill">
      {{ count }}
    </span>
  </span>
  <span [routerLink]="['/profile/notification']" class="d-block d-lg-none">
    <span class="icn-alarm"></span>
    <span *ngIf="count" class="badge badge-pill">
      {{ count }}
    </span>
  </span>
  <!-- <a
    class="d-inline d-lg-none marginL10 text-white"
    [routerLink]="['/profile/notification']"
    >All Notifications</a
  > -->
  <shared-notification-widget-listing-small
    (mouseleave)="onClickedOutside($event)"
    [status]="status"
    (hideEvent)="hideEvent($event)"
    [hidden]="hideNotification"
    *ngIf="!hideNotification"
  >
  </shared-notification-widget-listing-small>
</div>
