import { AfterViewInit, Component, Injector } from '@angular/core';
import { AbstractBaseBlockUIComponent } from '@shared/abstracts/abstract-base-blockui.component';

@Component({
  selector: 'landing-page-why-boxxport',
  templateUrl: './landing-page-why-boxxport.component.html',
  styleUrls: ['./landing-page-why-boxxport.component.scss']
})
export class LandingPageWhyBoxxportComponent extends AbstractBaseBlockUIComponent implements AfterViewInit {
  readonly whyus = [
    {
      title: 'Get Real-time Information',
      mesg: `Getting hands-on real-time information is a tedious job and when it comes to buying shipping containers, you never want to be in doubt. With our on-point real-time data and precise insights on prices and availability, we find the best way out so that you never have to go empty-handed`,
      img: `${this.assetPath}/images/why_one.png`,
      imgTitle: `Used 40ft containers for sale`,
      imgAlt: `Shipping containers trading`
    },
    {
      title: 'Trade Fast and Easy',
      mesg: `With our supreme online container trading services, you can trade-in shipping units by sitting even in the luxury of your homes. With the power of IoT technologies, we make the whole trading process super-fast and easy. You just have to click on it to get the best shipping box trading solution.`,
      img: `${this.assetPath}/images/why_two.png`,
      imgTitle: `Shipper owned container`,
      imgAlt: `Different types of containers`
    },
    {
      title: 'Go Global',
      mesg: `At BOXXPORT, your trade options are limitless. With us, you don’t have to confine at a local level. We have huge trade opportunities to match you with container buyers or sellers locally and globally. You can trade new and used shipping containers in USA or everywhere else by being at any location in the world.`,
      img: `${this.assetPath}/images/why_three.png`,
      imgTitle: `Container buyer`,
      imgAlt: `Shipping container trading`
    },
    {
      title: 'Connect and Control',
      mesg: `BOXXPORT is known for its crème container solutions. We help you digitally supervise all the container dealings data with our cloud-based business solutions. You don’t have to rely on others for shipping unit’s trading, just open BOXXPORT’s dashboard, and the world is yours.`,
      img: `${this.assetPath}/images/why_four.png`,
      imgTitle: `Maritime container`,
      imgAlt: `Shipping container dimensions`
    },
    {
      title: 'Live Stocks',
      mesg: `With huge containers at the disposal, BOXXPORT provides you an open marketplace to give you an amazing opportunity to sell and buy containers worldwide. Increase your business efficiency with our optimized online trading processes. `,
      img: `${this.assetPath}/images/why_five.png`,
      imgTitle: `Shipping container near me`,
      imgAlt: `Intermodal containers`
    },
    {
      title: 'Get Container Prices Immediately      ',
      mesg: `At BOXXPORT, you can relish container services 24x7. With our accurate real-time and impeccable insights, you can get to know all about container pricing and availability at any time. You are in control all the time as you can track all the shipping unit details with ease. `,
      img: `${this.assetPath}/images/why_six.png`,
      imgTitle: `Buy and sell containers`,
      imgAlt: `Second-hand containers for sale`
    },
    {
      title: 'Transparent Processes',
      mesg: `BOXXPORT offers completely transparent transaction services and pricing systems. We believe you must know where your money is going. All the transaction process takes place in front of you and you have the freedom of downloading or generating the details with just a click. We help you in trading in the shipping containers smartly. `,
      img: `${this.assetPath}/images/why_seven.png`,
      imgTitle: `Where to buy containers`,
      imgAlt: `Shipping container supplier`
    },
    {
      title: 'Efficient and Accessible',
      mesg: `To handle all the container-buying and selling proceedings, you have got your own dashboard to keep a check on all the stocks, depots, orders, and sales. You are the manager of your marketplace whether you are buying a single container or in bulk trade. With BOXXPORT, you are a step ahead as we send you all the reports and alerts.`,
      img: `${this.assetPath}/images/why_eight.png`,
      imgTitle: `Container selling companies`,
      imgAlt: `Shipping container services`
    },
    {
      title: 'Developed with the Latest Technology',
      mesg: `At BOXXPORT, we use innovative digital technologies to provide you with flawless services and exquisite support. With strong logistics expertise, we help you reach new heights with ease.    We help you in getting optimum, scalable, and profitable results by providing the right shipping container.`,
      img: `${this.assetPath}/images/why_nine.png`,
      imgTitle: `Steel shipping containers`,
      imgAlt: `Metal Containers for sale`
    },
  ];
  constructor(protected injector: Injector) { super(injector); }

  ngAfterViewInit() {
    [...document.getElementsByClassName('carousel ') as any].forEach(e => e.removeAttribute('tabindex'));
  }
}
