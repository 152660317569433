import { Injector, Input, OnChanges, SimpleChanges, Directive } from '@angular/core';
import { AbstractBaseBlockUIComponent } from '@shared/abstracts/abstract-base-blockui.component';
import { BuyerDashboardService } from '@marketplace/marketplace-dashboard-core/service/buyer-dashboard.service';
import { SellerDashboardService } from '@marketplace/marketplace-dashboard-core/service/seller-dashboard.service';

@Directive()
export abstract class AbstractDashboardCoreComponent extends AbstractBaseBlockUIComponent implements OnChanges {

    noData = false;

    @Input() seriesName;

    @Input() userType;

    @Input() heading;

    @Input() orgIdentifer;
    showEmptyScreen = false;
    protected buyerDashboardService: BuyerDashboardService;
    protected sellerDashboardService: SellerDashboardService;

    constructor(
        protected injector: Injector,
    ) {
        super(injector);
        this.buyerDashboardService = injector.get(BuyerDashboardService);
        this.sellerDashboardService = injector.get(SellerDashboardService);
    }

    protected OnInit(): void {
        super.OnInit();
        this.OnDashboardInit();
    }

    protected OnDashboardInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        let isChange = false;
        if (changes) {
            const keys = ['orgIdentifer', 'userType'];
            keys.forEach(key => {
                if (changes[key]) {
                    if (!changes[key].firstChange) {
                        if (changes[key].previousValue !== changes[key].currentValue) {
                            isChange = true;
                            this[key] = changes[key].currentValue;
                        }
                    }
                }
            });
        }
        if (isChange) {
            this.OnChanges(changes);
        }
    }

    protected OnChanges(changes: SimpleChanges): void {
        this.OnInit();
    }

    protected countryState(element) {
        if (element.name && element.state) {
            return `${element.state}, ${element.name}`;
        } else if (element.name) {
            return element.name;
        }
        return element.state;
    }
}
