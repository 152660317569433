import { DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthBuyGuardService } from '@shared/auth/auth.buy.guard';
import { AuthPublicGuardService } from '@shared/auth/auth.public.service.guard';
import { AuthSecureGuardService } from '@shared/auth/auth.secure.guard';
import { AuthSellerOnlyGuardService } from '@shared/auth/auth.selleronly.guard';
import { AuthService } from '@shared/auth/auth.service';
import { AuthGuardService } from '@shared/auth/auth.service.guard';
import { DataProvider } from '@shared/provider/data. provider';
// tslint:disable-next-line:max-line-length
import { ActuatorService, BankAccountService, OrderService, PingService, ProductCategoryService, ProductService, ReadMasterDataService, SellerProductService, SellerReviewService, UserBecomeAsSellerService, UserCartService, UserProfileService, UserPublicRegistrationService, UserPublicService, UserService, UserWishlistService } from '@shared/services';
import { CompanyService } from '@shared/services/company.service';
import { CompanyTypeService } from '@shared/services/companyType.service';
import { PermissionsService } from '@shared/services/permissions.service';
import { ClientIPService } from '@shared/_services/client-ip.service';
import { GoogleTagManagerService } from '@shared/_services/google-tag-manager.service';
import { UploadFileService } from '../component/upload-form/upload-file.service';
import { CountryService } from '../services/country.service';
import { SharedModule } from '../shared.module';
import { AsyncJobService } from './async-job.service';
import { ConnectionService } from './ConnectionService.service';
import { PreventBackService } from './prevent-back.guard';
import { PreventBackGuard } from './prevent-back.service';
import { PreventNavigateCanDeactivateGuard } from './prevent-navigate-can-deactivate.guard';
import { ScriptService } from './script.service';
import { SWService } from './SWService.service';
import { CustomMetaTagService } from './custom.meta.tag.service';
import { CustomCanonicalTagService } from './custom.cannoincal.tag.service';

@NgModule({
  imports: [
    SharedModule
  ],
  providers: [
    ConnectionService,
    SWService,
    DataProvider,
    GoogleTagManagerService,
    ProductCategoryService,
    UserPublicService, UserService,
    UserProfileService, ProductService,
    SellerReviewService,
    PingService, OrderService,
    ActuatorService,
    UserWishlistService,
    ReadMasterDataService,
    CountryService,
    UserPublicRegistrationService,
    SellerProductService,
    UserCartService,
    BankAccountService,
    UserBecomeAsSellerService,
    AuthService,
    PermissionsService,
    AuthGuardService,
    AuthPublicGuardService,
    AuthSellerOnlyGuardService,
    AuthBuyGuardService,
    AuthSecureGuardService,
    UploadFileService,
    DatePipe,
    CompanyTypeService,
    AsyncJobService,
    CompanyService,
    PreventNavigateCanDeactivateGuard,
    PreventBackGuard,
    PreventBackService,
    ScriptService, ClientIPService, CustomMetaTagService, CustomCanonicalTagService,
    DecimalPipe
  ],
  declarations: [
  ],
  exports: [
  ]
})
export class SharedServiceModule { }
