import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class TimingInterceptor implements HttpInterceptor {
    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const started = Date.now();
        let status: string;
        return next
            .handle(req).pipe(
                tap(event => {
                    status = '';
                    if (event instanceof HttpResponse) {
                        status = 'succeeded';
                        const elapsed = Date.now() - started;
                        // tslint:disable-next-line:no-console
                        console.debug(`Request for ${req.urlWithParams} took ${elapsed} ms.`);
                    }
                }, error => { status = 'failed'; }),
                finalize(() => {
                    const elapsedTime = Date.now() - started;
                    const message = req.method + ' ' + req.urlWithParams + ' ' + status + ' in ' + elapsedTime + 'ms';
                    // tslint:disable-next-line:no-console
                    console.debug(message);
                }));
    }

}
