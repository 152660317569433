<div
  class="d-flex justify-content-around align-items-center flex-wrap flex-lg-nowrap text-white h-100 py-3"
>
  <div class="text-lg-left text-center col-12 col-lg-4">
    <h1 class="font32 fw-350 font-freightSans-pro">
      <b>Buy</b> containers and <b>sell</b>
      back to us.
    </h1>
    <p>
      Save time and money by purchasing new and used shipping containers from
      Boxxport.
      <br />
      <br />
      Utilize them for 90 days and then sell them back to us at a pre-agreed
      price.
    </p>
  </div>
  <div class="d-flex flex-column align-item-center col-12 col-lg-4">
    <img
      class="my-4 mx-auto"
      alt=""
      src="{{ assetPath }}/images/new-svg/buybackicon.svg"
      height="100px"
      width="100px"
    />
    <button class="btn btn-pink mb-2" routerLink="/signup">
      Register and start Selling
      <img
        alt=""
        class="pl-1"
        src="{{ assetPath }}/images/new-svg/rightpinkarrow.svg"
      />
    </button>
    <button
      class="btn btn-pink-outline"
      routerLink="/buyback-shipping-containers"
    >
      Learn More
    </button>
  </div>
</div>
