import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractBaseService } from "@shared/abstracts/abstract-base-service";

@Injectable({
  providedIn: "root",
})
export class PushNotificationService extends AbstractBaseService {
  constructor(private http: HttpClient) {
    super();
  }

  setNotificationRegistration(registration: any) {
    return this.http.post(
      `${this.apiBaseUrl}pushNotification/saveBrowserDetails`,
      registration
    );
  }
}
