import { Component, Injector, Input } from "@angular/core";
import { Router } from "@angular/router";
import { AbstractBaseBlockUIComponent } from "@shared/abstracts/abstract-base-blockui.component";
import { OrganizationDTO } from "@shared/dto/OrganizationDTO";
import { OrganizationService } from "@shared/services/organization.service";

@Component({
  selector: "marketplace-update-default-company-header",
  templateUrl: "./update-default-company-header.component.html",
  styles: [],
})
export class UpdateDefaultCompanyHeaderComponent extends AbstractBaseBlockUIComponent {
  router: Router;
  @Input() organizationDTO: OrganizationDTO;
  @Input() showRedirectButton = true;
  constructor(protected injector: Injector) {
    super(injector);
    this.router = injector.get(Router);
  }

  ngOnInit(): void {}
}
