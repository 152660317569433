import {
  Component,
  Injector,
  SimpleChanges,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { AbstractNotificationWidgetComponent } from "../abstract/abstract-notification-widget.component";
import { NotificationWidgetService } from "../service/notification-widget.service";
import { ErrorUtils } from "@shared/constant/error.utils";
import { AuthService } from "@shared/auth/auth.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { NegotiationNotificationDTO } from "../dto/NegotiationNotificationDTO";
import { NotificationCountModel } from "../dto/NotificationCountModel.dto";
import { NegotiationNotificationModalComponent } from "./negotiation-notification-modal/negotiation-notification-modal.component";

@Component({
  selector: "shared-notification-widget-count",
  templateUrl: "./notification-widget-count.component.html",
  styleUrls: ["./notification-widget-count.component.scss"],
})
export class NotificationWidgetCountComponent
  extends AbstractNotificationWidgetComponent
  implements OnDestroy
{
  @Output() closeAllDropdownsEvent = new EventEmitter();
  @Input() closeNotificationWidget: EventEmitter<any>;
  isDestroy = false;

  hideNotification = true;

  count = 0;

  lastUpdatedDateTime: Date;

  constructor(
    protected injector: Injector,
    protected authService: AuthService,
    protected notificationWidgetService: NotificationWidgetService,
    protected modalService: BsModalService
  ) {
    super(injector);
  }

  OnInit() {
    this.closeNotificationWidget.subscribe(() => {
      this.hideNotification = true;
    });
    this.OnChatWidgetInit();
  }

  protected OnChatWidgetInit() {
    super.OnChatWidgetInit();
    this.status = "READ,UNREAD";
    this.handlerCount();
    this.subscriptions.push(
      this.notificationWidgetService.updateNotificationEvent.subscribe(
        (types: any) => {
          console.log(types);
          let count = this.count;
          types.forEach((type) => {
            if (type === "UNREAD") {
              count++;
            } else {
              count--;
            }
          });
          this.count = Math.max(count, 0);
        }
      ),
      this.notificationWidgetService.updateNotificationCountEvent.subscribe(
        () => {
          this.updateCount(undefined);
        }
      )
    );
  }

  private updateCount(onSuccess) {
    this.notificationWidgetService
      .count(this.userType, this.status, {
        lastUpdatedDateTime: this.lastUpdatedDateTime,
      })
      .subscribe((data) => {
        this.lastUpdatedDateTime = data.result.lastUpdatedDateTime;
        this.count = data.result.unread;
        this.handleResponsePopup(data.result);
        if (onSuccess) {
          onSuccess();
        }
      }, ErrorUtils.logError);
  }

  private handlerCount() {
    if (this.authService.isUserLoggedIn()) {
      const onSuccess = () => {
        if (!this.isDestroy) {
          setTimeout(() => {
            this.handlerCount();
          }, 20000);
        }
      };
      if (this.hideNotification) {
        if (navigator && navigator.onLine) {
          this.updateCount(onSuccess);
        } else {
          console.log("Currently ofline mode...");
          onSuccess();
        }
      } else {
        console.log("Notification already open...");
        onSuccess();
      }
    }
  }

  handleResponsePopup(notificationCountModel: NotificationCountModel) {
    if (
      notificationCountModel.notificationObjectList != null &&
      notificationCountModel.notificationObjectList !== undefined
    ) {
      notificationCountModel.notificationObjectList.forEach(
        (notificationDetails) => {
          const negotiationNotification =
            notificationDetails as NegotiationNotificationDTO;
          if (
            negotiationNotification.updateForBuyer ||
            negotiationNotification.updateForSeller
          ) {
            const calendlyPopup =
              document.getElementsByClassName("calendly-popup").length === 0;
            const newsLetterPopup =
              document.getElementsByClassName("sib-form").length === 0;
            const anyModalPopup =
              document.getElementsByClassName("modal-body").length === 0;
            if (calendlyPopup && newsLetterPopup && anyModalPopup) {
              this.handleNegotiationUpdate(negotiationNotification);
            }
          }
        }
      );
    }
  }

  handleNegotiationUpdate(negotiationNotification: NegotiationNotificationDTO) {
    const initialState = {
      negotiationNotification,
    };
    this.modalService.show(NegotiationNotificationModalComponent, {
      initialState,
    } as any);
  }

  protected OnChanges(changes: SimpleChanges): void {
    super.OnChanges(changes);
    this.OnChatWidgetInit();
  }

  hideEvent($event) {
    this.hideNotification = $event;
  }

  onClickedOutside(e: Event) {
    this.hideNotification = true;
    console.log("Clicked outside:", e);
  }
  bellIconClick() {
    this.hideNotification = !this.hideNotification;
    this.closeAllDropdownsEvent?.emit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.isDestroy = true;
  }
}
