import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';

import { BaseResponseDTO } from '@shared/dto/BaseResponseDTO';
@Injectable({ providedIn: 'root' })
export class UserCartService {


    constructor(private http: HttpClient) { }

    getUserCartGet(object: any) {
        return this.http.get<BaseResponseDTO<any[]>>('/api/users/carts/', object).pipe(first());
    }

    updateToCartPut(object: any) {
        return this.http.put<BaseResponseDTO<any>>('/api/users/carts/{id}/quantity/{quantity}', object).pipe(first());
    }

    getUserCartCountGet(object: any) {
        return this.http.get<BaseResponseDTO<number>>('/api/users/carts/count', object).pipe(first());
    }

    removeFromCartDelete(object: any) {
        return this.http.delete<BaseResponseDTO<any>>('/api/users/carts/{id}', object).pipe(first());
    }

    addProductToCartPost(object: any, queryParams) {
        return this.http.post<BaseResponseDTO<any>>('/api/users/carts/{productId}/quantity/{quantity}', object).pipe(first());
    }

    // ##Replace
}
