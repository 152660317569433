import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap, Params, Router } from "@angular/router";
import { ContainerDetailsBuyerData } from "@marketplace/dto/ContainerDetailsBuyer.data";
import { ProductSearchUtils } from "@marketplace/utils/ProductSearch.utils";
import { NgSelectComponent } from "@ng-select/ng-select";
import { ErrorUtils } from "@shared/constant/error.utils";
import { LocaleUtils } from "@shared/constant/shared.localeUtils";
import { Utils } from "@shared/constant/shared.utils";
import {
  MapLoaderOption,
  MapLoaderService,
} from "@shared/map/map-loader.service";
import { ReadMasterDataService } from "@shared/services";
import { CountryService } from "@shared/services/country.service";
import { GeoTaggedLocationDTO } from "@shared/dto/GeoTaggedLocation";
import { Meta } from "@angular/platform-browser";
import { CustomMetaTagService } from "@shared/_services/custom.meta.tag.service";
import { AbstractBaseComponent } from "@shared/abstracts/abstract-base.component";
import { MapUtilsService } from "../map-utils/map-utils.service";

@Component({
  selector: "marketplace-find-best-container-prices",
  templateUrl: "./marketplace-find-best-container-prices.component.html",
  styleUrls: ["./marketplace-find-best-container-prices.component.scss"],
})
export class MarketplaceFindBestContainerPricesComponent
  extends AbstractBaseComponent
  implements OnInit, AfterViewInit
{
  filterParams: ParamMap;

  @ViewChild("mapLocation") mapLocation: ElementRef;
  // @ViewChild("exportCountry") exportCountry: NgSelectComponent;

  @Input() heading: string;
  @Input() updateMetaData = true;
  @Input() landingpage: string;
  containerDetailsBuyerData: ContainerDetailsBuyerData;
  geoTaggedLocations: GeoTaggedLocationDTO[];
  constructor(
    private activatedRoute: ActivatedRoute,
    private mapLoaderService: MapLoaderService,
    private router: Router,
    private countryService: CountryService,
    private readMasterDataService: ReadMasterDataService,
    private metaService: CustomMetaTagService,
    private mapUtils: MapUtilsService
  ) {
    super();
  }

  ngOnInit() {
    // if (!this.heading) {
    //   this.heading = "Buy Shipping containers worldwide at best prices";
    // }
    const defaultValues = new Map();
    defaultValues.set("radius", 100);
    this.filterParams = this.activatedRoute.snapshot.queryParamMap;
    this.containerDetailsBuyerData = new ContainerDetailsBuyerData(
      this.readMasterDataService,
      this.countryService,
      this.filterParams,
      defaultValues
    );
    if (!this.landingpage) {
      this.landingpage = "Worldwide";
    }
    this.landingpage = this.landingpage;
    if (this.updateMetaData) {
      this.metaService.updateTag({
        name: "description",
        content:
          "Buy new or used shipping containers worldwide at the best prices. You can also sell your own products taking full control of your trading. Visit us!",
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.shouldAddressAutoCompleteWork()) {
      const nativeElement = this.mapLocation.nativeElement;
      this.mapLoaderService.mapLoader({
        nativeElement: nativeElement,
        defaultNullCheck: true,
        listenerCallback: (place: google.maps.places.PlaceResult) => {
          ProductSearchUtils.updateFormByPlace(
            this.containerDetailsBuyerData.filterForm,
            place
          );
        },
      } as MapLoaderOption);

      //custom made placeResult Component
      // this.mapUtils.initPlaceResults(
      //   this.mapLocation,
      //   (place: google.maps.places.PlaceResult) => {
      //     ProductSearchUtils.updateFormByPlace(
      //       this.containerDetailsBuyerData.filterForm,
      //       place
      //     );
      //   }
      // );
      console.log(nativeElement);
      this.mapLoaderService.getPlacePredictions(nativeElement);
      // setTimeout(() => {
      //   this.exportCountry.setDisabledState(true);
      // }, 1000);
    } else {
      // this.exportCountry.setDisabledState(true);
    }
  }
  // isExportChange($event) {
  //   const isTrue = Utils.isChecked($event);
  //   const exportCountry =
  //     this.containerDetailsBuyerData.filterForm.controls.exportCountry;
  //   if (isTrue) {
  //     exportCountry.setValidators([Validators.required]);
  //   } else {
  //     exportCountry.clearValidators();
  //     exportCountry.setValue(undefined);
  //   }
  //   exportCountry.updateValueAndValidity();
  //   this.exportCountry.writeValue(undefined);
  //   this.exportCountry.setDisabledState(!isTrue);
  // }

  gotoSerach() {
    const filterForm = this.containerDetailsBuyerData.filterForm;
    Utils.markFormGroupTouched(filterForm);
    if (!this.shouldAddressAutoCompleteWork()) {
      const selectedCity =
        this.containerDetailsBuyerData.filterForm.controls.city.value;
      if (this.geoTaggedLocations) {
        const geoTaggedLocationDTOs = this.geoTaggedLocations.filter(
          (city) => city.city === selectedCity
        );
        if (geoTaggedLocationDTOs && geoTaggedLocationDTOs.length > 0) {
          this.containerDetailsBuyerData.filterForm.controls.countryShortName.setValue(
            geoTaggedLocationDTOs[0].iso2
          );
          this.containerDetailsBuyerData.filterForm.controls.longitude.setValue(
            geoTaggedLocationDTOs[0].longitude
          );
          this.containerDetailsBuyerData.filterForm.controls.latitude.setValue(
            geoTaggedLocationDTOs[0].latitude
          );
          this.containerDetailsBuyerData.filterForm.controls.location.setValue(
            geoTaggedLocationDTOs[0].city +
              "," +
              geoTaggedLocationDTOs[0].country
          );
        }
      }
    }
    if (filterForm.valid && filterForm.dirty) {
      const queryParams: Params =
        this.containerDetailsBuyerData.filterForm.getRawValue();
      this.router.navigate(["/buyer/product/listing"], {
        queryParams: queryParams,
        queryParamsHandling: "merge",
      });
    }
  }

  getRadiusVal() {
    return this.containerDetailsBuyerData.filterForm.controls.radius.value;
  }
  setRadiusVal(value) {
    console.log(value);
    this.containerDetailsBuyerData.filterForm.controls.radius.setValue(value);
  }
  shouldAddressAutoCompleteWork() {
    return LocaleUtils.shouldAddressAutoCompleteWork();
  }
  fetchAndPopulateCityData() {
    this.containerDetailsBuyerData.filterForm.controls.city.markAsUntouched();
    this.containerDetailsBuyerData.filterForm.controls.city.setValue(undefined);
    const selectedCountry =
      this.containerDetailsBuyerData.filterForm.controls.country.value;
    const countrydtos =
      this.containerDetailsBuyerData.countryWrapperDTO.countryData.filter(
        (countryDto) => countryDto.countryName === selectedCountry
      );
    this.geoTaggedLocations = [];
    if (countrydtos.length > 0) {
      this.countryService
        .fetchGeoTaggedLocations(countrydtos[0].iso2)
        .subscribe((cityData) => {
          this.geoTaggedLocations = cityData.result;
        }, ErrorUtils.logError);
    }
  }

  countrySearchFN = (term: string, item: any) => {
    term = term?.toLocaleLowerCase();
    const countrydtos =
      this.containerDetailsBuyerData?.countryWrapperDTO?.countryData?.filter(
        (countryDto) => countryDto?.countryName === item?.key
      );
    return (
      countrydtos[0]?.countryName?.toLocaleLowerCase().indexOf(term) > -1 ||
      countrydtos[0]?.iso?.toLocaleLowerCase().indexOf(term) > -1 ||
      countrydtos[0]?.iso2?.toLocaleLowerCase().indexOf(term) > -1
    );
  };
}
