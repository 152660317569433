import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractBaseService } from '@shared/abstracts/abstract-base-service';
import { Utils } from '@shared/constant/shared.utils';
import { BaseResponseDTO } from '@shared/dto';
import { AdminProductBulkUploadDTO } from '@shared/dto/AdminProductBulkUploadDTO';
import { JobStatusDTO } from '@shared/dto/JobStatusDTO';
import { finalize } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AsyncJobService extends AbstractBaseService {

    constructor(private http: HttpClient) { super(); }

    private url(jobType: string) {
        return `${this.apiBaseUrl}async/job/${jobType}/`;
    }

    submitJob(documentIdentifier: string, jobType: string, bulkDTO: AdminProductBulkUploadDTO | {}) {
        return this.http.post<BaseResponseDTO<JobStatusDTO>>(`${this.url(jobType)}${documentIdentifier}`, bulkDTO);
    }

    updateJob(jobIdentifier: string, jobType: string, body: any) {
        return this.http.post<BaseResponseDTO<JobStatusDTO>>(`${this.url(jobType)}${jobIdentifier}/patch`, body);
    }

    statusById(id, jobType: string) {
        return this.http.get<BaseResponseDTO<JobStatusDTO>>(`${this.url(jobType)}${id}/status/0`);
    }
    status(uri: string) {
        return this.http.get<BaseResponseDTO<JobStatusDTO>>(`${uri.replace('https://localhost:5000', '').replace('/async', '/api/async')}`);
    }

    checkStatusById(id, jobType, callbackSuccess, callbackError) {
        setTimeout(() => {
            this.statusById(id, jobType)
                .pipe(finalize(() => {
                }))
                .subscribe(statusData => {
                    this.resolveStatusData(statusData.result, callbackSuccess, callbackError);
                });
        }, 1000);
    }

    checkStatus(uri, callbackSuccess, callbackError) {
        console.log(`Check status for job ${uri}`);
        setTimeout(() => {
            this.status(uri)
                .subscribe(statusData => {
                    this.resolveStatusData(statusData.result, callbackSuccess, callbackError);
                });
        }, 1000);
    }
    private resolveStatusData(result: JobStatusDTO, callbackSuccess, callbackError) {
        const status = result.status;
        if (status === 'SUCCESS') {
            callbackSuccess(result);
        } else if (status === 'FAIL') {
            callbackError(result);
        } else {
            const uri = result.uri;
            const count = Utils.parseInt(uri.substring(uri.lastIndexOf('/') + 1), 0);
            if (count > 30) {
                window.location.reload();
            } else {
                const time = result.retryInterval || 1000;
                setTimeout(() => {
                    this.checkStatus(uri, callbackSuccess, callbackError);
                }, time);
            }
        }
    }
}
