import { Injector, Directive } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AbstractModalComponent } from './abstract-modal.component';
import { Subject } from 'rxjs';


@Directive()
export abstract class AbstractNavigationModalComponent extends AbstractModalComponent {

    modalErrorMsg: string;

    callbackFunc: (bsModalRef: BsModalRef, object: any) => void;

    constructor(
        public bsModalRef: BsModalRef,
        protected injector: Injector,
    ) {
        super(bsModalRef, injector);
    }

    subject: Subject<boolean>;

    OnModalInit() {
        super.OnModalInit();
    }


    yesClicked() {
        super.save({});
        if (this.subject) {
            this.subject.next(true);
            this.subject.complete();
        }
    }

    close() {
        this.bsModalRef.hide();
        if (this.subject) {
            this.subject.next(false);
            this.subject.complete();
        }
    }
}
