import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractBaseService } from '@shared/abstracts/abstract-base-service';
import { BaseResponseDTO } from '@shared/dto/BaseResponseDTO';
import { CommentDTO } from '@shared/dto/review/CommentDTO';
import { ReplyDTO } from '@shared/dto/review/ReplyDTO';
import { ReviewType } from '@shared/enums/ReviewType';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SellerReviewService extends AbstractBaseService {

    constructor(private http: HttpClient) { super(); }

    private reviewBaseUrl(reviewType: ReviewType, identifier: string | number) {
        return `${this.apiBaseUrl}review/comment/${reviewType}/${identifier}/`;
    }
    private reviewReplyBaseUrl(reviewType: ReviewType, commentIdentifier: string | number) {
        return `${this.apiBaseUrl}review/comment/reply/${reviewType}/${commentIdentifier}/`;
    }

    reviewComment(reviewType: ReviewType, identifier: string | number, page: number) {
        const params = new HttpParams().set('page', page + '');
        return this.http.get<BaseResponseDTO<CommentDTO[]>>(`${this.reviewBaseUrl(reviewType, identifier)}`, { params }).pipe(first());
    }

    reviewCommentCount(reviewType: ReviewType, identifier: string | number) {
        return this.http.get<BaseResponseDTO<number>>(`${this.reviewBaseUrl(reviewType, identifier)}count`).pipe(first());
    }

    reviewCommentPost(reviewType: ReviewType, identifier: string | number, orderIdentifier: string | number, body: object) {
        return this.http.post<BaseResponseDTO<any>>(`${this.reviewBaseUrl(reviewType, identifier)}${orderIdentifier}`, body).pipe(first());
    }

    reviewCommentReply(reviewType: ReviewType, commentIdentifier: string | number, page: number) {
        const params = new HttpParams().set('page', page + '');
        return this.http.get<BaseResponseDTO<ReplyDTO[]>>(`${this.reviewReplyBaseUrl(reviewType, commentIdentifier)}`, { params }).pipe(first());
    }

    reviewCommentReplyCount(reviewType: ReviewType, commentIdentifier: string | number) {
        return this.http.get<BaseResponseDTO<number>>(`${this.reviewReplyBaseUrl(reviewType, commentIdentifier)}count`).pipe(first());
    }

    reviewCommentRepliesCount(reviewType: ReviewType, commentIdentifiers: string[] | number[]) {
        return this.http.post<BaseResponseDTO<object>>(`${this.reviewReplyBaseUrl(reviewType, 'ALL')}count`, { data: commentIdentifiers }).pipe(first());
    }

    reviewCommentReplyPost(reviewType: ReviewType, commentIdentifier: string | number, identifier: string | number, orderIdentifier: string | number, body: object) {
        return this.http.post<BaseResponseDTO<any>>(`${this.reviewReplyBaseUrl(reviewType, commentIdentifier)}${identifier}/${orderIdentifier}`, body).pipe(first());
    }

}
