import { BaseDTO } from '@shared/dto/BaseDTO';
import { MasterDataType } from '@shared/enums/MasterDataType';
import { ReadMasterDataService } from '@shared/services';
import { Subscription } from 'rxjs';
import { ContainerGradeDTO } from '@shared/dto/ContainerGradeDTO';
import { ContainerTypeDTO } from '@shared/dto/ContainerTypeDTO';

export class ContainerDetailsWrapperMinData extends BaseDTO {

    containerGradeData: ContainerGradeDTO[] = [];

    containerTypeData: ContainerTypeDTO[] = [];

    responseArrPromise: Subscription[] = [];

    constructor(readMasterDataService: ReadMasterDataService) {
        super();
        if (readMasterDataService) {
            this.responseArrPromise.push(
                readMasterDataService
                    .findDataAll(MasterDataType.ContainerGrade)
                    .subscribe(data => {
                        this.containerGradeData = data.result;

                    })
            );
            this.responseArrPromise.push(
                readMasterDataService
                    .findDataAll(MasterDataType.ContainerType)
                    .subscribe(data => {
                        this.containerTypeData = data.result;
                    })
            );
        }
    }

}
