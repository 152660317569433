<div class="container faqs">
    <h2 class="font36">Frequently asked Questions</h2>
    <div class="row">
        <div class="col-md-10 offset-md-1">
            <a href="https://boxxport.zendesk.com/hc/en-us/search?query=start" target="_blank">How do I become a Buyer?</a>
            <a href="https://boxxport.zendesk.com/hc/en-us/search?query=start" target="_blank">How do I become a Seller?</a>
             <a href="https://boxxport.zendesk.com/hc/en-us/search?query=start" target="_blank">I can’t find the container I am looking for </a>
             <a href="https://boxxport.zendesk.com/hc/en-us/search?query=start" target="_blank">How do I upload my first stocks? </a>
        </div>
    </div>
</div>