import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SellerProductListingAbstractComponent } from '@marketplace/seller/product/seller-product-listing/abstract/seller-product-listing-abstract.component';
import { OrganizationDTO } from '@shared/dto/OrganizationDTO';
import { OrganizationService } from '@shared/services/organization.service';

@Component({
  selector: 'seller-listing-update-home',
  templateUrl: './seller-listing-update-home.component.html',
  styleUrls: ['./seller-listing-update-home.component.scss']
})
export class SellerListingUpdateHomeComponent extends SellerProductListingAbstractComponent {


  organizationDTO: OrganizationDTO;
  organizationIdentifier = undefined;
  fromDifferentOrganization = false;
  productListingLink = '/seller/product/listing';
  constructor(
    protected injector: Injector,
    private route: ActivatedRoute,
    private organizationService: OrganizationService
  ) {
    super(injector);
    this.selectedTab = 'ACTIVE';
    this.selectedTabType = 'UPDATE';
    this.sortingObject = {
      name: 'LIVE_DATE',
      isAsc: true
    };
  }
  ngOnInit() {
    super.ngOnInit();
  }
  OnInit() {
    this.route.params.subscribe(params => {
      this.organizationIdentifier = params['orgIdentifier'];
      this.organizationService.getOrganizationDetails(this.organizationIdentifier).subscribe(data1 => {
        if (data1.result) {
          this.organizationService.getCurrentUserOrgnization().subscribe(data => {
            if (data.result) {
              if (data.result.identifier !== this.organizationIdentifier) {
                this.fromDifferentOrganization = true;
              }
              this.organizationDTO = data1.result;
            }
          });
        }
      });
    });

    super.OnInit();
  }
  gotoCompanyDetail() {
    this.router.navigate(['/profile/org/listing'], { queryParams: { from: this.router.url } });
  }

}
