export const menus = [
  {
    name: "Organization",
    icon: "dashboard",
    id: "organization",
    link: false,
    open: false,
    sub: [
      {
        name: "Organization List",
        link: "/organization/seller/AllOrganization",
        icon: "list",
        id: "list",
        chip: false,
        open: true,
      },
      {
        name: "Search",
        link: "/organization/search",
        icon: "search",
        id: "search",
        chip: false,
        open: true,
      },
    ],
  },
  {
    name: "User",
    icon: "dashboard",
    id: "user",
    link: false,
    open: false,
    sub: [
      {
        name: "Listing",
        link: "/user/home/listing",
        icon: "list",
        id: "list",
        chip: false,
        open: true,
      },
      {
        name: "Search",
        link: "/user/home/search",
        icon: "search",
        id: "search",
        chip: false,
        open: true,
      },
    ],
  },

  /* , {
         'name': 'Seller',
         'icon': 'dashboard',
         'link': false,
         'open': false,
         'sub': [
             {
                 'name': 'Listing',
                 'link': '/user/seller/listing',
                 'icon': 'list',
                 'chip': false,
                 'open': true,
             }, {
                 'name': 'Search',
                 'link': '/user/seller/search',
                 'icon': 'search',
                 'chip': false,
                 'open': true,
             }
         ]
     }
    , {
        'name': 'Seller Review',
        'icon': 'dashboard',
        'link': false,
        'open': false,
        'sub': [
            {
                'name': 'Listing',
                'link': '/sellerReviews/listing',
                'icon': 'list',
                'chip': false,
                'open': true,
            }, {
                'name': 'Search',
                'link': '/sellerReviews/search',
                'icon': 'search',
                'chip': false,
                'open': true,
            }
        ]
    }*/ {
    name: "Products",
    icon: "dashboard",
    link: false,
    hiddenFor: ["ROLE_SALES_ADMIN"],
    id: "product",
    open: false,
    sub: [
      {
        name: "Listing",
        link: "/product/listing",
        icon: "list",
        id: "list",
        chip: false,
        open: true,
      },
      {
        name: "Search",
        link: "/product/search",
        icon: "search",
        id: "search",
        chip: false,
        open: true,
      },
      {
        name: "Depot Listing",
        link: "/product/depot/listing",
        icon: "list",
        id: "depotlist",
        chip: false,
        open: true,
      },
      {
        name: "Depot Search",
        link: "/product/depot/search",
        icon: "search",
        id: "depotsearch",
        chip: false,
        open: true,
      },
      {
        name: "Full Service Listing",
        link: "/product/fullService/listing",
        icon: "list",
        id: "fullservicelist",
        chip: false,
        open: true,
      },
      {
        name: "Full Service Search",
        link: "/product/fullService/search",
        icon: "search",
        id: "fullservicesearch",
        chip: false,
        open: true,
      },
      {
        name: "Buyback Prices",
        link: "/product/buyback/prices",
        icon: "list",
        id: "buybackprices",
        chip: false,
        open: true,
      },
      {
        name: "Buyback Listing",
        link: "/product/buyback/listing",
        icon: "list",
        id: "buybackprices",
        chip: false,
        open: true,
      },
    ],
  },
  {
    name: "Negotiation",
    icon: "dashboard",
    link: false,
    id: "negotiation",
    open: false,
    sub: [
      {
        name: "Listing",
        link: "/negotiation/negotiate/activeNegotiation",
        icon: "list",
        id: "list",
        chip: false,
        open: true,
      },
      {
        name: "Search",
        link: "/negotiation/search",
        icon: "search",
        id: "search",
        chip: false,
        open: true,
      },
    ],
  },
  {
    name: "Container Radar",
    icon: "dashboard",
    id: "radar",
    link: false,
    open: false,
    sub: [
      {
        name: "Listing",
        link: "/containerRadar/radar/sellerRadar",
        icon: "list",
        id: "list",
        chip: false,
        open: true,
      },
      {
        name: "Search",
        link: "/containerRadar/search",
        icon: "search",
        id: "search",
        chip: false,
        open: true,
      },
    ],
  },
  {
    name: "Orders",
    icon: "dashboard",
    link: false,
    hiddenFor: ["ROLE_SALES_ADMIN"],
    id: "order",
    open: false,
    sub: [
      {
        name: "Listing",
        link: "/orders/listing",
        icon: "list",
        id: "list",
        chip: false,
        open: true,
      },
      {
        name: "Buyback Order Listing",
        link: "/orders/buyback/listing",
        icon: "list",
        id: "list",
        chip: false,
        open: true,
      },
      {
        name: "Search",
        link: "/orders/search",
        icon: "search",
        id: "search",
        chip: false,
        open: true,
      },
    ],
  },
  {
    name: "Marketplace",
    icon: "dashboard",
    link: false,
    id: "marketplace",
    hiddenFor: ["ROLE_SALES_ADMIN", "ROLE_ASSOCIATE_ADMIN"],
    open: false,
    sub: [
      {
        name: "System Configuration",
        link: "/marketplace/systemConfig",
        icon: "swap_vert",
        id: "systemconfig",
        chip: false,
        open: true,
      },
      {
        name: "Master Data",
        link: "/marketplace/masterData",
        icon: "swap_vert",
        id: "masterdata",
        chip: false,
        open: true,
      },
      {
        name: "Landing Page",
        link: "/marketplace/landingPage",
        icon: "swap_vert",
        id: "landingPage",
        chip: false,
        open: true,
      },
      {
        name: " AOB Landing Page",
        link: "/marketplace/aobLandingPage",
        icon: "swap_vert",
        id: "aobLandingPage",
        chip: false,
        open: true,
      },
      {
        name: "Landing Page Search",
        link: "/marketplace/searchLandingPage",
        icon: "search",
        id: "landingPageSearch",
        chip: false,
        open: true,
      },
      {
        name: "Teams Page",
        id: "id",
        link: "/marketplace/teamsPage",
        icon: "swap_vert",
        chip: false,
        open: true,
      },
      {
        name: "Meet Us Page",
        id: "id",
        link: "/marketplace/meetusPage",
        icon: "swap_vert",
        chip: false,
        open: true,
      },
      {
        name: "Boxxport Academy",
        id: "id",
        link: "/marketplace/academyPage",
        icon: "swap_vert",
        chip: false,
        open: true,
      },
      {
        name: "Boxx Buzz",
        id: "id",
        link: "/marketplace/academyEventPage",
        icon: "swap_vert",
        chip: false,
        open: true,
      },
      /* {
                'name': 'Tax',
                'link': '/marketplace/tax',
                'icon': 'swap_vert',
                'chip': false,
                'open': true,
            }*/
    ],
  },
  {
    name: "Role",
    icon: "dashboard",
    hide: true,
    id: "adminrole",
    hiddenFor: ["ROLE_SALES_ADMIN"],
    link: false,
    open: false,
    sub: [
      //     {
      //         'name': 'Cache',
      //         'link': '/other/cache',
      //         'icon': 'swap_vert',
      //         'chip': false,
      //         'open': true,
      //     },
      {
        name: "Email Template",
        link: "/other/emailTemplate",
        icon: "swap_vert",
        id: "emailTemplate",
        chip: false,
        hide: true,
        hiddenFor: [
          "ROLE_ADMIN",
          "ROLE_SALES_ADMIN",
          "ROLE_ASSOCIATE_ADMIN",
          "ROLE_SUPER_ADMIN",
        ],
        open: false,
      },
      {
        name: "Scheduler",
        link: "/other/scheduler",
        icon: "swap_vert",
        id: "scheduler",
        chip: false,
        hide: true,
        hiddenFor: [
          "ROLE_ADMIN",
          "ROLE_SALES_ADMIN",
          "ROLE_ASSOCIATE_ADMIN",
          "ROLE_SUPER_ADMIN",
        ],
        open: false,
      },
      {
        name: "Add Admin",
        link: "/other/addAdmin",
        icon: "swap_vert",
        id: "addadmin",
        chip: false,
        hide: true,
        hiddenFor: ["ROLE_ADMIN", "ROLE_SALES_ADMIN", "ROLE_ASSOCIATE_ADMIN"],
        open: false,
      },
      {
        name: "List Admin",
        link: "/other/listAdmin",
        icon: "list",
        id: "adminlist",
        chip: false,
        hide: true,
        hiddenFor: ["ROLE_ADMIN", "ROLE_SALES_ADMIN", "ROLE_ASSOCIATE_ADMIN"],
        open: false,
      },
      {
        name: "Search Admin",
        link: "/other/searchAdmin",
        icon: "search",
        id: "searchadmin",
        chip: false,
        hide: true,
        hiddenFor: ["ROLE_ADMIN", "ROLE_SALES_ADMIN", "ROLE_ASSOCIATE_ADMIN"],
        open: false,
      },
      {
        name: "Action Approval Status",
        link: "/other/adminActions",
        icon: "swap_vert",
        chip: false,
        open: false,
        hide: true,
        hiddenFor: ["ROLE_SALES_ADMIN"],
      },
      {
        name: "Action Status Search",
        link: "/other/searchAction",
        icon: "search",
        chip: false,
        open: false,
        hide: true,
        hiddenFor: ["ROLE_SALES_ADMIN"],
      },
      // , {
      //     'name': 'rolesMapping',
      //     'link': '/other/rolesMapping',
      //     'icon': 'swap_vert',
      //     'chip': false,
      //     'open': true,
      // }
    ],
  },
  {
    name: "Reports",
    icon: "dashboard",
    id: "user",
    link: false,
    open: false,
    sub: [
      {
        name: "Company Dashboard",
        link: "/reports/companyDashboard",
        icon: "list",
        id: "list",
        chip: false,
        // 'hiddenFor': ['ROLE_ADMIN', 'ROLE_SALES_ADMIN', 'ROLE_ASSOCIATE_ADMIN'],
        hide: true,
        open: true,
      },
      {
        name: "Buyer Dashboard",
        link: "/reports/buyerDashboard",
        icon: "list",
        id: "list",
        chip: false,
        // 'hiddenFor': ['ROLE_ADMIN', 'ROLE_SALES_ADMIN', 'ROLE_ASSOCIATE_ADMIN'],
        hide: true,
        open: true,
      },
      {
        name: "Seller Dashboard",
        link: "/reports/sellerDashboard",
        icon: "list",
        id: "list",
        chip: false,
        // 'hiddenFor': ['ROLE_ADMIN', 'ROLE_SALES_ADMIN', 'ROLE_ASSOCIATE_ADMIN'],
        hide: true,
        open: true,
      },
      {
        name: "Reg. User Dashboard",
        link: "/reports/registeredUserDashboard",
        icon: "list",
        id: "list",
        chip: false,
        // 'hiddenFor': ['ROLE_ADMIN', 'ROLE_SALES_ADMIN', 'ROLE_ASSOCIATE_ADMIN'],
        hide: true,
        open: true,
      },
      {
        name: "Buyer Retention Report",
        link: "/reports/buyerRetentionReport",
        icon: "list",
        id: "list",
        chip: false,
        // 'hiddenFor': ['ROLE_ADMIN', 'ROLE_SALES_ADMIN', 'ROLE_ASSOCIATE_ADMIN'],
        hide: true,
        open: true,
      },
      {
        name: "GA Search Report",
        link: "/reports/gaSearchReport",
        icon: "list",
        id: "list",
        chip: false,
        // 'hiddenFor': ['ROLE_ADMIN', 'ROLE_SALES_ADMIN', 'ROLE_ASSOCIATE_ADMIN'],
        hide: true,
        open: true,
      },
      {
        name: "GA Search/Buyer Report",
        link: "/reports/gaSearchBuyerReport",
        icon: "list",
        id: "list",
        chip: false,
        // 'hiddenFor': ['ROLE_ADMIN', 'ROLE_SALES_ADMIN', 'ROLE_ASSOCIATE_ADMIN'],
        hide: true,
        open: true,
      },
      {
        name: "Pricing Report Location",
        link: "/reports/pricingReportByLocation",
        icon: "list",
        id: "list",
        chip: false,
        // 'hiddenFor': ['ROLE_ADMIN', 'ROLE_SALES_ADMIN', 'ROLE_ASSOCIATE_ADMIN'],
        hide: true,
        open: true,
      },
      {
        name: "Pricing Report Region",
        link: "/reports/pricingReportByRegion",
        icon: "list",
        id: "list",
        chip: false,
        // 'hiddenFor': ['ROLE_ADMIN', 'ROLE_SALES_ADMIN', 'ROLE_ASSOCIATE_ADMIN'],
        hide: true,
        open: true,
      },
      {
        name: "Price Comparison Report",
        link: "/reports/priceComparisonReport",
        icon: "list",
        id: "list",
        chip: false,
        // 'hiddenFor': ['ROLE_ADMIN', 'ROLE_SALES_ADMIN', 'ROLE_ASSOCIATE_ADMIN'],
        hide: true,
        open: true,
      },
    ],
  },
  {
    name: "Boxxport Offers",
    icon: "dashboard",
    id: "user",
    link: false,
    open: false,
    sub: [
      {
        name: "Offers Listing",
        link: "/bossOoxxportOffers",
        icon: "list",
        id: "list",
        chip: false,
        // 'hiddenFor': ['ROLE_ADMIN', 'ROLE_SALES_ADMIN', 'ROLE_ASSOCIATE_ADMIN'],
        hide: true,
        open: true,
      },
    ],
  },
  {
    name: "E-Wallet",
    icon: "dashboard",
    id: "user",
    link: false,
    open: false,
    sub: [
      {
        name: "Wallet",
        link: "/wallet/loadWallet",
        icon: "list",
        id: "list",
        chip: false,
        // 'hiddenFor': ['ROLE_ADMIN', 'ROLE_SALES_ADMIN', 'ROLE_ASSOCIATE_ADMIN'],
        hide: true,
        open: true,
      },
    ],
  },
];
