import { FormGroup } from '@angular/forms';
import { ParamMap } from '@angular/router';
import { ContainerDetailsWrapperMinData } from '@marketplace/seller/product/seller-product-listing/seller-product-add-type/dto/ContainerDetailsWrapperMin.data';
import { ProductSearchUtils } from '@marketplace/utils/ProductSearch.utils';
import { ErrorUtils } from '@shared/constant/error.utils';
import { CountryWrapperDTO } from '@shared/dto/CountryWrapperDTO';
import { ReadMasterDataService } from '@shared/services';
import { CountryService } from '@shared/services/country.service';

export class ContainerDetailsBuyerData extends ContainerDetailsWrapperMinData {


    filterForm: FormGroup;
    countryWrapperDTO: CountryWrapperDTO;
    countryForSearchWrapper: CountryWrapperDTO;
    constructor(readMasterDataService: ReadMasterDataService, countryService: CountryService, filterParams: ParamMap, defaultValues) {
        super(readMasterDataService);
        this.filterForm = ProductSearchUtils.searchForm(filterParams, defaultValues);
        this.responseArrPromise.push(
            countryService.country()
                .subscribe(countryWrapperDTOData => {
                    this.countryWrapperDTO = new CountryWrapperDTO(countryWrapperDTOData.result);
                }, ErrorUtils.logError)
        );
        this.responseArrPromise.push(
            countryService.countryForSearch()
                .subscribe(countryForSearchWrapperData => {
                    this.countryForSearchWrapper = new CountryWrapperDTO(countryForSearchWrapperData.result);
                }, ErrorUtils.logError)
        );
    }
}
