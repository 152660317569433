<div *ngIf="landingPageDTO">
    <div class="mainBg">
        <img src="{{landingPageDTO.imageUrl}}" alt="">
    </div>
    <article class="article first py-0">
        <div
          class="d-flex justify-content-center align-items-center flex-column px-3 h-100 py-3 py-lg-3"
        >
          <h2 class="text-white bold">What are you doing today?</h2>
          <div class="container px-0">
            <div
              class="w-100 welcome-features p-2"
              [ngClass]="searchStrategy == 'default' ? 'bg-buy' : 'bg-spot'"
            >
              <div
                class="h-100 d-flex flex-column flex-nowrap w-100 position-relative"
              >
                <div
                  class="row rounded-toggle w-100 m-0 p-2 bg-blue-lighter text-center"
                  (click)="isNavOpen = !isNavOpen"
                  [ngClass]="{ active: isNavOpen == true }"
                >
                  <div
                    *ngFor="let toggle of toggleButtons"
                    class="col-lg-3 col-sm-12"
                    [ngClass]="{ active: searchStrategy == toggle.key }"
                    (click)="searchStrategy = toggle.key"
                  >
                    {{ toggle.name }}
                  </div>
                </div>
                <div class="toggle-placeholder"></div>
      
                <div class="flex-grow-1 mt-2">
                  <marketplace-find-best-container-prices
                    [landingpage]="landingpage"
                    *ngIf="searchStrategy == 'default'"
                  ></marketplace-find-best-container-prices>
                  <marketplace-sell-container
                    [isLoggedIn]="isLoggedIn"
                    [isSeller]="isSeller"
                    *ngIf="searchStrategy == 'selling'"
                  >
                  </marketplace-sell-container>
                  <marketplace-buyback-search
                    [isLoggedIn]="isLoggedIn"
                    *ngIf="searchStrategy == 'buyback'"
                  ></marketplace-buyback-search>
                  <marketplace-radar
                    [isLoggedIn]="isLoggedIn"
                    [isSeller]="isSeller"
                    *ngIf="searchStrategy == 'radar'"
                  ></marketplace-radar>
                </div>
              </div>
            </div>
          </div>
          <div
            class="text-blue-light px-2 px-lg-5 py-2 bottom position-absolute bg-blue-lightest-2"
          >
            Want to <span class="bold">know more</span> about BOXXPORT?
            <img
              class="pl-1"
              src="{{ assetPath }}/images/new-svg/filleddownarrow.svg"
            />
          </div>
        </div>
      </article>
    <article class="article infoSection" *ngFor="let section of landingPageDTO.sections; let index = index;">
        <landing-page-info-section [sectionDetail]="section"></landing-page-info-section>
    </article>
    <article class="article darkbg whyus">
        <landing-page-why-boxxport></landing-page-why-boxxport>
    </article>
    <article class="article news" *ngIf="newsCategoryId" style="padding: 0px;">
        <landing-page-latest-posts [isNewsSections]="true" [newsCategoryId]="newsCategoryId">
        </landing-page-latest-posts>
    </article>
    <article class="article blogs" *ngIf="newsCategoryId" style="padding: 0px;">
        <landing-page-latest-posts [newsCategoryId]="newsCategoryId">
        </landing-page-latest-posts>
    </article>
    <article class="article darkbg faqs">
        <landing-page-faqs></landing-page-faqs>
    </article>
    <article class="article">
        <landing-page-testemonials></landing-page-testemonials>
    </article>
</div> 