import { Injector, OnDestroy, Directive } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  RouteConfigLoadEnd,
  Router,
} from "@angular/router";
import { ConstantService } from "@shared/constant/shared.constant";
import { Utils } from "@shared/constant/shared.utils";
import { TranslateService } from "@ngx-translate/core";
import { GoogleTagManagerService } from "@shared/_services/google-tag-manager.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Subscription } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { LocationStrategy } from "@angular/common";
import { PreventBackService } from "@shared/_services/prevent-back.guard";
// import { parser } from 'ua-parser-js';

@Directive()
export abstract class AbstractAppComponent implements OnDestroy {
  private googleTagManagerService: GoogleTagManagerService;

  protected router: Router;

  private title: Title;

  private meta: Meta;

  private translate: TranslateService;

  protected subs: Array<Subscription> = [];

  @BlockUI() blockUI: NgBlockUI;

  pageId: number;

  route: ActivatedRoute;

  constructor(injector: Injector) {
    // console.log('userAgent', parser(navigator.userAgent));
    this.googleTagManagerService = injector.get(GoogleTagManagerService);
    this.router = injector.get(Router);
    this.title = injector.get(Title);
    this.meta = injector.get(Meta);
    this.translate = injector.get(TranslateService);
    this.route = injector.get(ActivatedRoute);
    const preventBackService: PreventBackService =
      injector.get(PreventBackService);
    const location: LocationStrategy = injector.get(LocationStrategy);
    location.onPopState(() => {
      // set isBackButtonClicked to true.
      preventBackService.setBackClicked(true);
      return false;
    });
    this.appendGaTrackingCode();
    this.appendPardotTrackingCode();
    // this.appendFacebookTrackingCode();
    // this.appendLinkedinTrackingCode();
    let i = 0;
    this.subs[i++] = this.router.events
      .pipe(
        tap((event) => {
          if (event instanceof RouteConfigLoadEnd) {
            console.log(event.route);
          }
        })
      )
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.pageId = Utils.hashCode(this.router.url);
        this.googleTagManagerService.pageEvent(event);
      });
    this.subs[i++] = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this.changeTitle(route.data);
        this.routerChangeEvent(route.data);
      });
    this.blockUI.start(); // Start blocking

    setTimeout(() => {
      this.blockUI.stop(); // Stop blocking
    }, 200);
  }
  routerChangeEvent(data) {
    console.log(data);
  }
  private appendGaTrackingCode() {
    try {
      const script1 = document.createElement("script");
      script1.innerHTML =
        `
            window.dataLayer = window.dataLayer || [];
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','` +
        ConstantService.get("googleAnalyticsKey") +
        `');
          `;  
      document.head.appendChild(script1);
      const noscript = document.createElement("noscript");
      noscript.innerHTML =
        `
                <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=` +
        ConstantService.get("googleAnalyticsKey") +
        `"
                height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
            `;
      document.body.prepend(noscript);
    } catch (ex) {
      console.error("Error appending google tag manager");
      console.error(ex);
    }
  }

  private appendPardotTrackingCode() {
    try {
      const script1 = document.createElement("script");
      script1.type ="text/javascript";
      script1.innerHTML =
        `
        piAId = '1003521';
        piCId = '`+ConstantService.get("PARDOTCID") +`';
        piHostname = 'pi.pardot.com'; (function() {
            function async_load(){
                var s = document.createElement('script'); s.type = 'text/javascript';
                s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
                var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
            }
            if(window.attachEvent) { window.attachEvent('onload', async_load); }
            else { window.addEventListener('load', async_load, false); }
        })();
        `
      document.head.appendChild(script1);
      //console.log(ConstantService.get("PARDOTCID"));
      // document.body.prepend(noscript);
      console.log(script1);
    } catch (ex) {
      console.error("Error appending Pardot Tracker");
      console.error(ex);
    }
  }

  //   private appendFacebookTrackingCode() {
  //     const facbookKey = ConstantService.get("facebookMetaPixelKey");
  //     try {
  //       const script1 = document.createElement("script");
  //       script1.innerHTML = `
  //             !function(f,b,e,v,n,t,s)
  //     {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  //     n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  //     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  //     n.queue=[];t=b.createElement(e);t.async=!0;
  //     t.src=v;s=b.getElementsByTagName(e)[0];
  //     s.parentNode.insertBefore(t,s)}(window, document,'script',
  //     'https://connect.facebook.net/en_US/fbevents.js');
  //     fbq('init', '${facbookKey}');
  //     fbq('track', 'PageView');
  //           `;
  //       document.head.appendChild(script1);
  //       const noscript = document.createElement("noscript");
  //       noscript.innerHTML = `
  //         <noscript><img height="1" width="1" style="display:none"
  //         src="https://www.facebook.com/tr?id=${facbookKey}&ev=PageView&noscript=1"
  //         /></noscript>
  //             `;
  //       document.body.prepend(noscript);
  //     } catch (ex) {
  //       console.error("Error appending Facebook Meta Pixel");
  //       console.error(ex);
  //     }
  //   }
  //   private appendLinkedinTrackingCode() {
  //     const linkedinKey = ConstantService.get("linkedinInsightKey");
  //     const footer = document.getElementsByTagName("footer")[0];
  //     try {
  //       const script1 = document.createElement("script");
  //       script1.innerHTML = `
  //         _linkedin_partner_id = "${linkedinKey}";
  //         window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  //         window._linkedin_data_partner_ids.push(_linkedin_partner_id);
  //           `;
  //       document.head.appendChild(script1);
  //       const script2 = document.createElement("script");
  //       script2.innerHTML = `
  //       (function(l) {
  //         if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
  //         window.lintrk.q=[]}
  //         var s = document.getElementsByTagName("script")[0];
  //         var b = document.createElement("script");
  //         b.type = "text/javascript";b.async = true;
  //         b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
  //         s.parentNode.insertBefore(b, s);})(window.lintrk);
  //               `;
  //       document.head.appendChild(script2);
  //       const noscript = document.createElement("noscript");
  //       noscript.innerHTML = `
  //         <noscript>
  //         <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=${linkedinKey}&fmt=gif" />
  //         </noscript>
  //             `;
  //       document.body.prepend(noscript);
  //     } catch (ex) {
  //       console.error("Error appending Linkedin Insight");
  //       console.error(ex);
  //     }
  //   }
  ngOnInit() {}
  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  private changeTitle(data) {
    this.title.setTitle(this.getTitle(data));
  }

  protected getTitle(data) {
    let title = data && data.title;
    if (!title && this.router.url) {
      const url = this.router.url.split("?")[0];
      if (url) {
        const titleArr = url.split("/");
        title = this.calculateTitle(titleArr);
        if (!title) {
          title = this.calculateTitle(titleArr.reverse());
        }
      }
    }
    return title
      ? this.translate.instant(title)
      : this.translate.instant("Boxxport");
  }

  private calculateTitle(titleArr) {
    titleArr = titleArr.filter((el) => el && el !== "");
    if (titleArr) {
      const length = titleArr.length;
      for (let i = 0; i < length; i++) {
        const titleKey = "portal.title." + titleArr.join(".");
        const titleValue = this.translate.instant(titleKey);
        if (titleValue !== titleKey) {
          return titleValue;
        }
        titleArr.pop();
      }
    }
  }
}
