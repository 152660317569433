<div class="modal-body maxWidth" *blockUI="blockUIuuid">
  <div>
    <h1 class="marginB10">Your changes will not be saved and Listing will remain live with old price.</h1>
    <div class="marginT10 marginB10">
      <p>By navigating away from this page, your <strong>Listing Price will not be changed</strong></p>
    </div>
    <div class="row marginT20">
      <div class="col-sm-6 colsm">
        <button type="button" class="btn btn-primary btn-block" (click)="close();">Cancel</button>
      </div>
      <div class="col-sm-6 colsm text-left">
        <a class="btn bold paddingL30" href="javascript:void(0)" (click)="yesClicked();">Ok, discard changes</a>
      </div>
    </div>
  </div>
</div>
