<div class="mainContainer">
  <block-ui>
    <div id="wrapper" *blockUI="blockUIuuid">
      <div [hidden]="!isConnected" [class.isConnected]="!isConnected" [ngSwitch]="headerType" id="goToTop">
        <div *ngSwitchCase="'HEADER_NO'">
          <router-outlet></router-outlet>
        </div>
        <div *ngSwitchDefault class="smMinHeight">
          <marketplace-header [flow]="headerType"></marketplace-header>
          <marketplace-sidebar [flow]="headerType"></marketplace-sidebar>
        </div>
      </div>
    </div>
    <marketplace-footer [hidden]="!isConnected" [flow]="headerType"></marketplace-footer>
  </block-ui>
  <shared-cookie-consent [hidden]="!isConnected"></shared-cookie-consent>
</div>
