<cookie-law
  [position]="position"
  #cookieLaw
  (isSeen)="seen($event)"
  [name]="cookieLawName"
  [expiration]="expiration"
>
  <div #contentProjection>
    <ng-content></ng-content>
  </div>
  <div *ngIf="!hasContentProjection()">
    We use cookies and other technologies to customize your experience, perform
    analytics and deliver personalized advertising on our site and newsletter
    and across the Internet based on your interests. By clicking “I agree”
    below, you consent to the use by us and our third-party partners of cookies
    and data gathered from your use of our platforms. See our
    <a
      target="_blank"
      [routerLink]="['/privacy-policy']"
      fragment="cookiePolicy"
      routerLinkActive="router-link-active"
      class="bold"
      >Privacy Policy</a
    >
    to learn more about the use of data and your rights. You also agree to our
    <a
      target="_blank"
      [routerLink]="['/terms']"
      fragment="cookiePolicy"
      routerLinkActive="router-link-active"
      class="bold"
    >
      Terms and Conditions </a
    >.
    <div class="text-right form-check">
      <input
        (change)="isAgreeAccept = !isAgreeAccept"
        [checked]="isAgreeAccept"
        type="checkbox"
        name="agree"
        id="agree"
      />
      <label for="agree" class="marginR20">Agree</label>
      <button
        [disabled]="!isAgreeAccept"
        class="btn btn-primary"
        (click)="accept()"
      >
        Continue
      </button>
    </div>
  </div>
</cookie-law>
