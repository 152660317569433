<div class="row" *ngIf="notification">
  <div class="col-lg-9 col-md-8 text-color">
    <div>
      <div [innerHTML]="notification.heading"></div>
    </div>
  </div>
  <div
    class="col-lg-3 col-md-4 text-sm-right bold"
    (click)="listingActionClick($event)"
  >
    <shared-notification-widget-listing-small-item-action
      [userType]="userType"
      [config]="config"
      [notification]="notification"
    >
    </shared-notification-widget-listing-small-item-action>
  </div>
</div>
