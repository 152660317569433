import { HostListener, Input, OnDestroy, OnInit, OnChanges, SimpleChanges, Directive } from '@angular/core';
import { ASSET_PATH } from '@shared/constant/shared.utils';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';

@Directive()
export abstract class AbstractBaseComponent implements OnDestroy, OnInit, OnChanges {

    assetPath = ASSET_PATH;

    @BlockUI('blockUI') blockUI: NgBlockUI;

    @Input() css;

    protected subscriptions: Subscription[] = [];

    constructor() {
    }

    public canDeactivateMsg() {
        return 'Are you sure?';
    }

    public canDeactivate(): boolean {
        return true;
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate()) {
            $event.returnValue = true;
        }
    }
    ngOnInit(): void {
        this.OnInit();
        setTimeout(() => {
            const ngSelects = document.querySelectorAll('ng-select input[autocomplete]');
            if (ngSelects) {
                [].forEach.call(ngSelects, (ngSelect: any) => {
                    // do whatever
                    ngSelect.setAttribute('autocomplete', 'off');
                });
            }
        }, 1000);
    }
    protected OnInit(): void {
    }

    ngOnDestroy() {
        this.unsubscribeAll();
        if (this.blockUI) {
            this.blockUI.stop();
        }
    }

    protected unsubscribeAll() {
        this.subscriptions
            .forEach((subscription: Subscription) => {
                subscription.unsubscribe();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.onChanges(changes);
    }

    protected onChanges(changes: SimpleChanges): void {

    }

}
