import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "@shared/auth/auth.service";
import { BaseResponseDTO } from "@shared/dto";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  constructor(private http: HttpClient, private authService: AuthService) {}
  adminLogin(loginModel) {
    return this.doLogin(loginModel, "/api/p/users/adminLogin");
  }
  login(loginModel) {
    return this.doLogin(loginModel, "/api/p/users/login");
  }
  private doLogin(loginModel, url): Observable<string> {
    return this.http
      .post<BaseResponseDTO<any>>(url, loginModel)
      .pipe(
        map((res: BaseResponseDTO<any>) => {
          const isLoggedIn = res && res.result && res.result.accessToken;
          let msg = res.msg;
          // login successful if there's a jwt token in the response
          if (isLoggedIn) {
            this.authService.setLoggedInUser(JSON.stringify(res.result));
            msg = null;
          }
          return msg;
        })
      )
      .pipe(first());
  }
  signup(signupModel) {
    return this.http
      .post<BaseResponseDTO<any>>("/api/p/users/registration/", signupModel)
      .pipe(first());
  }

  signupVerify(q: string, isAdmin = false) {
    return this.http
      .post<BaseResponseDTO<any>>(`/api/p/users/registration/verify?q=${q}`, {
        isAdmin: isAdmin,
      })
      .pipe(first());
  }
  processMemberInvite(q: string) {
    return this.http
      .post<BaseResponseDTO<any>>(
        `/api/p/users/registration/processInvite?q=${q}`,
        {}
      )
      .pipe(first());
  }
  inviteSignup(inviteSignupModel) {
    return this.http
      .post<BaseResponseDTO<any>>(
        "/api/p/users/registration/invite/signup",
        inviteSignupModel
      )
      .pipe(first());
  }
  resendSignupLink(email: string) {
    return this.http
      .post<BaseResponseDTO<any>>(`/api/p/users/registration/resend`, {
        data: email,
      })
      .pipe(first());
  }

  forgotPassword(forgotPassworModel: object) {
    return this.http
      .post<BaseResponseDTO<any>>(
        "/api/p/users/forgotPassword",
        forgotPassworModel
      )
      .pipe(first());
  }

  forgotPasswordVerify(q: string, password: string, isAdmin = false) {
    return this.http
      .post<BaseResponseDTO<any>>(`/api/p/users/forgotPassword/verify?q=${q}`, {
        password: password,
        isAdmin: isAdmin,
      })
      .pipe(first());
  }

  logout(pushSubscription?: any) {
    return this.http
      .put<BaseResponseDTO<any>>(
        "/api/users/logout",
        pushSubscription ? pushSubscription : undefined
      )
      .pipe(first());
  }
}
