import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap, Params, Router } from "@angular/router";
import { ContainerDetailsBuyerData } from "@marketplace/dto/ContainerDetailsBuyer.data";
import { BoxxportBuyBackUtils } from "@marketplace/utils/BoxxportBuyBack.utils";
import { ProductSearchUtils } from "@marketplace/utils/ProductSearch.utils";
import { AbstractBaseComponent } from "@shared/abstracts/abstract-base.component";
import { AuthService } from "@shared/auth/auth.service";
import { ErrorUtils } from "@shared/constant/error.utils";
import { LocaleUtils } from "@shared/constant/shared.localeUtils";
import { Utils } from "@shared/constant/shared.utils";
import { ContainerGradeDTO } from "@shared/dto/ContainerGradeDTO";
import { ContainerTypeDTO } from "@shared/dto/ContainerTypeDTO";
import { GeoTaggedLocationDTO } from "@shared/dto/GeoTaggedLocation";
import {
  MapLoaderOption,
  MapLoaderService,
} from "@shared/map/map-loader.service";
import { MapUtils, PlaceResultOption } from "@shared/map/map.utils";
import { ReadMasterDataService } from "@shared/services";
import { CountryService } from "@shared/services/country.service";
import { CustomMetaTagService } from "@shared/_services/custom.meta.tag.service";
import { isEqual, remove } from "lodash";
@Component({
  selector: "marketplace-buyback-search",
  templateUrl: "./marketplace-buyback-search.component.html",
  styleUrls: ["./marketplace-buyback-search.component.scss"],
})
export class MarketplaceBuyBackSearchComponent
  extends AbstractBaseComponent
  implements OnInit, AfterViewInit
{
  filterParams: ParamMap;
  containerGradeSelectedList: ContainerGradeDTO[];

  containerGradeExpanded = false;
  addContainerGradeTouched = false;
  containerTypeSelectedList: ContainerTypeDTO[];
  @Input() isLoggedIn = false;
  containerTypeExpanded = false;
  addContainerTypeTouched = false;
  @ViewChild("mapLocation") mapLocation: ElementRef;
  @ViewChild("mapLocation2") mapLocation2: ElementRef;
  locs: PlaceResultOption[] = [];
  @Input() heading: string;
  @Input() updateMetaData = true;
  containerDetailsBuyerData: ContainerDetailsBuyerData;
  MAX_REDELIVERY_LOCATIONS = 7;
  geoTaggedLocations: GeoTaggedLocationDTO[];
  filteredType = ["20' Dry Van", "40' Dry Van", "40' High Cube"];
  filteredGrade = ["IICL", "Cargo Worthy", "New"];
  constructor(
    private activatedRoute: ActivatedRoute,
    private mapLoaderService: MapLoaderService,
    private router: Router,
    private countryService: CountryService,
    private readMasterDataService: ReadMasterDataService,
    private metaService: CustomMetaTagService,
    private authService: AuthService,
    private ngZone: NgZone,
    private buybackUtils: BoxxportBuyBackUtils
  ) {
    super();
  }

  ngOnInit() {
    const defaultValues = new Map();
    defaultValues.set("radius", 100);
    this.filterParams = this.activatedRoute.snapshot.queryParamMap;
    this.containerDetailsBuyerData = new ContainerDetailsBuyerData(
      this.readMasterDataService,
      this.countryService,
      this.filterParams,
      defaultValues
    );
    if (this.updateMetaData) {
      this.metaService.updateTag({
        name: "description",
        content:
          "BOXXPORT is an online platform enabling people from across the globe to sell and buy shipping containers online at much-negotiated rate with just a few clicks.",
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.shouldAddressAutoCompleteWork()) {
      const nativeElement = this.mapLocation.nativeElement;
      this.mapLoaderService.mapLoader({
        nativeElement: nativeElement,
        defaultNullCheck: true,
        listenerCallback: (place: google.maps.places.PlaceResult) => {
          this.containerDetailsBuyerData.filterForm.controls.location.setErrors(
            undefined
          );
          const placeResult: PlaceResultOption = MapUtils.placeResult(place);
          if (this.locs == undefined) {
            this.locs = [];
          }
          const locsTemp = this.locs.filter((loc) => {
            return isEqual(loc, placeResult);
          });
          if (locsTemp && locsTemp.length > 0) {
            this.containerDetailsBuyerData.filterForm.controls.location.setErrors(
              { sameLocationAsRedeliveryLocation: true }
            );
          } else {
            ProductSearchUtils.updateFormByPlace(
              this.containerDetailsBuyerData.filterForm,
              place
            );
          }
        },
      } as MapLoaderOption);
      const nativeElement2 = this.mapLocation2.nativeElement;
      this.mapLoaderService.mapLoader(
        {
          nativeElement: nativeElement2,
          defaultNullCheck: true,
          listenerCallback: (place: google.maps.places.PlaceResult) => {
            this.ngZone.run(() => {
              const placeResult: PlaceResultOption =
                MapUtils.placeResult(place);
              if (this.locs == undefined) {
                this.locs = [];
              }
              this.containerDetailsBuyerData.filterForm.controls.reDeliveryLocations.setErrors(
                undefined
              );
              this.containerDetailsBuyerData.filterForm.controls.reDeliveryLocations.updateValueAndValidity();
              const country =
                this.containerDetailsBuyerData.filterForm.controls.country
                  .value;
              const city =
                this.containerDetailsBuyerData.filterForm.controls.city.value;
              const state =
                this.containerDetailsBuyerData.filterForm.controls.state.value;
              if (
                placeResult.city == city &&
                placeResult.state == state &&
                placeResult.country == country
              ) {
                this.containerDetailsBuyerData.filterForm.controls.reDeliveryLocations.setErrors(
                  { sameLocationAsContainerLocation: true }
                );
              } else {
                const locsTemp = this.locs.filter((loc) => {
                  return (
                    this.buybackUtils.getRedeliveryLocationName(loc) ==
                    this.buybackUtils.getRedeliveryLocationName(placeResult)
                  );
                });
                if (locsTemp == undefined || locsTemp.length <= 0) {
                  if (
                    this.locs &&
                    this.locs.length >= this.MAX_REDELIVERY_LOCATIONS
                  ) {
                    this.containerDetailsBuyerData.filterForm.controls.reDeliveryLocations.setErrors(
                      { maxLocationReached: true }
                    );
                  } else {
                    if (placeResult.country == "United States") {
                      placeResult.city = placeResult.name;
                      placeResult.location =
                        this.buybackUtils.getRedeliveryLocationName(
                          placeResult
                        );
                    }
                    this.locs.push(placeResult);
                    if (this.locs.length > 0) {
                      this.containerDetailsBuyerData.filterForm.controls.reDeliveryLocations.clearValidators();
                    } else {
                      this.containerDetailsBuyerData.filterForm.controls.reDeliveryLocations.setValidators(
                        Validators.required
                      );
                    }
                    this.containerDetailsBuyerData.filterForm.controls.reDeliveryLocations.updateValueAndValidity();
                    this.mapLocation2.nativeElement.value = "";
                  }
                } else {
                  this.mapLocation2.nativeElement.value = "";
                }
              }
            });
          },
        } as MapLoaderOption,
        { types: ["(cities)"] }
      );
    }
  }

  gotoSearch() {
    const filterForm = this.containerDetailsBuyerData.filterForm;
    Utils.markFormGroupTouched(filterForm);
    if (!this.shouldAddressAutoCompleteWork()) {
      const selectedCity =
        this.containerDetailsBuyerData.filterForm.controls.city.value;
      if (this.geoTaggedLocations) {
        const geoTaggedLocationDTOs = this.geoTaggedLocations.filter(
          (city) => city.city === selectedCity
        );
        if (geoTaggedLocationDTOs && geoTaggedLocationDTOs.length > 0) {
          this.containerDetailsBuyerData.filterForm.controls.countryShortName.setValue(
            geoTaggedLocationDTOs[0].iso2
          );
          this.containerDetailsBuyerData.filterForm.controls.longitude.setValue(
            geoTaggedLocationDTOs[0].longitude
          );
          this.containerDetailsBuyerData.filterForm.controls.latitude.setValue(
            geoTaggedLocationDTOs[0].latitude
          );
          this.containerDetailsBuyerData.filterForm.controls.location.setValue(
            geoTaggedLocationDTOs[0].city +
              "," +
              geoTaggedLocationDTOs[0].country
          );
        }
      }
    }
    let locations = undefined;
    this.containerDetailsBuyerData.filterForm.controls.reDeliveryLocations.setValue(
      undefined
    );
    if (this.locs.length > 0) {
      locations = JSON.stringify(this.locs);
    } else {
      this.containerDetailsBuyerData.filterForm.controls.reDeliveryLocations.addValidators(
        Validators.required
      );
      this.containerDetailsBuyerData.filterForm.controls.reDeliveryLocations.updateValueAndValidity();
    }
    let types = undefined;
    this.containerDetailsBuyerData.filterForm.controls.checklistType.setValue(
      undefined
    );
    this.addContainerTypeTouched = true;
    if (
      this.containerTypeSelectedList &&
      this.containerTypeSelectedList.length > 0
    ) {
      types = this.containerTypeSelectedList
        .map((item) => item.containerTypeCodeDesc)
        .join();
    } else {
      this.containerDetailsBuyerData.filterForm.controls.checklistType.addValidators(
        Validators.required
      );
      this.containerDetailsBuyerData.filterForm.controls.checklistType.updateValueAndValidity();
    }

    let grades = undefined;
    this.containerDetailsBuyerData.filterForm.controls.checklistGrade.setValue(
      undefined
    );
    this.addContainerGradeTouched = true;
    if (
      this.containerGradeSelectedList &&
      this.containerGradeSelectedList.length > 0
    ) {
      grades = this.containerGradeSelectedList
        .map((item) => item.description)
        .join();
    } else {
      this.containerDetailsBuyerData.filterForm.controls.checklistGrade.addValidators(
        Validators.required
      );
      this.containerDetailsBuyerData.filterForm.controls.checklistGrade.updateValueAndValidity();
    }
    this.mapLocation2.nativeElement.value = "";
    if (filterForm.valid && filterForm.dirty && locations && types && grades) {
      this.containerDetailsBuyerData.filterForm.controls.reDeliveryLocations.setValue(
        locations
      );
      this.containerDetailsBuyerData.filterForm.controls.checklistGrade.setValue(
        grades
      );
      this.containerDetailsBuyerData.filterForm.controls.checklistType.setValue(
        types
      );
      const queryParams: Params =
        this.containerDetailsBuyerData.filterForm.getRawValue();
      this.router.navigate(["/buyer/product/listing/buyback"], {
        queryParams: queryParams,
        queryParamsHandling: "merge",
      });
    }
  }

  getRadiusVal() {
    return this.containerDetailsBuyerData.filterForm.controls.radius.value;
  }
  setRadiusVal(value) {
    console.log(value);
    this.containerDetailsBuyerData.filterForm.controls.radius.setValue(value);
  }
  shouldAddressAutoCompleteWork() {
    return LocaleUtils.shouldAddressAutoCompleteWork();
  }
  fetchAndPopulateCityData() {
    this.containerDetailsBuyerData.filterForm.controls.city.markAsUntouched();
    this.containerDetailsBuyerData.filterForm.controls.city.setValue(undefined);
    const selectedCountry =
      this.containerDetailsBuyerData.filterForm.controls.country.value;
    const countrydtos =
      this.containerDetailsBuyerData.countryWrapperDTO.countryData.filter(
        (countryDto) => countryDto.countryName === selectedCountry
      );
    this.geoTaggedLocations = [];
    if (countrydtos.length > 0) {
      this.countryService
        .fetchGeoTaggedLocations(countrydtos[0].iso2)
        .subscribe((cityData) => {
          this.geoTaggedLocations = cityData.result;
        }, ErrorUtils.logError);
    }
  }

  countrySearchFN = (term: string, item: any) => {
    term = term?.toLocaleLowerCase();
    const countrydtos =
      this.containerDetailsBuyerData?.countryWrapperDTO?.countryData?.filter(
        (countryDto) => countryDto?.countryName === item?.key
      );
    return (
      countrydtos[0]?.countryName?.toLocaleLowerCase().indexOf(term) > -1 ||
      countrydtos[0]?.iso?.toLocaleLowerCase().indexOf(term) > -1 ||
      countrydtos[0]?.iso2?.toLocaleLowerCase().indexOf(term) > -1
    );
  };
  removeLocation(delLoc: PlaceResultOption) {
    this.locs = this.locs.filter((loc) => {
      return !isEqual(loc, delLoc);
    });
    this.containerDetailsBuyerData.filterForm.controls.reDeliveryLocations.setErrors(
      undefined
    );
    if (this.locs && this.locs.length > this.MAX_REDELIVERY_LOCATIONS) {
      this.containerDetailsBuyerData.filterForm.controls.reDeliveryLocations.setErrors(
        { maxLocationReached: true }
      );
    }
  }
  containerGradeClick() {
    this.containerGradeExpanded = !this.containerGradeExpanded;
    this.addContainerGradeTouched = true;
  }

  containerGradeServedListChange($event, containerGrader: ContainerGradeDTO) {
    this.containerGradeHandle(containerGrader, $event.target.checked === true);
  }
  private containerGradeHandle(
    containerGrader: ContainerGradeDTO,
    isChecked = false
  ) {
    if (isChecked) {
      if (!this.containerGradeSelectedList) {
        this.containerGradeSelectedList = [];
      }
      this.containerGradeSelectedList.push(containerGrader);
    } else {
      remove(this.containerGradeSelectedList, (obj) => {
        const value = isEqual(obj.code, containerGrader.code);
        if (value) {
          this.updateContainerGradeCheckbox(obj, false);
        }
        return value;
      });
    }
    if (this.containerGradeSelectedList.length > 0) {
      this.containerDetailsBuyerData.filterForm.controls.checklistGrade.clearValidators();
    } else {
      this.containerDetailsBuyerData.filterForm.controls.checklistGrade.setValidators(
        Validators.required
      );
    }
    this.containerDetailsBuyerData.filterForm.controls.checklistGrade.updateValueAndValidity();
  }
  private updateContainerGradeCheckbox(
    containerGrader: ContainerGradeDTO,
    checked = false
  ) {
    const element = document.getElementById(
      `containerGradeIndex-${containerGrader.code}`
    ) as HTMLElement;
    if (element) {
      element["checked"] = checked;
    }
  }
  checkForValue(containerGrade: ContainerGradeDTO) {
    let containerGradeExists = false;
    if (this.containerGradeSelectedList) {
      this.containerGradeSelectedList.forEach((containerGradeTemp) => {
        if (containerGradeTemp.code === containerGrade.code) {
          containerGradeExists = true;
        }
      });
    }
    return containerGradeExists;
  }
  containerGradeRemove(containerGrade: ContainerGradeDTO) {
    this.containerGradeHandle(containerGrade, false);
  }
  containerTypeClick() {
    this.containerTypeExpanded = !this.containerTypeExpanded;
    this.addContainerTypeTouched = true;
  }

  containerTypeServedListChange($event, containerType: ContainerTypeDTO) {
    this.containerTypeHandle(containerType, $event.target.checked === true);
  }
  private containerTypeHandle(
    containerType: ContainerTypeDTO,
    isChecked = false
  ) {
    if (isChecked) {
      if (!this.containerTypeSelectedList) {
        this.containerTypeSelectedList = [];
      }
      this.containerTypeSelectedList.push(containerType);
    } else {
      remove(this.containerTypeSelectedList, (obj) => {
        const value = isEqual(
          obj.containerTypeCode,
          containerType.containerTypeCode
        );
        if (value) {
          this.updateContainerTypeCheckbox(obj, false);
        }
        return value;
      });
    }
    if (this.containerTypeSelectedList.length > 0) {
      this.containerDetailsBuyerData.filterForm.controls.checklistType.clearValidators();
    } else {
      this.containerDetailsBuyerData.filterForm.controls.checklistType.setValidators(
        Validators.required
      );
    }
    this.containerDetailsBuyerData.filterForm.controls.checklistType.updateValueAndValidity();
  }
  private updateContainerTypeCheckbox(
    containerType: ContainerTypeDTO,
    checked = false
  ) {
    const element = document.getElementById(
      `containerTypeIndex-${containerType.containerTypeCode}`
    ) as HTMLElement;
    if (element) {
      element["checked"] = checked;
    }
  }
  checkForValueType(containerType: ContainerTypeDTO) {
    let containerTypeExists = false;
    if (this.containerTypeSelectedList) {
      this.containerTypeSelectedList.forEach((containerTypeTemp) => {
        if (
          containerTypeTemp.containerTypeCode ===
          containerType.containerTypeCode
        ) {
          containerTypeExists = true;
        }
      });
    }
    return containerTypeExists;
  }
  containerTypeRemove(containerType: ContainerTypeDTO) {
    this.containerTypeHandle(containerType, false);
  }
  isFilteredType(type: ContainerTypeDTO) {
    return (
      this.filteredType.filter((t) => t == type.containerTypeCodeDesc).length >
      0
    );
  }
  typeSelected(value, event) {
    const selectedType: ContainerTypeDTO =
      this.containerDetailsBuyerData.containerTypeData.filter(
        (type) => type.containerTypeCode == value
      )[0];
    this.containerTypeHandle(selectedType, event.target.checked);
  }
  getTypesForView() {
    if (this.containerTypeSelectedList) {
      return this.containerTypeSelectedList.filter(
        (type) => this.filteredType.indexOf(type.containerTypeCodeDesc) == -1
      );
    }
    return this.containerTypeSelectedList;
  }
  isFilteredGrade(grade: ContainerGradeDTO) {
    return this.filteredGrade.filter((t) => t == grade.description).length > 0;
  }
  gradeSelected(value, event) {
    const selectedGrade: ContainerGradeDTO =
      this.containerDetailsBuyerData.containerGradeData.filter(
        (grade) => grade.description == value
      )[0];
    this.containerGradeHandle(selectedGrade, event.target.checked);
  }
  getGradesForView() {
    if (this.containerGradeSelectedList) {
      return this.containerGradeSelectedList.filter(
        (grade) => this.filteredGrade.indexOf(grade.description) == -1
      );
    }
    return this.containerGradeSelectedList;
  }
}
