import { AgmCoreModule, LazyMapsAPILoaderConfigLiteral, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { Injectable, NgModule } from '@angular/core';
import { ConstantService, GOOGLE_MAP_API_KEY } from '@shared/constant/shared.constant';
import { MapLoaderService } from './map-loader.service';


@Injectable({ providedIn: 'root' })
export class GoogleMapsConfig implements LazyMapsAPILoaderConfigLiteral {
    apiKey: string = ConstantService.get(GOOGLE_MAP_API_KEY);
    libraries = ['places'];
    language = 'en';
}

@NgModule({
    imports: [
        AgmCoreModule.forRoot(),
    ],
    providers: [
        { provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapsConfig },
        MapLoaderService
    ],
    declarations: [],
    exports: [
        AgmCoreModule
    ]
})
export class MapCoreModule { }
