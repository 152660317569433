<ng-container [ngSwitch]="isLoggedIn">
  <div
    class="d-flex flex-column align-items-center justify-content-center h-100"
    *ngSwitchCase="true"
  >
    <h1 class="text-white text-center font32 font-weight-normal">
      Set preference
      <p class="bold">for your Container Radar</p>
    </h1>
    <div class="row mw-100 m-0">
      <div class="p-1 col-lg-6 col-12">
        <button
          class="btn btn-white text-color w-100"
          routerLink="/buyer/radar"
        >
          <div class="d-flex">
            <img
              class="mr-2"
              src="{{ assetPath }}/images/new-svg/cyanradaricon.svg"
            />
            <div class="text-nowrap">
              Set preference
              <p class="bold text-left">for Buying</p>
            </div>
          </div>
        </button>
      </div>
      <div class="p-1 col-lg-6 col-12">
        <ng-container [ngSwitch]="isSeller">
          <button
            class="btn btn-white text-color w-100"
            *ngSwitchCase="true"
            routerLink="/seller/radar"
          >
            <div class="d-flex">
              <img
                class="mr-2"
                src="{{ assetPath }}/images/new-svg/pinkradaricon.svg"
                alt=""
              />
              <div class="text-nowrap">
                Track preference
                <p class="bold text-left">for Selling</p>
              </div>
            </div>
          </button>
          <div class="d-flex flex-column h-100" *ngSwitchCase="false">
            <button class="btn btn-grey1 text-color" routerLink="/asSeller">
              Register as a Seller
              <img
                alt=""
                src="{{ assetPath }}/images/new-svg/rightgreyarrow.svg"
              />
            </button>
            <p class="text-blue-lighter m-0">
              Follow the demands for shipping containers
            </p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div
    class="d-flex justify-content-around align-items-center text-white h-100 flex-wrap flex-lg-nowrap py-3"
    *ngSwitchCase="false"
  >
    <div class="text-center text-lg-left col-12 col-lg-4">
      <h1 class="font32 fw-350 font-freightSans-pro">
        Empower <br />
        <b>your decisions</b>
      </h1>

      <p>
        <b>
          Container Radar is a powerful tool designed to address unmet market
          demands!</b
        >
        Buyers can specify the containers they've been seeking, while Sellers
        can fulfill those unmet demands promptly.
      </p>
    </div>
    <div class="d-flex flex-column align-item-center col-12 col-lg-4">
      <img
        class="my-4 mx-auto"
        src="{{ assetPath }}/images/new-svg/radar.svg"
        height="100px"
        width="100px"
      />
      <button class="btn btn-pink mb-2" routerLink="/signup">
        Start using Container Radar
        <img
          class="pl-1"
          src="{{ assetPath }}/images/new-svg/rightpinkarrow.svg"
        />
      </button>
      <button
        class="btn btn-pink-outline"
        routerLink="/radar-shipping-containers"
      >
        Learn More
      </button>
    </div>
  </div>
</ng-container>
