import { Injector, Directive } from "@angular/core";
import { ScrollService } from "@marketplace/service/scroll.service";
import { AbstractBaseBlockUIComponent } from "@shared/abstracts/abstract-base-blockui.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { VideoPlayerModalComponent } from "@marketplace/marketplace-core/video-player-modal/video-player-modal.component";

export interface VideoOption {
  url?: string;
  type?: string;
}
@Directive()
export abstract class AbstractBaseStaticComponent extends AbstractBaseBlockUIComponent {
  protected scrollService: ScrollService;

  protected modalService: BsModalService;

  // private modalService: BsModalService;

  constructor(protected injector: Injector) {
    super(injector);
    this.modalService = injector.get(BsModalService);
    this.scrollService = injector.get(ScrollService);
  }

  protected OnInit() {
    super.OnInit();
    this.staticOnInit();
  }

  scrollTo(el: string) {
    const element = document.getElementById(el);
    element?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  }

  protected staticOnInit() {}

  goto(target) {
    this.scrollService.scrollTaget(target);
  }

  playVideoUrl(url) {
    const config = {} as VideoOption;
    config.url = url;
    this.playVideo(config);
  }

  playVideoType(type) {
    const config = {} as VideoOption;
    config.type = type;
    if (!type) {
      return;
    }
    this.playVideo(config);
  }

  playVideo(initialState: VideoOption = {}) {
    this.modalService.show(VideoPlayerModalComponent, {
      class: "modal-dialog-centered big-modal-dialog-fullscreen",
      initialState,
    });
  }
}
