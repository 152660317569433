import { Injector, Directive } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { AbstractBaseBlockUIComponent } from '@shared/abstracts/abstract-base-blockui.component';
import { ErrorUtils } from '@shared/constant/error.utils';
import { ContainerDto } from '@shared/dto';
import { SellerProductService } from '@shared/services';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Directive()
export abstract class SellerProductListingAbstractComponent extends AbstractBaseBlockUIComponent {

    @BlockUI('blockUI') blockUI: NgBlockUI;
    containers: ContainerDto[] = [];
    totalCount = 0;
    error: string; // The new stock has to be lower than the previous stock.
    pageCount = 0;
    pageLength = 10;
    selectedTab: string;
    searchText: string;
    private sellerProductService: SellerProductService;
    protected router: Router;
    private activatedRoute: ActivatedRoute;
    selectedTabType = undefined;
    sortingObject = {
        name: 'LIVE_DATE',
        isAsc: false
    };
    constructor(protected injector: Injector) {
        super(injector);
        this.sellerProductService = this.injector.get(SellerProductService);
        this.router = this.injector.get(Router);
        this.activatedRoute = this.injector.get(ActivatedRoute);
    }

    OnInit() {
        this.activatedRoute.queryParams.subscribe(queryParams => {
            if (queryParams.pageNumber) {
                this.pageCount = queryParams.pageNumber;
            } if (queryParams.pageLength) {
                this.pageLength = queryParams.pageLength;
            }
            if (queryParams.sortingField) {
                this.sortingObject.name = queryParams.sortingField;
            }
            if (queryParams.sortOrder) {
                this.sortingObject.isAsc = queryParams.sortOrder === 'ASC' ? true : false;
            }
            this.loadData();
        });
    }
    loadData() {
        if (this.selectedTab) {
            let filterParams = this.activatedRoute.snapshot.queryParamMap['params'];
            if (this.selectedTab == 'ACTIVE' && this.selectedTabType == 'UPDATE' && Object.keys(filterParams).length == 0) {
                filterParams = {
                    'sortingField': this.sortingObject.name,
                    'order': this.sortingObject.isAsc ? 'ASC' : 'DESC'
                };
            }
            this.blockUI.start();
            this.sellerProductService.searchProductForTabCount(this.selectedTab, filterParams)
                .pipe(finalize(() => {
                    this.blockUI.stop();
                })).subscribe((countData) => {
                    this.totalCount = countData.result;
                    this.sellerProductService.searchProductsForTab(this.selectedTab, filterParams).subscribe((data) => {
                        this.containers = data.result;
                    });
                });
        }
    }
    paginationEvent(pagination: Params) {
        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: pagination,
                queryParamsHandling: 'merge', // remove to replace all query params by provided
            });
    }
    reloadPage($event) {
        console.log($event + 'listing abstract');
        this.error = undefined;
        if ($event) {
            if ($event === 'failedMoreQuantity') {
                this.error = 'The new stock has to be lower than the previous stock.';
            } else if ($event === 'failedNoQuantity') {
                this.error = 'The new Stock value cannot be empty.';
            } else if ($event === 'failedZeroQuantity') {
                this.error = 'The new Stock value cannot be Zero.';
            } else if ($event === 'failed') {
                this.error = 'The new Stock update Failed.';
            } else if ($event === 'clearError') {
                this.error = undefined;
            } else if ($event === 'failedZeroNewPrice') {
                this.error = 'The new Stock Price value cannot be Zero.';
            } else {
                this.ngOnInit();
            }
        }
    }
    private executeSearch(filterParams: any) {
        const productSearch = this.sellerProductService.searchProductsForTab(this.selectedTab, filterParams);
        const productSearchCount = this.sellerProductService.searchProductForTabCount(this.selectedTab, filterParams);
        forkJoin([productSearch, productSearchCount])
            .pipe(
                finalize(() => {
                })
            ).subscribe(([productSearchResult, productSearchCountResult]) => {
                this.containers = productSearchResult.result;
                this.totalCount = productSearchCountResult.result;
            }, ErrorUtils.logError);
    }
    search() {
        // if (this.searchText) {
        this.executeSearch({ genericSearchString: this.searchText });
        // }
    }
    addArrowClass(name) {
        if (this.sortingObject.name === name) {
            if (this.sortingObject.isAsc) {
                return 'icn-uparrow';
            } else {
                return 'icn-dnarrow';
            }
        }
        return 'icn-updnarrow';
    }

    addSorting(name) {
        if (this.sortingObject.name === name) {
            if (this.sortingObject.isAsc) {
                return 'sortACN';
            } else {
                return 'sortDCN';
            }
        }
        return 'sort';
    }

    sort(sortingField: string) {
        if (this.sortingObject.name === sortingField) {
            this.sortingObject.isAsc = !this.sortingObject.isAsc;
        }
        const order = this.sortingObject.isAsc ? 'ASC' : 'DESC';
        this.paginationEvent({ sortingField, order });
    }
    paste(pastedText) {
        this.searchText = pastedText;
        this.search();
    }
}
