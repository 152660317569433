import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScatterPlotChartComponent } from './scatter-plot-chart/scatter-plot-chart.component';
import { FunnelChartComponent } from './funnel-chart/funnel-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { BarStackChartComponent } from './bar-stack-chart/bar-stack-chart.component';
import { VerticalChartComponent } from './vertical-chart/vertical-chart.component';
import { HorizontalChartComponent } from './horizontal-chart/horizontal-chart.component';
import { SeriesChartComponent } from './series-chart/series-chart.component';
import { VerticalStackedChartComponent } from './vertical-stacked-chart/vertical-stacked-chart.component';
import { HorizontalStackedChartComponent } from './horizontal-stacked-chart/horizontal-stacked-chart.component';
import { AccessibleLineChartComponent } from './accessible-line-chart/accessible-line-chart.component';

// https://www.highcharts.com/blog/post/highcharts-and-angular-7/
// https://www.highcharts.com/demo
// https://api.highcharts.com/highcharts/legend
@NgModule({
  declarations: [ScatterPlotChartComponent, FunnelChartComponent, PieChartComponent, BarStackChartComponent, VerticalChartComponent, HorizontalChartComponent, SeriesChartComponent, VerticalStackedChartComponent, HorizontalStackedChartComponent, AccessibleLineChartComponent],
  exports: [ScatterPlotChartComponent, FunnelChartComponent, PieChartComponent, BarStackChartComponent, VerticalChartComponent, HorizontalChartComponent, SeriesChartComponent, VerticalStackedChartComponent, HorizontalStackedChartComponent,AccessibleLineChartComponent],
  imports: [
    CommonModule
  ]
})
export class SharedChartCoreModule { }
