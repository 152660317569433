import { EventEmitter, Injectable, Output } from '@angular/core';
import { ProductListingDTO } from '@marketplace/dto/ProductListingDTO';
import { AuthService } from '@shared/auth/auth.service';
import { BuyerContainerDto } from '@shared/dto/BuyerContainerDto';
import { ProductService } from '@shared/services';
import { ProductListingActionStatus } from '../enums/ProductListingActionStatus.enums';
import { MarkerplaceProductCompareService } from './markerplace-product-compare.service';
import { MarkerplaceProductFollowService, SubscriptionType } from './markerplace-product-follow.service';

@Injectable({
    providedIn: 'root'
})
export class MarkerplaceProductService {

    @Output() public locationUpdateEvent: EventEmitter<any> = new EventEmitter();


    constructor(
        private buyerProductListingCompareService: MarkerplaceProductCompareService,
        private authService: AuthService,
        private productService: ProductService,
        private productListingFollowService: MarkerplaceProductFollowService,
    ) { }

    productListingDTO(container: BuyerContainerDto): ProductListingDTO {
        if (container) {
            const productListingDTO: ProductListingDTO = new ProductListingDTO();
            productListingDTO.container = container;
            productListingDTO.isAddedForCompare = this.buyerProductListingCompareService.isAddedForCompare(container.identifier);
            productListingDTO.followStatus = container.isSubscribe === true ? ProductListingActionStatus.active : ProductListingActionStatus.inactive;
            productListingDTO.identifier = container.identifier;
            return productListingDTO;
        }
    }

    followStatus(productListingDTO: ProductListingDTO[]) {
        if (this.authService.isUserLoggedIn() && false) {
            const productIdentifiers = productListingDTO
                .map(p => p.container)
                .map((container: BuyerContainerDto) => container.identifier)
                .join(',');
            if (productIdentifiers) {
                this.productListingFollowService
                    .list(SubscriptionType.PRODUCT, productIdentifiers)
                    .subscribe(followData => {
                        const identifierArr: string[] = followData.result || [];
                        productListingDTO.forEach(p => {
                            const isTrue = p.container && identifierArr.indexOf(p.container.identifier) !== -1;
                            if (isTrue) {
                                p.followStatus = ProductListingActionStatus.active;
                            } else {
                                p.followStatus = ProductListingActionStatus.inactive;
                            }
                        });
                    });
            }
        }
    }

    clearAddToCompare() {
        this.buyerProductListingCompareService.clearAddToCompare();
    }

    productDetails(productIdentifier: string, querParams?: any) {
        return this.productService.details(productIdentifier, querParams);
    }
}
