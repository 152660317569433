import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthSellerOnlyGuardService } from "@shared/auth/auth.selleronly.guard";
import { AuthGuardService } from "@shared/auth/auth.service.guard";
import { ConstantService, USE_HASH } from "@shared/constant/shared.constant";
import { environment } from "../environments/environment";
import { AccessDeniedComponent } from "./access-denied/access-denied.component";
import { BeacomeAsSellerHomeComponent } from "./beacome-as-seller/beacome-as-seller-home/beacome-as-seller-home.component";
import { BuyerComponent } from "./buyer/buyer.component";
import { HeaderType } from "./enums/header-types.enums";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { ProfileComponent } from "./profile/profile.component";
import { RootComponent } from "./root/root.component";
import { SellerListingUpdateHomeComponent } from "./seller/product/seller-listing-update/seller-listing-update-home/seller-listing-update-home.component";
import { SellerComponent } from "./seller/seller.component";
import { StaticComponent } from "./static/static.component";
import { SubscriptionSuccessComponent } from "./subscription-success/subscription-success.component";
import { UnderMaintenanceComponent } from "./under-maintenance/under-maintenance.component";

const routes: Routes = [
  {
    path: "",
    component: RootComponent,
    loadChildren: () => import("./root/root.module").then((m) => m.RootModule),
  },
  // {
  //   path: "static",
  //   component: StaticComponent,
  //   loadChildren: () =>
  //     import("./static/static.module").then((m) => m.StaticModule),
  // },
  {
    path: "profile",
    component: ProfileComponent,
    loadChildren: () =>
      import("./profile/profile.module").then((m) => m.ProfileModule),
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {},
  },
  {
    path: "pending",
    component: ProfileComponent,
    loadChildren: () =>
      import("./pending/pending.module").then((m) => m.PendingModule),
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {},
  },
  {
    path: "asSeller",
    component: BeacomeAsSellerHomeComponent,
    loadChildren: () =>
      import("./beacome-as-seller/beacome-as-seller.module").then(
        (m) => m.BeacomeAsSellerModule
      ),
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
  },
  {
    path: "buyer",
    component: BuyerComponent,
    loadChildren: () =>
      import("./buyer/buyer.module").then((m) => m.BuyerModule),
  },
  {
    path: "seller/product/listingUpdate/:orgIdentifier",
    component: SellerListingUpdateHomeComponent,
    loadChildren: () =>
      import(
        "./seller/product/seller-listing-update/seller-listing-update.module"
      ).then((m) => m.SellerListingUpdateModule),
    canActivate: [AuthGuardService, AuthSellerOnlyGuardService],
    canActivateChild: [AuthGuardService],
  },
  {
    path: "seller",
    loadChildren: () =>
      import("./seller/seller.module").then((m) => m.SellerModule),
    component: SellerComponent,
    canActivate: [AuthGuardService, AuthSellerOnlyGuardService],
    canActivateChild: [AuthGuardService],
    data: {
      header: HeaderType.HEADER_SELLER,
    },
  },

  {
    path: "",
    loadChildren: () =>
      import("./static/static.module").then((m) => m.StaticModule),
    component: StaticComponent,
  },

  {
    path: "subscription-success",
    component: SubscriptionSuccessComponent,
  },
  {
    path: "accessDenied",
    component: AccessDeniedComponent,
  },
  {
    path: "serverDown",
    component: UnderMaintenanceComponent,
  },
  {
    path: "notFound",
    component: PageNotFoundComponent,
  },
  {
    path: ":url",
    component: LandingPageComponent,
  },
  {
    path: "**",
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      enableTracing: false && !environment.production,
      initialNavigation: "enabled",
      useHash: ConstantService.get(USE_HASH) === true,
      paramsInheritanceStrategy: "always",
    }),
  ],
  exports: [RouterModule],
})
export class MarketplaceModuleRoutingModule {}
