import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractBaseService } from "@shared/abstracts/abstract-base-service";
import { BaseResponseDTO } from "@shared/dto";

@Injectable({
  providedIn: "root",
})
export class MarketplaceStocksService extends AbstractBaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getAvailableStocks() {
    return this.http.get<BaseResponseDTO<any>>(
      `${this.apiBaseUrl}/p/landingPage/stockandvalue`
    );
  }
}
