import { Component, Injector } from '@angular/core';
import { AbstractBaseBlockUIComponent } from '@shared/abstracts/abstract-base-blockui.component';

@Component({
  selector: 'landing-page-testemonials',
  templateUrl: './landing-page-testemonials.component.html',
  styleUrls: ['./landing-page-testemonials.component.scss']
})
export class LandingPageTestemonialsComponent extends AbstractBaseBlockUIComponent {

  testimonials = [
    {
      mesg: 'We published a listing of our offering on BOXXPORT and managed to dispose of some of  our containers in locations which were yet not so well known to us. The BOXXPORT staff is helpful and very active on following up orders. Recommend to give the platform a try.',
      img: `${this.assetPath}/images/ovl2.png`,
      title: `OVLahtinen CONTAINERS`
    },
    {
      mesg: 'BOXXPORT is a new platform for our container trading e-business. We had some new and exciting experiences as compared to normal business. Moreover, BOXXPORT team was very supportive and helpful to get us familiar with their product.  Hopefully, we can grow stronger together with all the users.',
      img: `${this.assetPath}/images/psg.png`,
      title: 'PSG CONTAINER CO., LIMITED'
    },
    {
      mesg: 'Boxxport offers a digital platform for companies to buy and sell containers on a global basis, bringing local retailers and large global operators together in the quick changing modern world of shipping containers. Boxxport is the new way forward to your container solutions.',
      img: `${this.assetPath}/images/trident.png`,
      title: `TRIDENT CONTAINER LEASING B.V.`
    },
  ];
  constructor(protected injector: Injector) { super(injector); }

}
