import { NgModule } from '@angular/core';
import { CartApiService } from './cart-api.service';
import { CartService } from './cart.service';
import { MarkerplaceProductAnalyticService } from './markerplace-product-analytic.service';
import { MarkerplaceProductCompareService } from './markerplace-product-compare.service';
import { MarkerplaceProductFollowService } from './markerplace-product-follow.service';
import { MarkerplaceProductService } from './markerplace-product.service';

@NgModule({
  providers: [
    MarkerplaceProductService,
    MarkerplaceProductCompareService,
    MarkerplaceProductFollowService,
    MarkerplaceProductAnalyticService,
    CartService,
    CartApiService,
  ],
})
export class MarketplaceProductCoreServiceModule { }
