import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { AbstractBaseService } from '@shared/abstracts/abstract-base-service';
import { Utils } from '@shared/constant/shared.utils';
import { BaseResponseDTO } from '@shared/dto';

export enum SubscriptionType {

  SELLER = 'SELLER',

  PRODUCT = 'PRODUCT',

  ON_REQUEST = 'ON_REQUEST',
}

export interface FollowListingEvent {
  productId: string;
}

const ON_REQUEST_FOLLOW_LISTING: EventEmitter<FollowListingEvent> = new EventEmitter();

@Injectable({ providedIn: 'root' })
export class MarkerplaceProductFollowService extends AbstractBaseService {

  constructor(private http: HttpClient) { super(); }

  @Output() requestFollowListingEvent = ON_REQUEST_FOLLOW_LISTING;

  private url(component: string, type: SubscriptionType, identifier: string) {
    const url = ['subscription'];
    url.push(type);
    url.push(component);
    url.push(identifier);
    const pathUrl = url.filter(e => Utils.isNotNull(e)).join('/');
    return `${this.apiBaseUrl}${pathUrl}`;
  }

  public subscribe(type: SubscriptionType, identifier: string) {
    return this.http.post<BaseResponseDTO<void>>(this.url('subscribe', type, identifier), {});
  }

  public unsubscribe(type: SubscriptionType, identifier: string) {
    return this.http.post<BaseResponseDTO<void>>(this.url('unsubscribe', type, identifier), {});
  }

  public list(type: SubscriptionType, identifier: string) {
    return this.http.get<BaseResponseDTO<string[]>>(this.url(undefined, type, identifier), {});
  }
}
