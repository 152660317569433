import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponseDTO } from '@shared/dto/BaseResponseDTO';
import { CountryDTO } from '@shared/dto/CountryDTO';
import { GeoTaggedLocationDTO } from '@shared/dto/GeoTaggedLocation';
import { first } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class CountryService {

    constructor(private http: HttpClient) { }

    country() {
        return this.http.get<BaseResponseDTO<CountryDTO[]>>('/api/p/country/', {}).pipe(first());
    }

    fetchGeoTaggedLocations(countryCode: string) {
        return this.http.get<BaseResponseDTO<GeoTaggedLocationDTO[]>>('/api/p/country/citiesByCountry/' + countryCode, {}).pipe(first());
    }

    fetchCitiesByCountryAndState(geoTagLocation: GeoTaggedLocationDTO) {
        return this.http.post<BaseResponseDTO<GeoTaggedLocationDTO[]>>(`/api/p/country/citiesByCountryAndState`, geoTagLocation).pipe(first());
    }

    countryForSearch() {
        return this.http.get<BaseResponseDTO<CountryDTO[]>>('/api/p/country/countryForSearch/', {}).pipe(first());
    }

    updatePriceFluctuation(geoTagLocation: GeoTaggedLocationDTO) {
        const url = `/api/admin/country/update/priceFluctuation`;
        return this.http.post<BaseResponseDTO<GeoTaggedLocationDTO[]>>(url, geoTagLocation).pipe(first());
    }
}
