import { BaseDTO } from '@shared/dto/BaseDTO';
import { NegotiationDTO } from '@shared/dto/NegotiationDTO';
import { ReDeliveryLocationPriceDTO } from '@shared/dto/ReDeliveryLocationPriceDTO';

export class CartDTO extends BaseDTO {

    productIdentifier: string;

    quantity: number;

    negotiable: boolean = false;

    negotiationDTO: NegotiationDTO;

    buyback: boolean = false;
    buybackLocations: ReDeliveryLocationPriceDTO[] = [];
}
