<div class="container whyus">
  <h2 class="font36">Why BOXXPORT?</h2>
  <carousel [isAnimated]="true">
    <slide *ngFor="let slide of whyus; let index = index">
      <div class="row">
        <div class="col-md-5 order-md-1 order-2">
          <h2 class="font24 text-cyan bold mt-2">{{ slide.title }}</h2>
          <p>{{ slide.mesg }}</p>
        </div>
        <div class="col-md-6 offset-md-1 order-md-2 order-1 col-8 offset-2">
          <img
            alt=""
            [src]="slide.img"
            [title]="slide.imgTitle"
            class="img-fluid"
            [alt]="slide.imgAlt"
          />
        </div>
      </div>
    </slide>
  </carousel>
</div>
