import { Component, Injector } from '@angular/core';
import { AbstractNavigationModalComponent } from '@marketplace/abstract/abstract-navigation-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'marketplace-update-price-modal',
  templateUrl: './update-price-modal.component.html',
  styleUrls: ['./update-price-modal.component.scss']
})
export class UpdatePriceModalComponent extends AbstractNavigationModalComponent {


  constructor(
    protected injector: Injector,
    public bsModalRef: BsModalRef
  ) { super(bsModalRef, injector); }
}
