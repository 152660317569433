import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AbstractBaseBlockUIComponent } from '@shared/abstracts/abstract-base-blockui.component';
import { ErrorUtils } from '@shared/constant/error.utils';
import { ContainerDto } from '@shared/dto';
import { ContainerPriceHistoryDTO } from '@shared/dto/ContainerPriceHistoryDTO';
import { SellerProductService } from '@shared/services/sellerproduct.service';
import { ProductPriceUtils } from '@shared/utils/ProductPrice.utils';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

@Component({
  selector: '[seller-listing-update-item]',
  templateUrl: './seller-listing-update-item.component.html',
  styleUrls: ['./seller-listing-update-item.component.scss']
})
export class SellerListingUpdateItemComponent extends AbstractBaseBlockUIComponent {


  @Input() container: ContainerDto;
  newQuantity: any;
  newPrice: number;
  editMode: boolean;
  priceUpdateNotAllowed = false;
  priceUpdateRemaining = 0;
  priceUpdateCount = 0;
  @Output() reloadPage = new EventEmitter<any>();
  constructor(protected injector: Injector,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private toastService: ToastrService,
    private sellerProductService: SellerProductService) {
    super(injector);
  }

  ngOnInit() {
    this.newQuantity = this.container.quantityAvailable;
    this.newPrice = this.container.containerListingPrice?.priceAsOfTodayWithFee;
    this.priceUpdateNotAllowed = !this.container.priceUpdateAllowed;
    this.priceUpdateRemaining = this.container.priceUpdateRemaining;
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    this.newQuantity = this.container.quantityAvailable;
    this.newPrice = this.container.containerListingPrice?.priceAsOfTodayWithFee;
    this.reloadPage.emit('clearError');
  }
  saveContainer() {
    if (this.newQuantity === undefined || this.newQuantity === null || this.newQuantity === '') {
      this.reloadPage.emit('failedNoQuantity');
    } else if (this.newQuantity === '0') {
      this.reloadPage.emit('failedZeroQuantity');
    } else if (this.newQuantity > this.container.quantityAvailable) {
      this.reloadPage.emit('failedMoreQuantity');
    } else if (this.newPrice == 0) {
      this.reloadPage.emit('failedZeroNewPrice');
    } else {
      const containerPriceHistoryDTO = new ContainerPriceHistoryDTO();
      containerPriceHistoryDTO.containerId = this.container.identifier;
      const priceInfo = ProductPriceUtils.fromFinalPrice(this.newPrice, this.container.marketPlaceCommission);
      if (this.priceUpdateNotAllowed) {
        containerPriceHistoryDTO.updatedPrice = undefined;
      } else {
        containerPriceHistoryDTO.updatedPrice = priceInfo.myEarning;
      }
      containerPriceHistoryDTO.originalPrice = this.container.containerListingPrice?.priceAsOfToday;
      containerPriceHistoryDTO.negotiable = this.container.negotiable;
      containerPriceHistoryDTO.negotiationAmount = this.container.negotiationAmount;
      containerPriceHistoryDTO.negotiationAmountUnlimited = this.container.negotiationAmountUnlimited;
      if (this.newQuantity !== this.container.quantityAvailable) {
        containerPriceHistoryDTO.updatedQuantity = this.newQuantity;
      }
      this.sellerProductService.updateListing(this.container.identifier, containerPriceHistoryDTO)
        .pipe(finalize(() => {
        }))
        .subscribe(data => {
          this.container.quantityAvailable = this.newQuantity;
          if (!this.container.onRequest) {
            this.container.containerListingPrice.priceAsOfTodayWithFee = this.newPrice;
          }
          this.priceUpdateCount = this.priceUpdateCount + 1;
          if (this.priceUpdateRemaining - this.priceUpdateCount <= 0) {
            this.priceUpdateNotAllowed = true;
          }
          this.toggleEditMode();
          this.toastService.success(this.translateService.instant('toast.message.stock.price.quantity.updated.success.msg'), 'Success');
        }, error => {
          ErrorUtils.logError(error);
          if (error == 'max.price.update.exceeded') {
            this.priceUpdateNotAllowed = true;
          }
          this.toastService.error(this.translateService.instant('toast.message.stock.price.quantity.updated.error.msg'), 'Error');
          this.reloadPage.emit('failed');
        });
    }
  }
}
