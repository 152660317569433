import { Injector, Input, OnChanges, SimpleChanges, Directive } from '@angular/core';
import { ProductListingDTO } from '@marketplace/dto/ProductListingDTO';
import { AbstractBaseComponent } from '@shared/abstracts/abstract-base.component';

@Directive()
export abstract class AbstractMarketplaceViewComponent extends AbstractBaseComponent implements OnChanges {

    @Input() product: ProductListingDTO;

    @Input() showExclVat = false;

    @Input() showInclVat = false;
    constructor(
        injector: Injector
    ) {
        super();
    }

    OnInit(): void {
        this.renderView();
    }

    renderView(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.onChanges(changes);
    }

    onChanges(changes: SimpleChanges): void {
    }

}
