import { HttpClient } from '@angular/common/http';
import { AbstractBaseService } from '@shared/abstracts/abstract-base-service';
import { BaseResponseDTO } from '@shared/dto';

export abstract class AbstractDashboardService extends AbstractBaseService {

    constructor(protected httpClient: HttpClient) { super(); }

    protected url(userType: string, orgIdentifiers: string[] | string = '\-1', url: string) {
        return `${this.apiBaseUrl}${userType}/dashboard/${orgIdentifiers}/${url}`;
    }

    public newsletter(userType: string, orgIdentifiers: string[] | string = '\-1') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'newsletter'));
    }

    public topCountries(userType: string, orgIdentifiers: string[] | string = '\-1') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'topCountries'));
    }

    public yearly(userType: string, orgIdentifiers: string[] | string = '\-1') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'yearly'));
    }

    public yearlyAmount(userType: string, orgIdentifiers: string[] | string = '\-1') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'yearlyAmount'));
    }

    public amountInPipeLine(userType: string, orgIdentifiers: string[] | string = '\-1') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'amountInPipeLine'));
    }

    public priceIndex(userType: string, containerType: string, conatainerGrade: string, orgIdentifiers: string[] | string = '\-1') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'priceIndex/' + containerType + '/' + conatainerGrade));
    }

    public unitOrder(userType: string, orgIdentifiers: string[] | string = '\-1') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'unitOrder'));
    }

    public top5Location(userType: string, orgIdentifiers: string[] | string = '\-1') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'top5Location'));
    }

    public top5ContainerType(userType: string, orgIdentifiers: string[] | string = '\-1') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'top5ContainerType'));
    }

    public top5ContainerTypeAndGrade(userType: string, orgIdentifiers: string[] | string = '\-1') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'top5ContainerTypeAndGrade'));
    }

    public monthlyGrowth(userType: string, orgIdentifiers: string[] | string = '\-1') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'monthlyGrowth'));
    }

    public map(userType: string, orgIdentifiers: string[] | string = '\-1') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'map'));
    }

    public todo(userType: string, orgIdentifiers: string[] | string = '\-1') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, 'todo'));
    }

    public orders(userType: string, orgIdentifiers: string[] | string = '\-1', status = 'live') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, `orders/${status}`));
    }

    public ordersCount(userType: string, orgIdentifiers: string[] | string = '\-1', status = 'live') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, `orders/count/${status}`));
    }

    public ordersQuantity(userType: string, orgIdentifiers: string[] | string = '\-1', status = 'live') {
        return this.httpClient.get<BaseResponseDTO<any>>(this.url(userType, orgIdentifiers, `orders/quantity/${status}`));
    }

}
