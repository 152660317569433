import { NgModule } from "@angular/core";
import { MarketplaceCoreModule } from "@marketplace/marketplace-core/marketplace-core.module";
import { MarketplaceRatingCoreModule } from "@marketplace/marketplace-rating-core/marketplace-rating-core.module";
import { MakertplaceProductAddToCartComponent } from "./action/marketplace-product-add-to-cart/marketplace-product-add-to-cart.component";
import { MakertplaceProductAddToCompareComponent } from "./action/marketplace-product-add-to-compare/marketplace-product-add-to-compare.component";
import { MakertplaceProductBuyNowComponent } from "./action/marketplace-product-buy-now/marketplace-product-buy-now.component";
import { MarketplaceProductCommonActionComponent } from "./action/marketplace-product-common-action/marketplace-product-common-action.component";
import { MakertplaceProductFollowComponent } from "./action/marketplace-product-follow/marketplace-product-follow.component";
import { MakertplaceProductShareComponent } from "./action/marketplace-product-share/marketplace-product-share.component";
import { MarketplaceSoldItemDirective } from "./directive/marketplace-sold-item.directive";
import { MarketplaceViewCustomerDirective } from "./directive/marketplace-view-customer.directive";
import { MarketplaceCartIconComponent } from "./marketplace-cart-icon/marketplace-cart-icon.component";
import { MarketplacePriceInfoComponent } from "./marketplace-price-info/marketplace-price-info.component";
import { MarketplacePriceComponent } from "./marketplace-price/marketplace-price.component";
import { MarketplaceProductTaxInfoComponent } from "./marketplace-product-tax-info/marketplace-product-tax-info.component";
import { MarketplaceSellAsALotComponent } from "./marketplace-sell-as-alot/marketplace-sell-as-alot.component";
import { MarketplaceSellerInfoComponent } from "./marketplace-seller-info/marketplace-seller-info.component";
import { MarketplaceSellerSavedComponent } from "./marketplace-seller-saved/marketplace-seller-saved.component";
import { MarketplaceSigninLinkComponent } from "./marketplace-signin-link/marketplace-signin-link.component";
import { MarketplaceSignupLinkComponent } from "./marketplace-signup-link/marketplace-signup-link.component";
import { MarketplaceSpecialEquipmentComponent } from "./marketplace-special-equipment/marketplace-special-equipment.component";
import { BuyerProductListingFilterComponent } from "./product-listing-filter/product-listing-filter.component";
import { BuyerProductListingPaginationComponent } from "./product-listing-pagination/product-listing-pagination.component";
import { BuyerProductListingSortComponent } from "./product-listing-sort/product-listing-sort.component";
import { MarketplaceProductFeaturedListComponent } from "./product-listing/marketplace-product-featured-list/marketplace-product-featured-list.component";
import { MarketplaceProductFeaturedComponent } from "./product-listing/marketplace-product-featured/marketplace-product-featured.component";
import { MarketplaceProductPreviewComponent } from "./product-listing/marketplace-product-preview/marketplace-product-preview.component";
import { MarketplaceProductRecentlyViewedListComponent } from "./product-listing/marketplace-product-recently-viewed-list/marketplace-product-recently-viewed-list.component";
import { MarketplaceProductRecentlyViewedComponent } from "./product-listing/marketplace-product-recently-viewed/marketplace-product-recently-viewed.component";
import { MarketplaceProductRecommendationListComponent } from "./product-listing/marketplace-product-recommendation-list/marketplace-product-recommendation-list.component";
import { MarketplaceProductRecommendationComponent } from "./product-listing/marketplace-product-recommendation/marketplace-product-recommendation.component";
import { MarketplaceProductSellerOtherItemsListComponent } from "./product-listing/marketplace-product-seller-other-items-list/marketplace-product-seller-other-items-list.component";
import { MarketplaceProductSellerOtherItemsComponent } from "./product-listing/marketplace-product-seller-other-items/marketplace-product-seller-other-items.component";
import { MarketplaceProductSidebarComponent } from "./product-listing/marketplace-product-sidebar/marketplace-product-sidebar.component";
import { UpdateExportPreferenceModalComponent } from "./update-export-preference-modal/update-export-preference-modal.component";
import { ProductAvailablityUpdateModal } from "./product-availablity-update-modal/product-availablity-update-modal.component";
import { ContainerCarouselComponent } from "./container-carousel/container-carousel.component";
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  providers: [],
  declarations: [
    MarketplaceViewCustomerDirective,
    MarketplaceSoldItemDirective,
    //MarketplaceSellerInfoComponent,
    //MarketplacePriceComponent,
    MarketplaceSellerSavedComponent,
    //MarketplaceSellAsALotComponent,
    //MakertplaceProductAddToCartComponent,
    MakertplaceProductAddToCompareComponent,
    //MakertplaceProductBuyNowComponent,
    MakertplaceProductFollowComponent,
    MakertplaceProductShareComponent,
    MarketplaceProductSellerOtherItemsComponent,
    MarketplaceProductRecommendationComponent,
    MarketplaceProductSellerOtherItemsListComponent,
    MarketplaceProductRecommendationListComponent,
    MarketplaceProductRecentlyViewedComponent,
    MarketplaceProductRecentlyViewedListComponent,
    MarketplaceProductFeaturedComponent,
    MarketplaceProductFeaturedListComponent,
    BuyerProductListingFilterComponent,
    BuyerProductListingSortComponent,
    BuyerProductListingPaginationComponent,
    MarketplaceSignupLinkComponent,
    MarketplaceSpecialEquipmentComponent,
    MarketplaceProductCommonActionComponent,
    MarketplaceCartIconComponent,
    MarketplaceSigninLinkComponent,
    //MarketplacePriceInfoComponent,
    MarketplaceProductSidebarComponent,
    MarketplaceProductPreviewComponent,
    //MarketplaceProductTaxInfoComponent,
    UpdateExportPreferenceModalComponent,
    ProductAvailablityUpdateModal,
    ContainerCarouselComponent,
  ],
  exports: [
    MarketplaceViewCustomerDirective,
    MarketplaceSoldItemDirective,
    MarketplaceSellerInfoComponent,
    MarketplacePriceComponent,
    MarketplaceSellerSavedComponent,
    MarketplaceSellAsALotComponent,
    MarketplaceSignupLinkComponent,
    MarketplaceSpecialEquipmentComponent,
    MarketplaceCartIconComponent,
    BuyerProductListingFilterComponent,
    BuyerProductListingSortComponent,
    BuyerProductListingPaginationComponent,
    MarketplaceProductSidebarComponent,
    MarketplaceProductPreviewComponent,
    MakertplaceProductAddToCartComponent,
    MakertplaceProductBuyNowComponent,
    MakertplaceProductAddToCompareComponent,
    MakertplaceProductFollowComponent,
    MakertplaceProductShareComponent,
    MarketplaceProductCommonActionComponent,
    MarketplaceProductRecentlyViewedListComponent,
    MarketplaceProductFeaturedListComponent,
    MarketplaceProductSellerOtherItemsListComponent,
    MarketplaceProductSellerOtherItemsComponent,
    MarketplaceProductRecommendationListComponent,
    MarketplaceProductTaxInfoComponent,
    UpdateExportPreferenceModalComponent,
    ProductAvailablityUpdateModal,
    ContainerCarouselComponent,
  ],
  imports: [MarketplaceCoreModule, MarketplaceRatingCoreModule, MatTooltipModule],
})
export class MarketplaceProductCoreModule { }
