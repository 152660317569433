<div class="block-ui-spinner" *ngIf="message && getObect(message)">
  <ng-container [ngSwitch]="getObectType(message)">
    <ng-container *ngSwitchCase="smallLoader">
      <div class="boxxportLoaderSmall">
        <img
          alt=""
          class="small"
          src="{{ assetPath }}/images/svg/small-loader.svg"
        />
      </div>
      <div class="message" [innerHTML]="getObect(message).message"></div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="boxxportLoader" *ngIf="getObect(message).showLoader">
        <img alt="" src="{{ assetPath }}/images/loading.gif" />
      </div>
      <div class="message" [innerHTML]="getObect(message).message"></div>
    </ng-container>
  </ng-container>
</div>
