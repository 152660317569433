<div class="noti-show">
  <div *ngIf="notificationMap?.size === 0">
    <div class="row">
      <div class="col-12">
        <span *ngIf="isLoading">Loading....</span>
        <shared-notification-widget-no-notification
          *ngIf="!isLoading"
        ></shared-notification-widget-no-notification>
      </div>
    </div>
  </div>
  <ng-container *ngIf="notificationMap.size > 0">
    <div class="row">
      <div class="col-8">
        <h2 class="bold font24 margin0 text-color">Notifications</h2>
      </div>
      <div
        class="col-4 bold text-right text-sky-light marginT5"
        *ngIf="notificationMap?.size"
      >
        <a href="javascript:void(0)" (click)="markAsAllRead()"
          >Mark all as read</a
        >
      </div>
    </div>
    <hr class="marginT10 marginB0" />
    <ul>
      <ng-container
        *ngFor="
          let notificationObject of notificationMap | keyvalue : reverseKeyOrder
        "
      >
        <li *ngIf="isShow(notificationObject.value)">
          <shared-notification-widget-listing-small-header
            [config]="config"
            [heading]="notificationObject.key"
          >
          </shared-notification-widget-listing-small-header>
        </li>
        <ng-container *ngFor="let notification of notificationObject.value">
          <li
            [class]="notificationStatus(notification).toLowerCase()"
            *ngIf="notification.status !== 'HIDE'"
          >
            {{ notification.staus }}
            <shared-notification-widget-listing-small-item
              (click)="listingItemClick($event, notification)"
              [userType]="userType"
              [config]="config"
              [notification]="notification"
            >
            </shared-notification-widget-listing-small-item>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </ng-container>
  <div class="text-center marginT5">
    <a
      (click)="hideWindow()"
      [routerLink]="['/profile/notification']"
      class="bold"
    >
      See all Notifications
    </a>
  </div>
</div>
