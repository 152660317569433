import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MapLoaderService } from "@shared/map/map-loader.service";

@Component({
  selector: "[marketplace-places-autocomplete]",
  templateUrl: "./places-autocomplete.component.html",
  styleUrls: ["./places-autocomplete.component.scss"],
})
export class PlacesAutocompleteComponent implements AfterViewInit {
  @Input() entriesInView: number = 5;
  @Input() input: ElementRef;
  @Output() output = new EventEmitter();
  @ViewChild("dropdownContainer", { static: true }) dropdownElement: any;
  computedOptions = [];
  showSearchResults = false;

  dropdownPosition = {
    left: 0,
    top: 0,
    width: 0,
  };

  constructor(private mapLoaderService: MapLoaderService) {}

  stylizedOptions = [];

  ngAfterViewInit(): void {
    let input = this.input.nativeElement;
    input.addEventListener("input", () => {
      if (input.value) {
        console.log(this.dropdownElement);
        this.showSearchResults = true;
        this.setDropdownPosition();
        this.mapLoaderService
          .getPlacePredictions(input.value)
          .then((result) => {
            this.computedOptions = [];
            console.log(result);
            result.forEach((option) => {
              let structuredOption = option.structured_formatting;
              let splitStructure =
                structuredOption.main_text_matched_substrings[0];
              console.log(splitStructure);
              let computedOption = {
                searchMatch: structuredOption.main_text.slice(
                  0,
                  splitStructure.length
                ),
                prediction: structuredOption.main_text.slice(
                  splitStructure.length
                ),
              };
              this.computedOptions.push(computedOption);
            });
          });
      } else {
        this.showSearchResults = false;
      }
    });
  }
  closeAutoComplete(event: any) {
    this.showSearchResults = false;
    console.log(event);
  }

  setDropdownPosition() {
    let boundingRect = this.input.nativeElement.getBoundingClientRect();
    this.dropdownPosition.left = boundingRect.x + window.scrollX;
    this.dropdownPosition.top =
      boundingRect.y + window.scrollY + boundingRect.height;
    this.dropdownPosition.width = boundingRect.width;
    this.dropdownElement.nativeElement.style.left =
      this.dropdownPosition.left + "px";
    this.dropdownElement.nativeElement.style.top =
      this.dropdownPosition.top + "px";
    this.dropdownElement.nativeElement.style.width =
      this.dropdownPosition.width + "px";
  }
}
