import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { AbstractBaseComponent } from '@shared/abstracts/abstract-base.component';

@Injectable({ providedIn: 'root' })
export class PreventNavigateCanDeactivateGuard implements CanDeactivate<AbstractBaseComponent> {

    canDeactivate(component: AbstractBaseComponent): boolean {

        if (!component.canDeactivate()) {
            if (confirm(component.canDeactivateMsg())) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }
}
