import { Injector, Directive } from "@angular/core";
import { Utils } from "@shared/constant/shared.utils";
import { BlockUIService } from "ng-block-ui";
import { AbstractBaseComponent } from "./abstract-base.component";

@Directive()
export abstract class AbstractBaseBlockUIComponent extends AbstractBaseComponent {
  blockUIuuid = Utils.uuid();

  protected blockUIService: BlockUIService;

  constructor(protected injector: Injector) {
    super();
    this.blockUIService = injector.get(BlockUIService);
  }

  startBlockUi(target?: string | string[], message?: any) {
    this.blockUIService.start(target || this.blockUIuuid, message);
  }

  stopBlockUi(target?: string | string[]): void {
    this.blockUIService.stop(target || this.blockUIuuid);
  }
}
