import { Component, OnInit, Injector } from '@angular/core';
import { AbstractModalComponent } from '@marketplace/abstract/abstract-modal.component';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { NegotiationNotificationDTO } from '@shared/notification-widget/dto/NegotiationNotificationDTO';

@Component({
  templateUrl: './negotiation-notification-modal.component.html',
  styles: [
  ]
})
export class NegotiationNotificationModalComponent extends AbstractModalComponent {

  negotiationNotification: NegotiationNotificationDTO;

  inNegotiationFlow: Boolean = false;

  constructor(
    protected injector: Injector,
    public bsModalRef: BsModalRef, private toastr: ToastrService,
    private router: Router
  ) { super(bsModalRef, injector); }

  OnModalInit() {
    if (this.router.url.indexOf('negotiate') !== -1) {
      this.inNegotiationFlow = true;
    }
  }

  refresh() {
    if (this.negotiationNotification.updateForSeller) {
      window.location.href = '/seller/negotiate';
    } else {
      window.location.href = '/buyer/negotiate';
    }
    this.close();
  }
}
