import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponseDTO } from '@shared/dto/BaseResponseDTO';
import { first } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class CompanyTypeService  {

    constructor(private http: HttpClient) { }

    companyTypes() {
        return this.http.get<BaseResponseDTO<string[]>>('/api/p/companyType/', {}).pipe(first());
    }

    // ##Replace
}
