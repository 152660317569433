<!-- <link [href]="sanitizer.bypassSecurityTrustResourceUrl(calendlyCssUrl)" rel="stylesheet"> -->
<button
  (click)="loadPopup()"
  class="btn btn-cyan2-outline text-grey-light w-100"
  [hidden]="hideButton"
  [ngClass]="styleClass"
>
  <span style="margin-right: 10px">
    <img src="{{ assetPath }}/images/new-svg/demoicon.svg" alt="" />
    {{ buttonText }}
  </span>
</button>
