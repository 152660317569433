import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { menus } from '@boss/secure/ui/sidemenu/menu-elements';
import { v4 as UUID } from 'uuid';
import * as moment from 'moment';
import * as CryptoJS from 'crypto-js';
export const ASSET_PATH = document.getElementsByTagName('meta')
    && document.getElementsByTagName('meta')['assetPath'] ?
    document.getElementsByTagName('meta')['assetPath'].content :
    'assets';
const DATE_FORMAT = 'DD-MM-YYYY';

export const getPathFromUrl = (url: string) => url.split(/[?#]/)[0];

export const removeEmpty = (obj) => {
    if (obj) {
        Object.keys(obj).forEach(key => {
            if (obj[key] && typeof obj[key] === 'object') {
                removeEmpty(obj[key]);
            } else if (obj[key] == null) { delete obj[key]; }
        });
    }
};

@Injectable({ providedIn: 'root' })
export class Utils {


    static isNull(val: any): boolean {
        return !Utils.isNotNull(val);
    }
    static isNotNull(val: any): boolean {
        return val !== null && val !== undefined;
    }
    static isNotEmpty(val: any): boolean {
        return Utils.isNotNull(val) && val.length > 0;
    }
    static isEmpty(val: any): boolean {
        return !Utils.isNotEmpty(val);
    }
    static isEmptyObject(obj) {
        return (obj && (Object.keys(obj).length === 0));
    }
    static removeNull(val: object): any {
        Object.keys(val).forEach(function (key) {
            if (val[key] === null) {
                delete val[key];
            }
        });
        return val;
    }

    static uuid() {
        return UUID();
    }

    static first(arr) {
        return arr && arr.length > 0 ? arr[0] : {};
    }

    static values(obj) {
        return Object.keys(obj).map(e => obj[e]);
    }

    static markFormGroupTouched(formGroup: FormGroup) {
        formGroup.markAsDirty();
        Utils.values(formGroup.controls).forEach(control => {
            control.markAsTouched();
            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    static markFormGroupUnTouched(formGroup: FormGroup) {
        Utils.values(formGroup.controls).forEach(control => {
            control.markAsUntouched();
            if (control.controls) {
                this.markFormGroupUnTouched(control);
            }
        });
    }

    static fileRead(file: File, callback) {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file); // read file as data url
        reader.onload = (callbackEvent: any) => { // called once readAsDataURL is completed
            callback(callbackEvent.target.result);
        };
    }
    static isSameDate(d1: Date, d2: Date) {
        return d1 && d2 && moment(d1, DATE_FORMAT).isSame(moment(d2, DATE_FORMAT));
    }

    static dateToString(obj) {
        if (obj) {
            if (obj instanceof Date) {
                const momentObject = moment(obj);
                momentObject.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                const date = momentObject.format('YYYY-MM-DD[T]HH:mm:ss.sss[Z]');
                return date;
            }
        }
        return obj;
    }

    static isChecked($event): boolean {
        return $event && $event.target && $event.target.checked === true;
    }

    static parseInt(value, defaultValue = 0): number {
        if (value) {
            const intValue = parseInt(value, 10);
            if (!isNaN(intValue)) {
                return intValue;
            }
        }
        return defaultValue;
    }

    static parseFloat(value, defaultValue = 0): number {
        if (value) {
            const intValue = parseFloat(value);
            if (!isNaN(intValue)) {
                return intValue;
            }
        }
        return defaultValue;
    }

    static isArray(data) {
        return data && 'object' === typeof data;
    }

    static toQueryParam(object: object): HttpParams {
        let params = new HttpParams();
        if (object) {
            Object.keys(object)
                .forEach(key => {
                    const value = object[key] ? object[key] : '';
                    params = params.append(key, value);
                });
        }
        return params;
    }

    static hashCode(object: string | String): number {
        let hash = 0, i, chr;
        if (!object || object.length === 0) { return hash; }
        for (i = 0; i < object.length; i++) {
            chr = object.charCodeAt(i);
            // tslint:disable-next-line:no-bitwise
            hash = ((hash << 5) - hash) + chr;
            // tslint:disable-next-line:no-bitwise
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    static toFixed(value: number, toFixed = 2) {
        if (value) {
            let mul = 1;
            for (let index = 0; index < toFixed; index++) {
                mul *= 10;
            }
            return Math.round(value * mul) / mul;
        }
        return value;
    }
    static cloneObject(object: object) {
        return object && Object.keys(object).reduce((obj, key) => {
            obj[key] = object[key];
            return obj;
        }, {});
    }
    static diffObject(o1: object, o2: object) {
        if (o1 && o2) {
            return Object.keys(o2).reduce((diff, key) => {
                if (o1[key] === o2[key]) {
                    return diff;
                }
                return {
                    ...diff,
                    [key]: o2[key]
                };
            }, {});
        }
    }

    static copyToClipboard(str) {
        const el = document.createElement('textarea');  // Create a <textarea> element
        el.value = str;                                 // Set its value to the string that you want copied
        el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
        el.style.position = 'absolute';
        el.style.left = '-9999px';                      // Move outside the screen to make it invisible
        document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
        const selected =
            document.getSelection().rangeCount > 0        // Check if there is any content selected previously
                ? document.getSelection().getRangeAt(0)     // Store selection if found
                : false;                                    // Mark as false to know no selection existed before
        el.select();                                    // Select the <textarea> content
        document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
        document.body.removeChild(el);                  // Remove the <textarea> element
        if (selected) {                                 // If a selection existed before copying
            document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
            document.getSelection().addRange(selected);   // Restore the original selection
        }
    }

    static queryParamFromUrl() {
        const querryString = decodeURI(window.location.search)
            .replace('?', '');
        return this.queryString(querryString);
    }
    static queryString(querryString) {
        if (querryString) {
            return querryString
                .split('&')
                .map(param => param.split('='))
                .reduce((values, [key, value]) => {
                    values[key] = value;
                    return values;
                }, {});
        }
    }
    static queryParamFromString(url: string) {
        const indexOf = url.indexOf('?');
        if (indexOf !== -1) {
            const querryString = url.substring(indexOf + 1, url.length);
            return this.queryString(querryString);
        }
    }
    static trimChar(string: string, charToRemove = ' ') {
        while (string.charAt(0) === charToRemove) {
            string = string.substring(1);
        }
        while (string.charAt(string.length - 1) === charToRemove) {
            string = string.substring(0, string.length - 1);
        }
        return string;
    }


    private static hasExtension(fileName: string, exts: string[]) {
        return fileName && (exts.length === 0 || (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName.toLowerCase()));
    }

    public static isFileValid(file: File, fileType: string, maxSize: number, exts: string[]) {
        if (file) {
            const size = file.size;
            const name = file.name;
            const type = file.type;
            return ((Utils.isEmpty(type) || (Utils.isNull(fileType) || type.startsWith(fileType))) && (((size / 1000) / 1000) <= maxSize) && Utils.hasExtension(name, exts));
        }
    }

    public static pagination(value: any[], page: number = 0, size: number = -1): object[] {
        if (size > 0) {
            const start = page * size;
            const end = start + size;
            return value ? value.slice(start, end) : [];
        }
        return value;
    }

    public static findInvalidControlsRecursive(formToInvestigate: FormGroup | FormArray): string[] {
        const invalidControls: string[] = [];
        const recursiveFunc = (form: FormGroup | FormArray) => {
            Object.keys(form.controls).forEach(field => {
                const control = form.get(field);
                if (control.invalid) {
                    invalidControls.push(field);
                }
                if (control instanceof FormGroup) {
                    recursiveFunc(control);
                } else if (control instanceof FormArray) {
                    recursiveFunc(control);
                }
            });
        };
        recursiveFunc(formToInvestigate);
        return invalidControls;
    }

    static monthArray() {
        const month = [];
        month[0] = 'January';
        month[1] = 'February';
        month[2] = 'March';
        month[3] = 'April';
        month[4] = 'May';
        month[5] = 'June';
        month[6] = 'July';
        month[7] = 'August';
        month[8] = 'September';
        month[9] = 'October';
        month[10] = 'November';
        month[11] = 'December';
        return month;
    }

    static monthArrayCurrent() {
        const monthArray = Utils.monthArray();
        const monthArrayNew = monthArray.splice(new Date().getMonth() + 1);
        return monthArrayNew.concat(monthArray).reverse();
    }

    static roundedOffNumber(number: number) {
        const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return Number(formatter.format(number).replace(',', ''));
    }
    static isSuperAdmin(rolesArray: string[]) {
        if (this.getLoggedInUserRole(rolesArray) == 'ROLE_SUPER_ADMIN') {
            return true;
        }
    }
    static isOperationAdmin(rolesArray: string[]) {
        if (this.getLoggedInUserRole(rolesArray) == 'ROLE_ADMIN') {
            return true;
        }
    }

    static isAssociateAdmin(rolesArray: string[]) {
        if (this.getLoggedInUserRole(rolesArray) == 'ROLE_ASSOCIATE_ADMIN') {
            return true;
        }
    }

    static isSalesAdmin(rolesArray: string[]) {
        if (this.getLoggedInUserRole(rolesArray) == 'ROLE_SALES_ADMIN') {
            return true;
        }
    }
    static isUser(rolesArray: string[]) {
        if (this.getLoggedInUserRole(rolesArray) == 'ROLE_USER') {
            return true;
        }
    }
    static getLoggedInUserRole(rolesArray: string[]) {
        if (rolesArray.indexOf('ROLE_SUPER_ADMIN') !== -1) {
            return 'ROLE_SUPER_ADMIN';
        } else if (rolesArray.indexOf('ROLE_ADMIN') !== -1) {
            return 'ROLE_ADMIN';
        } else if (rolesArray.indexOf('ROLE_ASSOCIATE_ADMIN') !== -1) {
            return 'ROLE_ASSOCIATE_ADMIN';
        } else if (rolesArray.indexOf('ROLE_SALES_ADMIN') !== -1) {
            return 'ROLE_SALES_ADMIN';
        } else {
            return 'ROLE_USER';
        }
    }
    static isAccessible(componentName: string, role: string): boolean {
        let isAccessible = true;
        let subComponent = '';
        if (componentName.indexOf('.') > -1) {
            subComponent = componentName.split('.')[1];
        }
        menus.forEach((menuitem) => {
            if (menuitem.id == componentName) {
                if (subComponent) {
                    menuitem.sub.forEach((subMenus) => {
                        if (subMenus.id === subComponent) {
                            if (subMenus['hiddenFor'] && subMenus['hiddenFor'].indexOf(role) != -1) {
                                isAccessible = false;
                            }
                        }
                    });
                } else {
                    if (menuitem.hiddenFor && menuitem.hiddenFor.indexOf(role) != -1) {
                        isAccessible = false;
                    }
                }
            }
        });
        return isAccessible;
    }
    static getNodes(node: HTMLCollectionOf<Element>) {
        return Array.from(node).filter(e => e.nodeType == Node.ELEMENT_NODE);
    }
    static getChildNodes(node: Node) {
        return Array.from(node.childNodes).filter(e => e.nodeType == Node.ELEMENT_NODE);
    }
    static getMarketplaceBaseUrl() {
        return window.location.origin.replace('admin-', '').replace('admin.', '');
    }
    static encryptData(data: string, keyUsed: string, ivUsed: string) {
        const key = CryptoJS.enc.Utf8.parse(keyUsed);
        const iv = CryptoJS.enc.Utf8.parse(ivUsed);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            keySize: 16,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    }
}
