import { Utils } from '@shared/constant/shared.utils';
import { ContainerPriceDto, ContainerDiscountedPriceDto } from '@shared/dto';

export interface PriceInfo {

    finalPrice: number;

    finalPriceBig: number | string;

    finalPriceSmall: number | string;

    boxxportFee: number;

    myEarning: number;

    commisionPercentage: number;

}

export class ProductPriceUtils {

    /*
        commisionPercentage = 10%
        myEarning = 100
        boxxportFee = 100*10/100 = 10
        finalPrice = 100 + 10 = 110
    */
    static fromMyEarning(myEarning: number, commisionPercentage: number): PriceInfo {
        const priceInfo = {} as PriceInfo;
        if (!myEarning || commisionPercentage === undefined || commisionPercentage === null) {
            return priceInfo;
        }
        priceInfo.myEarning = Math.ceil(myEarning);
        priceInfo.finalPrice = Math.floor(priceInfo.myEarning * (1 + commisionPercentage / 100));
        priceInfo.boxxportFee = priceInfo.finalPrice - priceInfo.myEarning;
        return ProductPriceUtils.addDispValue(priceInfo);
    }

    /*
          commisionPercentage = 10%
          finalPrice = 110
          myEarnings = 110/(.1+1) = 100
          boxxportFee = 100 * 10 /100 = 10
      */
    static fromFinalPrice(finalPrice: number, commisionPercentage: number): PriceInfo {
        const priceInfo = {} as PriceInfo;
        if (!finalPrice || commisionPercentage === undefined || commisionPercentage === null) {
            return priceInfo;
        }
        const myEarnings = finalPrice / (commisionPercentage / 100 + 1);
        priceInfo.myEarning = Math.ceil(myEarnings);
        priceInfo.finalPrice = Math.floor(finalPrice);
        priceInfo.boxxportFee = priceInfo.finalPrice - priceInfo.myEarning;
        return ProductPriceUtils.addDispValue(priceInfo);
    }

    /*
    * percentage = (1 - NewPrice/price)*100
    */
    static benefit(originalPrice: number, discountedPrice: number) {
        return (1 - discountedPrice / originalPrice) * 100;
    }

    private static addDispValue(priceInfo: PriceInfo): PriceInfo {
        if (priceInfo) {
            const finalPrice = priceInfo.finalPrice;
            if (finalPrice) {
                const arr = (finalPrice + '').split('.');
                if (arr.length === 2) {
                    priceInfo.finalPriceBig = arr[0];
                    priceInfo.finalPriceSmall = arr[1];
                    if (priceInfo.finalPriceSmall.length < 2) {
                        priceInfo.finalPriceSmall = priceInfo.finalPriceSmall + '0';
                    }
                } else {
                    priceInfo.finalPriceBig = finalPrice;
                }
            }
        }
        return priceInfo;
    }

    public static updateFinalPrice(price: ContainerPriceDto, marketPlaceCommission: number) {
        if (price) {
            price.marketplaceFeePercent = marketPlaceCommission;
            if (!price.priceWithMarketplaceFee) {
                price.priceWithMarketplaceFee = ProductPriceUtils.fromMyEarning(price.originalPrice, marketPlaceCommission).finalPrice;
            }
        }
    }
    public static updateFinalDiscountPrice(price: ContainerDiscountedPriceDto, marketPlaceCommission: number) {
        if (price) {
            price.discountedPrice = price.discountedPrice;
            if (!price.discountedPriceWithFee) {
                price.discountedPriceWithFee = ProductPriceUtils.fromMyEarning(price.discountedPrice, marketPlaceCommission).finalPrice;
            }
        }
    }

}
