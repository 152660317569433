import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { AbstractBaseAdminService } from "@boss/secure/abstract/abstract-base-admin-service";
import { LocationLiveInventoryDTO } from "@marketplace/marketplace-core/marketplace-live-inventory/model/LocationLiveInventoryDTO";
import { Utils } from "@shared/constant/shared.utils";
import { ContainerSearchCriteria } from "@shared/dto";
import { BaseResponseDTO } from "@shared/dto/BaseResponseDTO";
import { BuyerContainerDto } from "@shared/dto/BuyerContainerDto";
import { ContainerDto } from "@shared/dto/ContainerDto";
import { ReDeliveryLocationPriceDTO } from "@shared/dto/ReDeliveryLocationPriceDTO";
import { PlaceResultOption } from "@shared/map/map.utils";
import { first } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ProductService extends AbstractBaseAdminService {
  @Output() public locationUpdateEvent: EventEmitter<any> = new EventEmitter();
  constructor(private http: HttpClient) {
    super();
  }

  details(identifier: string, paramMap?: any) {
    let params = new HttpParams();
    if (paramMap) {
      Object.keys(paramMap).forEach((key) => {
        params = params.set(key, paramMap[key]);
      });
    }
    return this.http
      .get<BaseResponseDTO<BuyerContainerDto>>(`/api/p/product/${identifier}`, {
        params,
      })
      .pipe(first());
  }
  search(object: object) {
    const params = Utils.toQueryParam(object);
    return this.http
      .get<BaseResponseDTO<BuyerContainerDto[]>>("/api/p/product/search", {
        params,
      })
      .pipe(first());
  }

  searchCount(object: object) {
    const params = Utils.toQueryParam(object);
    return this.http
      .get<BaseResponseDTO<number>>("/api/p/product/search/count", {
        params,
      })
      .pipe(first());
  }

  compareGet(object: any) {
    return this.http
      .get<BaseResponseDTO<object>>("/api/product/compare", object)
      .pipe(first());
  }

  searchProductsForSeller(
    sellerOrgIdentifier: string,
    productIdentifier: string,
    pageIndex: number,
    pageSize: number,
    isExport?: boolean,
    exportCountry?: string
  ) {
    let params = new HttpParams()
      .set("page", pageIndex.toString())
      .set("size", pageSize.toString())
      .set("isExport", isExport ? "true" : "false")
      .set("productIdentifier", productIdentifier);
    if (exportCountry) {
      params = params.set("exportCountry", exportCountry);
    }
    return this.http
      .get<BaseResponseDTO<BuyerContainerDto[]>>(
        "/api/p/product/seller/" + sellerOrgIdentifier,
        { params: params }
      )
      .pipe(first());
  }

  getAllContainersByStatus(
    pageIndex: number,
    pageSize: number,
    status: string
  ) {
    const params = new HttpParams()
      .set("page", pageIndex.toString())
      .set("size", pageSize.toString());
    const url = `${this.baseUrl}product/${status}/list`;
    return this.http
      .get<BaseResponseDTO<ContainerDto[]>>(url, { params: params })
      .pipe(first());
  }

  getAllContainersByStatusCount(status: string) {
    const params = new HttpParams();
    const url = `${this.baseUrl}product/${status}/count`;
    return this.http
      .get<BaseResponseDTO<number>>(url, { params: params })
      .pipe(first());
  }

  getAllContainersByStatusAndActivity(
    pageIndex: number,
    pageSize: number,
    status: string,
    activStatus: string
  ) {
    const params = new HttpParams()
      .set("page", pageIndex.toString())
      .set("size", pageSize.toString());
    const url = `${this.baseUrl}product/${status}/list/${activStatus}`;
    return this.http
      .get<BaseResponseDTO<ContainerDto[]>>(url, { params: params })
      .pipe(first());
  }

  getAllContainersByStatusAndActivityCount(
    status: string,
    activStatus: string
  ) {
    const params = new HttpParams();
    const url = `${this.baseUrl}product/${status}/count/${activStatus}`;
    return this.http
      .get<BaseResponseDTO<number>>(url, { params: params })
      .pipe(first());
  }

  getAllSearchedContainers(
    pageIndex: number,
    pageSize: number,
    csc: ContainerSearchCriteria
  ) {
    const url = `${this.baseUrl}product/searched/list?pageNo=${pageIndex}`;
    return this.http
      .post<BaseResponseDTO<ContainerDto[]>>(url, csc)
      .pipe(first());
  }

  getAllSearchedContainersCount(csc: ContainerSearchCriteria) {
    const url = `${this.baseUrl}product/searched/count`;
    return this.http.post<BaseResponseDTO<number>>(url, csc).pipe(first());
  }

  getAllFollowListing(
    page = 0,
    size = 2,
    isExport?: boolean,
    exportCountry?: string
  ) {
    let params = new HttpParams()
      .set("page", page.toString())
      .set("size", size.toString())
      .set("isExport", isExport ? "true" : "false");
    if (exportCountry) {
      params = params.set("exportCountry", exportCountry);
    }
    const url = `${this.apiBaseUrl}p/product/follow`;
    return this.http
      .get<BaseResponseDTO<BuyerContainerDto[]>>(url, { params })
      .pipe(first());
  }

  getAllFollowListingCount() {
    const url = `${this.apiBaseUrl}p/product/follow/count`;
    return this.http.get<BaseResponseDTO<number>>(url, {}).pipe(first());
  }

  getLiveInventoryLocation(showData = true, apiFilter?: any) {
    let params = new HttpParams();
    if (apiFilter) {
      if (apiFilter.params) {
        apiFilter = apiFilter.params;
      }
      Object.keys(apiFilter)
        .filter((key) => apiFilter[key])
        .forEach((key) => {
          params = params.set(key, apiFilter[key]);
        });
    }
    const url = `${this.apiBaseUrl}p/product/liveInventory?showData=${showData}`;
    return this.http
      .get<BaseResponseDTO<LocationLiveInventoryDTO>>(url, { params })
      .pipe(first());
  }

  requestPrice(
    productIdentifier,
    redeliveryLocations: ReDeliveryLocationPriceDTO[]
  ) {
    const headers = new HttpHeaders({
      "api-message-code": "request.buyback.price",
    });
    const url = `${this.apiBaseUrl}buyer/buyback/requestBuybackPrice/${productIdentifier}`;
    return this.http
      .post<BaseResponseDTO<number>>(
        url,
        {
          productIdentifier: productIdentifier,
          requestDTOList: redeliveryLocations,
        },
        { headers: headers }
      )
      .pipe(first());
  }
  buybackPrice(productIdentifier, buybackLocation: PlaceResultOption) {
    const url = `${this.apiBaseUrl}buyer/buyback/buybackPrice/${productIdentifier}`;
    return this.http
      .post<BaseResponseDTO<ReDeliveryLocationPriceDTO>>(url, buybackLocation)
      .pipe(first());
  }
  hasBuyBackProduct(object: object) {
    const params = Utils.toQueryParam(object);
    return this.http
      .get<BaseResponseDTO<boolean>>(
        "/api/buyer/buyback/search/hasBuyBackProducts",
        {
          params,
        }
      )
      .pipe(first());
  }

  // ##Replace
}
