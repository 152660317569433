import {
  Injector,
  Input,
  OnChanges,
  SimpleChanges,
  Directive,
} from "@angular/core";
import { AbstractBaseBlockUIComponent } from "@shared/abstracts/abstract-base-blockui.component";
import { NotificationModel } from "../dto/NotificationModel.dto";
import { NotificationWidgetService } from "../service/notification-widget.service";

@Directive()
export abstract class AbstractNotificationWidgetComponent
  extends AbstractBaseBlockUIComponent
  implements OnChanges
{
  protected statusHide = "HIDE";
  protected statusUnread = "UNREAD";
  protected statusRead = "READ";

  protected notificationWidgetService: NotificationWidgetService;

  @Input() userType = "SELLER,BUYER";

  @Input() status = "ALL";

  @Input() config = {};

  @Input() filter = {};

  constructor(protected injector: Injector) {
    super(injector);
    this.notificationWidgetService = injector.get(NotificationWidgetService);
  }

  OnInit() {
    super.OnInit();
    this.OnChatWidgetInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let isChange = false;
    if (changes) {
      if (changes.userType) {
        if (!changes.userType.firstChange || !changes.userType.previousValue) {
          isChange = true;
          this.userType = changes.userType.currentValue;
        }
      }
      if (changes.status) {
        if (!changes.status.firstChange || !changes.status.previousValue) {
          isChange = true;
          this.status = changes.status.currentValue;
        }
      }
      if (changes.config) {
        if (!changes.config.firstChange || !changes.config.previousValue) {
          isChange = true;
          this.config = changes.config.currentValue;
        }
      }
      if (changes.filter) {
        if (!changes.filter.firstChange || !changes.filter.previousValue) {
          isChange = true;
          this.filter = changes.filter.currentValue;
        }
      }
    }
    if (isChange) {
      this.OnChanges(changes);
    }
  }

  protected OnChanges(changes: SimpleChanges): void {}

  protected OnChatWidgetInit() {
    super.unsubscribeAll();
  }

  protected toggleNotificationStatus(notification: NotificationModel) {
    if (notification.status === this.statusUnread) {
      this.updateStatus(this.statusRead, [notification]);
    } else if (notification.status === this.statusRead) {
      this.updateStatus(this.statusUnread, [notification]);
    } else if (notification.status === this.statusHide) {
      this.updateStatus(this.statusUnread, [notification]);
    }
  }

  protected updateStatus(status, notifications: NotificationModel[]) {
    if (notifications) {
      const identifiers = notifications
        .map((e) => e.identifier)
        .filter((e) => e);
      if (identifiers) {
        this.notificationWidgetService
          .post(this.userType, status, identifiers)
          .subscribe((data) => {
            if (identifiers.length > 1) {
              this.notificationWidgetService.updateNotificationCountEvent.emit();
            } else {
              this.notificationWidgetService.updateNotificationEvent.emit(
                notifications
                  .filter((e) => e.status !== status)
                  .map((_) => status)
              );
            }
            notifications.forEach((notification) => {
              notification.status = status;
            });
          });
      }
    }
  }

  notificationStatus(notification: NotificationModel) {
    if (notification.status === this.statusRead) {
      return this.statusRead;
    } else if (notification.status === this.statusUnread) {
      return this.statusUnread;
    } else if (notification.status === this.statusHide) {
      return this.statusRead;
    }
    return "";
  }

  notificationToggleStatus(notification: NotificationModel) {
    if (notification.status === this.statusRead) {
      return this.statusUnread;
    } else if (notification.status === this.statusUnread) {
      return this.statusRead;
    } else if (notification.status === this.statusHide) {
      return this.statusUnread;
    }
    return "";
  }

  notificationTime(notification: NotificationModel) {
    const date = new Date(notification.date);
    return date;
  }
}
