import { Injector, Injectable, NgZone } from "@angular/core";
import { Utils } from "@shared/constant/shared.utils";
import { ConstantService } from "@shared/constant/shared.constant";

const CUSTOM_SCRIPT: any = {};

[
  { name: "stripe_card", src: "https://js.stripe.com/v3/" },
  {
    name: "calendly_script",
    src: "https://assets.calendly.com/assets/external/widget.js",
  },
  {
    name: "paypal",
    src: `https://www.paypal.com/sdk/js?client-id=${ConstantService.get(
      "PAYPAL_CLIENT_ID"
    )}&disable-funding=credit`,
    nonce: "boxxport-paypal",
  },
  {
    name: "bluesnap",
    src: `${ConstantService.get(
      "BLUESNAP_SP_COLLECTOR_URL"
    )}/web-sdk/5/bluesnap.js`,
  },
].forEach((script: any) => {
  const name = script.name;
  const id = `${Utils.uuid()}-${name}`;
  CUSTOM_SCRIPT[name] = {
    loaded: false,
    init: false,
    id,
    name,
    src: script.src,
    nonce: script.nonce,
  };
});

@Injectable({ providedIn: "root" })
export class ScriptService {
  constructor(injector: Injector, protected zone: NgZone) {}

  loadAll() {
    this.load(...Object.keys(CUSTOM_SCRIPT));
  }

  // load a single or multiple scripts
  load(...scripts: string[]) {
    const promises: any[] = [];
    // push the returned promise of each loadScript call
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    // return promise.all that resolves when all promises are resolved
    return Promise.all(promises);
  }

  // load the script
  private loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!CUSTOM_SCRIPT[name]) {
        resolve({});
      }
      // resolve if already loaded
      if (CUSTOM_SCRIPT[name].loaded) {
        resolve({ script: name, loaded: true, status: "Already Loaded" });
      } else if (CUSTOM_SCRIPT[name].init) {
        const wait = () => {
          setTimeout(() => {
            if (CUSTOM_SCRIPT[name].loaded) {
              resolve({ script: name, loaded: true, status: "Already Loaded" });
            } else {
              wait();
            }
          }, 2000);
        };
        wait();
      } else {
        CUSTOM_SCRIPT[name].init = true;
        // load script
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = CUSTOM_SCRIPT[name].src;
        script.id = CUSTOM_SCRIPT[name].id;
        script.innerHTML = "";
        script.async = true;
        script.defer = true;
        if (CUSTOM_SCRIPT[name].nonce) {
          const att = document.createAttribute("data-csp-nonce");
          att.value = CUSTOM_SCRIPT[name].nonce;
          script.setAttributeNode(att);
        }
        // cross browser handling of onLoaded event
        if (script["readyState"]) {
          // IE
          script["onreadystatechange"] = () => {
            if (
              script["readyState"] === "loaded" ||
              script["readyState"] === "complete"
            ) {
              script["onreadystatechange"] = null;
              CUSTOM_SCRIPT[name].loaded = true;
              resolve({ script: name, loaded: true, status: "Loaded" });
            }
          };
        } else {
          // Others
          script.onload = () => {
            this.zone.run(() => {
              CUSTOM_SCRIPT[name].loaded = true;
              resolve({ script: name, loaded: true, status: "Loaded" });
            });
          };
        }
        script.onerror = (error: any) =>
          resolve({ script: name, loaded: false, status: "Loaded" });
        // finally append the script tag in the DOM
        document.getElementsByTagName("head")[0].appendChild(script);
        CUSTOM_SCRIPT[name].init = false;
      }
    });
  }
}
